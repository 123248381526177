import React from "react";
import { Helmet } from "react-helmet";
import HeaderLandingAppointmentPlenish from "../../../components/header/landing/HeaderLandingAppointmentPlenish";
import FooterEightPlenish from "../../../components/footer/FooterEightPlenish";
import CopyRightFourPlenish from "../../../components/footer/CopyRightFourPlenish";

const BlogDetailsShouldIOwnRealEstateInMyPortfolio = () => {
  return (
    <div>
      {/* <div className="banner-promo">
        Get your questions answered,{" "}
        <Link to="/promo" className="">
          try for free
        </Link>
      </div> */}
      <div className="main-page-wrapper p0 font-gordita">
        <Helmet>
          <title>
            Plenish | Blog | Should I Own Real Estate In My Portfolio?
          </title>
        </Helmet>
        {/* End Page SEO Content */}

        <HeaderLandingAppointmentPlenish />
        {/* End Header */}

        {/* =============================================
            Fancy Hero Two
        ==============================================  */}
        <div className="fancy-hero-five bg-gray-50">
          <div className="bg-wrapper ">
            <div className="container">
              <div className="col-lg-10 m-auto text-center">
                <h1 className="heading">
                  Should I Own Real Estate In My Portfolio?
                </h1>
              </div>
            </div>
          </div>
          {/* /.bg-wrapper */}
        </div>
        {/* /.fancy-hero-two */}

        {/* =====================================================
            Feature Blog One
        ===================================================== */}
        <main className="bg-gray-50">
          <div className="relative flex justify-between items-center max-w-4xl mx-auto px-4 py-6">
            <div className="relative px-4 sm:px-6 lg:px-8">
              <div className="text-lg mx-auto">
                {/* <h1
                  className="my-2 block text-3xl text-center leading-8 font-bold tracking-tight text-gray-900 sm:text-4xl">
                  Should I Own Real Estate In My Portfolio?
                </h1> */}
                <p className="mt-4 text-xl text-gray-500 leading-8">
                  Owning real estate can be an intelligent strategy for
                  diversifying your investment portfolio. But before you dive
                  in, understand the unique risks and rewards of investing in
                  real estate. There's a lot of hype surrounding real estate
                  investments, so you must understand the facts before spending
                  your hard-earned money.
                </p>
                <p className="mt-4 text-xl text-gray-500 leading-8">
                  When it comes to real estate investment, you have many
                  options. You can buy land and build homes, buy apartment
                  complexes, and buy your own home. It's a big decision to start
                  investing in real estate and diversifying your investments
                  outside of the stock market. There are many things to think
                  about when it comes to real estate investments - such as taxes
                  or liquidity - but here are the basics for you to get started
                  with your real estate investments.
                </p>
                <div className="relative py-4">
                  <div className="absolute inset-x-0 bottom-0 h-1/2"></div>
                  <div className="max-w-7xl mx-auto">
                    <div className="relative shadow-xl sm:rounded-2xl sm:overflow-hidden">
                      <div className="absolute inset-0">
                        <img
                          className="h-full w-full object-cover"
                          src="images/blog/blog_2.jpg"
                          alt=""
                        />
                      </div>
                      <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-48 lg:px-8"></div>
                    </div>
                  </div>
                </div>
                <h1
                  className="mt-4 block text-2xl text-left leading-8 font-bold tracking-tight
                            text-gray-900 sm:text-3xl"
                >
                  Diversification
                </h1>
                <p className="mt-4 text-xl text-gray-500 leading-8">
                  Diversification is a cornerstone of any well-balanced
                  investment portfolio, and it helps you spread your money
                  around and mitigate risk by not placing all your eggs in one
                  basket.
                </p>
                <p className="mt-4 text-xl text-gray-500 leading-8">
                  Real estate is an excellent diversification tool for investors
                  because it can provide steady returns and income, and it's a
                  tangible asset that can be used as collateral for loans or
                  sold if need be.
                </p>
                <p className="mt-4 text-xl text-gray-500 leading-8">
                  But real estate also comes with its own set of risks — namely,
                  it takes time to appreciate, making it challenging to sell
                  anytime soon.
                </p>
                <p className="mt-4 text-xl text-gray-500 leading-8">
                  You could also use real estate funds to diversify. It's
                  important to note that real estate funds are not the same as
                  REITs (real estate investment trusts). REITs are publicly
                  traded corporations that own real estate properties; they're
                  usually listed on major stock exchanges such as the New York
                  Stock Exchange or Nasdaq. They pay out dividends to
                  shareholders based on the number of profits they make each
                  quarter or year.
                </p>
                <p className="mt-4 text-xl text-gray-500 leading-8">
                  Real estate funds are typically private investment vehicles
                  that pool money from multiple investors for a specific purpose
                  (buying rental properties or development projects). Each
                  investor gets an ownership stake in these funds, but they
                  don't have voting rights over how the fund is managed or
                  distributes profits from its holdings.
                </p>

                <h1 className="mt-4 block text-2xl text-left leading-8 font-bold tracking-tight text-gray-900 sm:text-3xl">
                  Understand Unique Risks and Rewards of Investing In Real
                  Estate
                </h1>
                <p className="mt-4 text-xl text-gray-500 leading-8">
                  Real estate is one of the most stable investments in any
                  portfolio, and it's also one of the most illiquid ones. And
                  while real estate funds can offer diversification and
                  professional management, they come with unique risks and
                  rewards.
                </p>
                <p className="mt-4 text-xl text-gray-500 leading-8 font-bold">
                  Here are three reasons why you should consider investing in a
                  real estate fund:
                </p>
                <p className="mt-4 text-xl text-gray-500 leading-8 font-bold">
                  <ol className="list-decimal ml-8 mt-4 text-xl text-gray-500 leading-8">
                    <li>Real estate is a diversification tool</li>
                    <li>
                      Real estate funds have low correlation to other asset
                      classes
                    </li>
                    <li>Ability to defer taxes</li>
                  </ol>
                </p>
                <h1 className="mt-4 block text-2xl text-left leading-8 font-bold tracking-tight text-gray-900 sm:text-3xl">
                  Risks Include Taxes, Illiquidity, and Volatility
                </h1>
                <p className="mt-4 text-xl text-gray-500 leading-8">
                  Risks include taxes, illiquidity, and volatility and the
                  ability of the fund manager to predict future market trends.
                </p>
                <p className="mt-4 text-xl text-gray-500 leading-8 font-bold">
                  Taxes on Real Estate Funds
                </p>
                <p className="mt-4 text-xl text-gray-500 leading-8">
                  Real estate funds pay dividends that can be taxed at
                  individual rates as high as 39.6 percent. That means you'll
                  have to pay taxes on dividends even if you don't sell your
                  shares in the fund until years after they were purchased.
                </p>
                <p className="mt-4 text-xl text-gray-500 leading-8">
                  You may also face capital gains taxes if the value of your
                  shares rises above what they cost when you bought them. This
                  could happen even if the value increases gradually over time
                  rather than quickly.
                </p>
                <p className="mt-4 text-xl text-gray-500 leading-8 font-bold">
                  Illiquidity of Real Estate Funds
                </p>
                <p className="mt-4 text-xl text-gray-500 leading-8">
                  Real estate investments are illiquid because it can take
                  months or even years for an investor to sell a property and
                  get his money back. But there are ways around this issue: Some
                  funds allow investors to sell shares back into the fund;
                  others allow investors to redeem shares every quarter with no
                  penalty. Some offer a combination of these options.
                </p>
                <p className="mt-4 text-xl text-gray-500 leading-8 font-bold">
                  Volatility of Real Estate Funds
                </p>
                <p className="mt-4 text-xl text-gray-500 leading-8">
                  Real estate value can vary greatly depending on location and
                  other factors. Therefore, you may experience unexpected
                  swings. You may experience periods where your fund's
                  performance is lower than expected or where you're unable to
                  make withdrawals.
                </p>
                <h1 className="mt-4 block text-2xl text-left leading-8 font-bold tracking-tight text-gray-900 sm:text-3xl">
                  Add Real Estate Investments as They Meet Your Goals
                </h1>
                <p className="mt-4 text-xl text-gray-500 leading-8">
                  Real estate investments can be a great addition to your
                  portfolio. Real estate investments have been around since the
                  beginning of time, and they will continue to be around.
                </p>
                <p className="mt-4 text-xl text-gray-500 leading-8">
                  There are many different types of real estate investment, such
                  as owning property, investing in properties, buying into real
                  estate funds, or even buying into a REIT (Real Estate
                  Investment Trust).
                </p>
                <p className="mt-4 text-xl text-gray-500 leading-8 font-bold">
                  Real Estate Investments Can Be Confusing
                </p>
                <p className="mt-4 text-xl text-gray-500 leading-8">
                  The first thing that people think about when they hear about
                  investing in real estate is real estate flipping. If you have
                  seen one episode of HGTV Flipping shows, you know how
                  confusing it can be to flip a house. It takes a lot of money
                  and time to get the deal done and make money. It is also very
                  stressful because if you don't get it done within the target
                  time, it could cost you more money than what you started with!
                </p>
                <p className="mt-4 text-xl text-gray-500 leading-8">
                  Another reason why real estate can be confusing is because
                  there are so many different ways to invest in it! Many kinds
                  of property investments exist, such as single-family homes,
                  multi-unit buildings, commercial buildings, and even raw land!
                  Some people even invest in mobile home parks, which can be
                  profitable but also very risky!
                </p>
                <p className="mt-4 text-xl text-gray-500 leading-8">
                  As you evaluate your investment goals and objectives, consider
                  adding real estate as a diversifier. Depending on the type,
                  real estate can allow you to achieve your goals bringing about
                  growth and stability for your portfolio.
                </p>
              </div>
            </div>
          </div>
        </main>

        <footer className="theme-footer-eight mt-100">
          <div className="top-footer">
            <div className="container">
              <FooterEightPlenish />
            </div>
            {/* /.container */}
          </div>
          {/* /.top-footer */}

          <div className="container">
            <div className="bottom-footer-content">
              <CopyRightFourPlenish />
            </div>
            {/*  /.bottom-footer */}
          </div>
        </footer>
        {/* /.theme-footer-one */}
      </div>
    </div>
  );
};

export default BlogDetailsShouldIOwnRealEstateInMyPortfolio;
