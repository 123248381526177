import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { AiFillStar } from "react-icons/ai";

export default function TestimonialTwo() {
  const settings = {
    dots: true,
    arrow: false,
    infinite: true,
    speed: 900,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    centerMode: true,
    centerPadding: "0",
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      <div>
        <div className="bg-white shadow-md rounded-md m-2 py-3 sm:m-0">
          <div className="w-full flex flex-col items-center justify-center gap-2">
            <h1 className="text-sm text-gray-600 leading-relaxed px-3">
              "Detailed answer from a licensed expert. It was so quick and
              simple - Amazing!" &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              &nbsp; &nbsp; &nbsp;
            </h1>
            <div className="flex items-center gap-4">
              <div className="flex flex-col tracking-wider">
                <label className="text-gray-600 font-bold text-sm">
                  Ziv Z., Michigan
                </label>
                <div className="flex flex-row justify-center items-center">
                  <AiFillStar style={{ color: "#D79515" }} />
                  <AiFillStar style={{ color: "#D79515" }} />
                  <AiFillStar style={{ color: "#D79515" }} />
                  <AiFillStar style={{ color: "#D79515" }} />
                  <AiFillStar style={{ color: "#D79515" }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="bg-white shadow-md rounded-md m-2 py-3">
          <div className="w-full flex flex-col items-center justify-center gap-2">
            <h1 className="text-sm text-gray-600 leading-relaxed px-3">
              "I really appreciated how the financial advice I received was just
              that: ADVICE!" &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              &nbsp; &nbsp;
            </h1>
            <div className="flex items-center gap-4">
              <div className="flex flex-col tracking-wider">
                <label className="text-gray-600 font-bold text-sm">
                  Antonio W., New York
                </label>
                <div className="flex flex-row justify-center items-center">
                  <AiFillStar style={{ color: "#D79515" }} />
                  <AiFillStar style={{ color: "#D79515" }} />
                  <AiFillStar style={{ color: "#D79515" }} />
                  <AiFillStar style={{ color: "#D79515" }} />
                  <AiFillStar style={{ color: "#D79515" }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="bg-white shadow-md rounded-md m-2 py-3">
          <div className="w-full flex flex-col items-center justify-center gap-2">
            <h1 className="text-sm text-gray-600 leading-relaxed px-3">
              “I received an intuitive and informative answer, It was helpful
              that I was able to do follow-ups too.” &nbsp; &nbsp; &nbsp; &nbsp;
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            </h1>
            <div className="flex items-center gap-4">
              <div className="flex flex-col tracking-wider">
                <label className="text-gray-600 font-bold text-sm">
                  Alex A., Florida
                </label>
                <div className="flex flex-row justify-center items-center">
                  <AiFillStar style={{ color: "#D79515" }} />
                  <AiFillStar style={{ color: "#D79515" }} />
                  <AiFillStar style={{ color: "#D79515" }} />
                  <AiFillStar style={{ color: "#D79515" }} />
                  <AiFillStar style={{ color: "#D79515" }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Slider>
  );
}
