import React from "react";
import { Helmet } from "react-helmet";
import HeaderLandingAppointmentPlenish from "../../../components/header/landing/HeaderLandingAppointmentPlenish";
import FooterEightPlenish from "../../../components/footer/FooterEightPlenish";
import CopyRightFourPlenish from "../../../components/footer/CopyRightFourPlenish";
import { Link } from "react-router-dom";
import TestimonialTwo from "../../../components/testimonial/TestimonialTwo";
import GlobalExpansionTopics from "../../../components/features/GlobalExpansionTopics";

const GlobalExpansionLanding = () => {
  return (
    <div>
      <div className="main-page-wrapper p0 font-gordita">
        <Helmet>
          <title>Plenish | Global Expansion</title>
        </Helmet>
        {/* End Page SEO Content */}

        <HeaderLandingAppointmentPlenish />

        {/* End Header */}

        <main className="hero-banner-thirteen lg-container">
          <div className="bg-gray-50 font-gordita p-1 -mt-10">
            <div className="relative max-w-5xl mx-auto">
              <div className="text-gray-700 text-xl font-bold text-center pt-4 mb-8">
                Plenish in partnership with Global Expansion
              </div>
              <div className="px-3 sm:px-5 mx-3 bg-white rounded-2xl shadow-md">
                <div className="text-black title_promo font-bold text-center pt-4">
                  Career transitions should not put your finances at risk. We
                  are here to help.
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-12 gap-0 py-2">
                  <div className="col-span-2 text-center"></div>
                  <div className="col-span-8 text-center">
                    <div className="text-gray-600 text-sm mt-10">
                      We bring trusted advice to you, so you have one less thing
                      to worry about while you focus on landing your next big
                      opportunity
                    </div>
                    <div className="my-10">
                      <Link to="/globalexpansion_appointment">
                        <button className="bg-green-700 hover:bg-green-500 text-white font-bold py-2 px-4 rounded-full">
                          Set a time
                        </button>
                      </Link>
                    </div>
                    <div className="text-lg text-black font-bold px-2 sm:px-20 my-8">
                      Talk to one of our registered financial advisors for free
                      and let us help you navigate this transition seamlessly
                      and with peace of mind
                    </div>
                  </div>
                  <div className="col-span-2 text-center"></div>
                </div>
              </div>

              {/* <div className="slider-content"> */}
              <div className="clientSliderTwo slick-nav-none mt-20 mb-14 sm:mb-0 mx-2 sm:mx-0">
                <TestimonialTwo />
              </div>
              {/* </div> */}

              <p className="my-8 title_promo sm:px-0 text-black font-bold ml-4 sm:mx-0 text-center">
                How It Works
              </p>

              <div className="flex flex-col px-3 sm:p-auto">
                <div className="flex pb-4 sm:ml-0">
                  <div className="flex flex-nowrap grid grid-cols-12 gap-5">
                    {/* <!-- Card 1 --> */}
                    <div className="flex flex-col rounded-2xl overflow-hidden shadow-md bg-white p-4 flex-shrink-0 col-span-12 sm:col-span-4">
                      <div className="text-3xl font-semibold text-gray-900 pt-2 pb-2">
                        01
                      </div>
                      <div className="rounded-2xl promo_rectangle_1 py-3">
                        <img
                          className="object-cover mx-auto"
                          src="/images/assets/chat.svg"
                          alt=""
                        />
                      </div>
                      <div className="flex-1 bg-white p-2 flex flex-col justify-between">
                        <div className="flex-1">
                          <div className="block mt-2">
                            <p className="mt-3 text-base text-gray-500 font-bold">
                              Free consultation
                            </p>
                            <p className="mt-2 text-base text-gray-500">
                              Have your free consultation to discuss what’s most
                              important in your finances and come up with what
                              needs to be focused on
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <!-- Card 2 --> */}
                    <div className="flex flex-col rounded-2xl overflow-hidden shadow-md bg-white p-4 flex-shrink-0 col-span-12 sm:col-span-4">
                      <div className="text-3xl font-semibold text-gray-900 pt-2 pb-2">
                        02
                      </div>
                      <div className="rounded-2xl promo_rectangle_2 py-3">
                        <img
                          className="object-cover mx-auto"
                          src="/images/assets/shield_check.svg"
                          alt=""
                        />
                      </div>

                      <div className="flex-1 bg-white p-2 flex flex-col justify-between">
                        <div className="flex-1">
                          <div className="block mt-2">
                            <p className="mt-3 text-base text-gray-500 font-bold">
                              Follow Up
                            </p>
                            <p className="mt-2 text-base text-gray-500">
                              Follow up with your advisor as needed to come up
                              with an executable plan*
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <!-- Card 3 --> */}
                    <div className="flex flex-col rounded-2xl overflow-hidden shadow-md bg-white p-4 flex-shrink-0 col-span-12 sm:col-span-4">
                      <div className="text-3xl font-semibold text-gray-900 pt-2 pb-2">
                        03
                      </div>
                      <div className="rounded-2xl promo_rectangle_3 py-3">
                        <img
                          className="object-cover mx-auto"
                          src="/images/assets/light_bulb.svg"
                          alt=""
                        />
                      </div>
                      <div className="flex-1 bg-white p-2 flex flex-col justify-between">
                        <div className="flex-1">
                          <div className="block mt-2">
                            <p className="mt-3 text-base text-gray-500 font-bold">
                              Success
                            </p>
                            <p className="mt-2 text-base text-gray-500">
                              Stay on top of your finances. Your advisor will be
                              there for you to adjust your plan over time to
                              navigate life and market changes smoothly*
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <p className="text-sm sm:px-0 text-black font-base ml-4 sm:mx-0">
                * fees may apply
              </p>

              <div className="relative max-w-5xl mx-auto mt-60">
                <div
                  className="fancy-feature-twentyFour2 wide_range_of_topics"
                  id="service"
                >
                  <div className="container">
                    <div className="text-center text-black title_promo font-bold">
                      Many topics that we can work you with
                    </div>
                  </div>
                  <div className="bg-wrapper mt-50 px-2">
                    <GlobalExpansionTopics />
                  </div>
                </div>
              </div>

              <p className="my-8 title_promo sm:px-0 text-black font-bold ml-4 sm:mx-0 text-center">
                Why Plenish
              </p>

              <div className="px-3 sm:px-0">
                <dl className="space-y-4 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-8">
                  <div className="relative rounded-2xl shadow-md p-3 bg-white">
                    <div className="">
                      <div className="flex items-center">
                        <div className="flex-shrink-0 promo_rectangle_1 rounded-2xl p-3">
                          <object
                            data="/images/assets/lightning.svg"
                            className="h-8 w-8"
                          >
                            {" "}
                          </object>
                        </div>
                        <div className="ml-4">
                          <p className="text-base leading-6 font-medium text-gray-900">
                            Actionable advice
                          </p>
                          <dd className="mt-2 text-sm text-gray-500">
                            Simple and relevant guidance for you to plan and
                            invest
                          </dd>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="relative rounded-2xl shadow-md p-3 bg-white">
                    <div className="">
                      <div className="flex items-center">
                        <div className="flex-shrink-0 promo_rectangle_2 rounded-2xl p-3">
                          <object
                            data="/images/assets/lock_closed.svg"
                            className="h-8 w-8"
                          >
                            {" "}
                          </object>
                        </div>
                        <div className="ml-4">
                          <p className="text-base leading-6 font-medium text-gray-900">
                            Licensed, experienced and hand-picked network of
                            advisors
                          </p>
                          <dd className="mt-2 text-sm text-gray-500">
                            Advisors who are registered and have many years of
                            experience, selected through a rigorous process
                          </dd>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="relative rounded-2xl shadow-md p-3 bg-white">
                    <div className="">
                      <div className="flex items-center">
                        <div className="flex-shrink-0  promo_rectangle_3 rounded-2xl p-3">
                          <object
                            data="/images/assets/user_group.svg"
                            className="h-8 w-8"
                          >
                            {" "}
                          </object>
                        </div>
                        <div className="ml-4">
                          <p className="text-base leading-6 font-medium text-gray-900">
                            For investors of all levels
                          </p>
                          <dd className="mt-2 text-sm text-gray-500">
                            From newbies to veterans, we are your stepping stone
                            to get you to the next level
                          </dd>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="relative rounded-2xl shadow-md p-3 bg-white">
                    <div className="">
                      <div className="flex items-center">
                        <div className="flex-shrink-0  promo_rectangle_4 rounded-2xl p-3">
                          <object
                            data="/images/assets/heart.svg"
                            className="h-8 w-8"
                          >
                            {" "}
                          </object>
                        </div>
                        <div className="ml-4">
                          <p className="text-base leading-6 font-medium text-gray-900">
                            No strings attached
                          </p>
                          <dd className="mt-2 text-sm text-gray-500">
                            After you get your free consultation, you can decide
                            whether you need to work more with your advisor. No
                            pressure whatsoever!
                          </dd>
                        </div>
                      </div>
                    </div>
                  </div>
                </dl>
              </div>
            </div>

            <div className="relative max-w-5xl mx-auto">
              <div className="sm:grid place-content-center sm:grid-cols-12 px-2 sm:px-0 mb-4">
                <div className="text-center md:inline-flex rounded-sm w-full justify-end sm:col-span-6 p-8">
                  <img
                    className="rounded-2xl mt-30 sm:mt-0 mx-auto sm:mx-0"
                    src="/images/assets/financial_advisor_2.png"
                    alt=""
                  />
                </div>
                <div className="justify-center items-center flex col-span-6 text-gray-800 pr-2 text-md sm:text-xl px-3 sm:px-0">
                  <div>
                    {/* <p className="text-2xl text-black font-medium md:-mt-36">
                      Our mission
                    </p> */}
                    {/* <p className="mt-20"> */}
                    Life is full of transitions. We created Plenish so that
                    everyone can get the needed resources to navigate life
                    surprises smoothly and confidently
                    {/* </p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>

        <footer className="theme-footer-eight mt-100">
          <div className="top-footer">
            <div className="container">
              <FooterEightPlenish />
            </div>
            {/* /.container */}
          </div>
          {/* /.top-footer */}

          <div className="container">
            <div className="bottom-footer-content">
              <CopyRightFourPlenish />
            </div>
            {/*  /.bottom-footer */}
          </div>
        </footer>
        {/* /.theme-footer-one */}
      </div>
    </div>
  );
};

export default GlobalExpansionLanding;
