import React from "react";
import { Link } from "react-router-dom";

const BlogContent = [
  {
    img: "blog_1",
    duration: "4",
    title: `How Big Of An Investment Property Can I Afford?`,
    excerpt: `Do you know how much house you can afford? Before you start shopping for hom...`,
    routePath: "/blog_how_big_of_an_investment_property_can_i_afford",
    dealyAnimation: "",
  },
  {
    img: "blog_14",
    duration: "3",
    title: `Portfolio Diversification: Importance And Best Practices`,
    excerpt: `Portfolio diversification aims to spread capital across different assets, reducing investm...`,
    routePath: "/blog_portfolio_diversification_importance_and_best_practices",
    dealyAnimation: "",
  },
  {
    img: "blog_2",
    duration: "3",
    title: `Should I Own Real Estate In My Portfolio?`,
    excerpt: `Owning real estate can be an intelligent strategy for diversifying your investment portfolio. But bef...`,
    routePath: "/blog_should_i_own_real_estate_in_my_portfolio",
    dealyAnimation: "",
  },
  {
    img: "blog_12",
    duration: "3",
    title: `Learn About ETFs`,
    excerpt: `What are ETFs? An exchange-traded fund, or ETF, is an investment vehicle that tracks a basket of related assets, such a...`,
    routePath: "/blog_learn_about_etfs",
    dealyAnimation: "",
  },
  {
    img: "blog_3",
    duration: "4",
    title: `Checklist Of What To Consider If You Are Buying Your First Home`,
    excerpt: `Buying your first home can be overwhelming. You need to consider so many questi...`,
    routePath: "/blog_checklist_of_what_to_consider_if_you_are_buying_your_first_home",
    dealyAnimation: "",
  },
  {
    img: "blog_15",
    duration: "5",
    title: `Stock Investment Strategies`,
    excerpt: `People talk a lot about stock market investing strategies. Sometimes, there are so many options, and it can be overwhel...`,
    routePath: "/blog_stock_investment_strategies",
    dealyAnimation: "",
  },
  {
    img: "blog_4",
    duration: "5",
    title: `Home Inspection Guide For Real Estate Investing`,
    excerpt: `When buying a home, it's essential to get a full inspection. This is when a professio...`,
    routePath: "/blog_home_inspection_guide_for_real_estate_investing",
    dealyAnimation: "",
  },
  {
    img: "blog_5",
    duration: "5",
    title: `Should I Carry My Mortgage Into My Retirement`,
    excerpt: `The benefits of continuing to carry a mortgage into retirement are heavily deb...`,
    routePath: "/blog_should_i_carry_my_mortgage_into_my_retirement",
    dealyAnimation: "",
  },
  {
    img: "blog_11",
    duration: "2",
    title: `Everything About Dividends`,
    excerpt: `Dividends are a form of distributing value back to the shareholders of a company. It may be paid in cash or in the fo...`,
    routePath: "/blog_everything_about_dividends",
    dealyAnimation: "",
  },
  {
    img: "blog_6",
    duration: "3",
    title: `Diversifying Your Investment Portfolio With Real Estate`,
    excerpt: `Investing in real estate is a great way to diversify your investment portfolio by geograp...`,
    routePath: "/blog_diversifying_your_investment_portfolio_with_real_state",
    dealyAnimation: "",
  },
  {
    img: "blog_13",
    duration: "3",
    title: `How the Tax Works When You Sell Stocks`,
    excerpt: `Are you wondering how to calculate profits from selling stocks? Do you want to know how to av...`,
    routePath: "/blog_how_the_tax_works_when_you_sell_stocks",
    dealyAnimation: "",
  },
  {
    img: "blog_7",
    duration: "4",
    title: `Tips On Finding The Best Investment Broker`,
    excerpt: `If you are looking for a broker, you need to do your due diligence to find the right one. If a broker is not doin...`,
    routePath: "/blog_tips_on_finding_the_best_broker",
    dealyAnimation: "",
  },
  {
    img: "blog_8",
    duration: "3",
    title: `All You Need To Know About Private Mortgage Insurance`,
    excerpt: `Private mortgage insurance is insurance that protects lenders, not homebuyers. If you put les...`,
    routePath: "/blog_all_you_need_to_know_about_private_mortgage_insurance",
    dealyAnimation: "",
  },
  {
    img: "blog_9",
    duration: "5",
    title: `Should I Buy Or Rent?`,
    excerpt: `Whether you decide to buy or rent your next home, there are many pros and cons to weigh. Should I buy or re...`,
    routePath: "/blog_should_i_buy_or_rent",
    dealyAnimation: "",
  },
  {
    img: "blog_10",
    duration: "4",
    title: `Condo Vs. House: Which Is Best For You`,
    excerpt: `Although condos and townhouses may have drawbacks, they are still great places to live. If you are thin...`,
    routePath: "/blog_condo_vs_house_which_is_best_for_you",
    dealyAnimation: "",
  },

];

const BlogStyle1Plenish = () => {
  return (
    <>
      {BlogContent.map((item, i) => (
        <div
          className="col-lg-4 col-md-6"
          data-aos="fade-up"
          data-aos-duration="1200"
          key={i}
          data-aos-delay={item.dealyAnimation}
        >
          <div className="post-meta">
            <Link to={item.routePath} className="title">
              <img
                src={`images/blog/${item.img}.jpg`}
                alt="media"
                className="image-meta"
              />
            </Link>
            <div className="tag">{item.duration} min read</div>
            <h3>
              <Link to={item.routePath} className="title">
                {item.title}
              </Link>
            </h3>
            <h4>
              <Link to={item.routePath} className="excerpt">
                {item.excerpt}
              </Link>
            </h4>
            <Link
              to={item.routePath}
              className="read-more d-flex justify-content-between align-items-center"
            >
              <span>Read More</span>
              <i className="flaticon-right-arrow"></i>
            </Link>
          </div>
          {/* /.post-meta */}
        </div>
      ))}
    </>
  );
};

export default BlogStyle1Plenish;
