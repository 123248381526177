import React from "react";
import { Helmet } from "react-helmet";
import FancyFeatureTewentyFourPlenish from "../../components/features/FancyFeatureTewentyFourPlenish";
import FancyFeatureTewentyTwoPlenish from "../../components/features/FancyFeatureTewentyTwoPlenish";
import HeaderLandingAppointmentPlenish from "../../components/header/landing/HeaderLandingAppointmentPlenish";
import HeroBannerThirteenPlenish from "../../components/form-survey-landing/HeroBannerThirteenPlenish";
// import PricingPlenish from "../../components/form-survey-landing/PricingPlenish";
import FancyVideoPlenish from "../../components/vr-landing/FancyVideoPlenish";
import FooterEightPlenish from "../../components/footer/FooterEightPlenish";
import CopyRightFourPlenish from "../../components/footer/CopyRightFourPlenish";
import FormForAdvisorsPlenish from "../../components/form/FormForAdvisorsPlenish";
import FaqPlenish from "../../components/faq/FaqPlenish";

const PlenishHome = () => {
  // For header select menu
  // const [click1, setClick1] = useState(false);
  // const handleClick1 = () => setClick1(!click1);

  return (
    <div>
      {/* <div className="banner-promo">
        Get your questions answered,{" "}
        <Link to="/promo" className="">
          try for free
        </Link>
      </div> */}
      <div className="main-page-wrapper p0 font-gordita bg-gray-50">
        <Helmet>
          <title>Plenish | Home</title>
        </Helmet>
        {/* End Page SEO Content */}

        <HeaderLandingAppointmentPlenish />
        {/* End Header Landing Appoinment Scheduling */}

        <div id="home">
          {/* <HeroBannerNinePlenish /> */}
          <HeroBannerThirteenPlenish />
        </div>
        {/* End Hero Banner Nine */}

        {/* =============================================
            Fancy Feature Twenty Three
        ==============================================  */}
        <div
          className="fancy-feature-twentyThree pt-100 md-pt-100"
          id="feature"
        >
          <div className="container">
            <div
              className="title-style-nine text-center pb-100 md-pb-100"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <div className="upper-title">HOW IT WORKS</div>
              <h2>
                One platform for <br />
                all your{" "}
                <span>
                  financial&nbsp;questions
                  <img src="images/shape/194.svg" alt="shape" />
                </span>
              </h2>
              <p className="sm:px-5">
                Think of Plenish as a Google for all your financial questions,
                but only better, <br />
                with personalized answers from real licensed financial advisors,
                and no obligation to hire one.
              </p>
            </div>
            {/* End title */}

            <div className="block-style-twentyThree">
              <div className="row align-items-center">
                <div
                  className="col-lg-6 order-lg-last ml-auto"
                  data-aos="fade-left"
                  data-aos-duration="1200"
                >
                  <div className="screen-container ml-auto">
                    <img src="images/assets/background_shape_3.png" alt="" />
                    <img
                      src="images/assets/screenshot_1.png"
                      alt=""
                      className="shapes shape-one"
                    />
                  </div>
                  {/* /.screen-container */}
                </div>
                <div
                  className="col-lg-5 order-lg-first mt-30 md:mt-0"
                  // data-aos="fade-right"
                  // data-aos-duration="1200"
                >
                  <div className="text-wrapper">
                    <h6>Step 1</h6>
                    <h3 className="title">Ask a question</h3>
                    <p>
                      Ask us a question about your personal finances. No
                      question is too simple or too complicated when it comes to
                      your personal finances. Sit back, and we'll get to work
                      for you. We only gather the minimum amount of information
                      necessary to provide you with a great answer.
                    </p>
                  </div>
                  {/*  /.text-wrapper */}
                </div>
              </div>
            </div>
            {/* /.block-style-twentyThree */}

            <div className="block-style-twentyThree">
              <div className="row">
                <div className="col-lg-6">
                  <div
                    className="screen-container mr-auto"
                    data-aos="fade-right"
                    data-aos-duration="1200"
                  >
                    <img src="images/assets/background_shape_4.png" alt="" />
                    <img
                      src="images/assets/screenshot_2.png"
                      alt="screen"
                      className="shapes shape-two"
                    />
                  </div>
                  {/* /.screen-container */}
                </div>
                <div
                  className="col-lg-5 ml-auto mt-30 md:mt-0"
                  // data-aos="fade-left"
                  // data-aos-duration="1200"
                >
                  <div className="text-wrapper">
                    <h6>Step 2</h6>
                    <h3 className="title">Get an answer</h3>
                    <p>
                      From one of our registered financial advisors who we have
                      background checked and handpicked. Your information is
                      private and never shared for any advertisements, product
                      marketing, or other services.
                    </p>
                  </div>
                  {/* /.text-wrapper */}
                </div>
              </div>
            </div>
            {/* /.block-style-twentyThree */}

            <div className="block-style-twentyThree">
              <div className="row">
                <div
                  className="col-lg-6 order-lg-last ml-auto"
                  data-aos="fade-left"
                  data-aos-duration="1200"
                >
                  <div className="screen-container ml-auto">
                    <img src="images/assets/background_shape_1.png" alt="" />
                    <img
                      src="images/assets/screenshot_3.png"
                      alt="screen"
                      className="shapes shape-one"
                    />
                  </div>
                  {/* /.screen-container */}
                </div>
                <div
                  className="col-lg-5 order-lg-first mt-30 md:mt-0"
                  // data-aos="fade-right"
                  // data-aos-duration="1200"
                >
                  <div className="text-wrapper">
                    <h6>Step 3</h6>
                    <h3 className="title">Dive deeper</h3>
                    <p>
                      You will have the option to reconnect with the advisor who
                      answered your question with any follow-ups you may have.
                    </p>
                  </div>
                  {/* /.text-wrapper */}
                </div>
              </div>
            </div>
            {/* /.block-style-twentyThree */}

            <div className="block-style-twentyThree">
              <div className="row">
                <div className="col-lg-6">
                  <div
                    className="screen-container mr-auto"
                    data-aos="fade-right"
                    data-aos-duration="1200"
                  >
                    <img src="images/assets/background_shape_2.png" alt="" />
                    <img
                      src="images/assets/screenshot_4.png"
                      alt="screen"
                      className="shapes shape-two"
                    />
                  </div>
                  {/* /.screen-container */}
                </div>
                <div
                  className="col-lg-5 ml-auto mt-30 md:mt-0"
                  // data-aos="fade-left"
                  // data-aos-duration="1200"
                >
                  <div className="text-wrapper">
                    <h6>Step 4</h6>
                    <h3 className="title">Engage further</h3>
                    <p>
                      Learn more about all the different aspects of your
                      financial life. Come back with other questions, engage
                      with our advisors at your own leisure to find best
                      solutions, and learn from our unique videos and contents
                      on a broad range of topics.
                    </p>
                  </div>
                  {/* /.text-wrapper */}
                </div>
              </div>
            </div>
            {/* /.block-style-twentyThree */}
          </div>
        </div>
        {/* /.fancy-feature-twentyThree */}

        {/* <!-- 
			=============================================
				Fancy Feature Thirty Nine
			============================================== 
			--> */}

        <div className="fancy-feature-thirtyNine lg-container position-relative">
          <div className="container">
            <div className="row align-items-center">
              <div
                className="col-lg-6 order-lg-first ml-auto"
                data-aos="fade-left"
                data-aos-duration="1200"
              >
                <div className="screen-container ml-auto mt-5">
                  <FancyVideoPlenish />
                </div>
              </div>
              <div
                className="col-lg-5 order-lg-last"
                data-aos="fade-right"
                data-aos-duration="1200"
              >
                <div className="text-wrapper md:mt-n5 mb-50 md:mb-0">
                  <img
                    src="images/shape/expert_advice.png"
                    alt="vr"
                    className="mx-auto w-12 sm:w-50 md:w-64 lg:w-72"
                    data-aos="fade-up"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- /.fancy-feature-thirtyNine --> */}

        {/* <!--
        =====================================================
            Pricing Section Nine
        =====================================================
        --> */}
        {/* <div
          className="pricing-section-nine lg-container pt-50"
          id="pricing-plenish"
        >
          <div className="container">
            <div
              className="title-style-eleven text-center mb-40 md-mb-20"
              data-aos="fade-up"
            >
              <div className="upper-title">PRICING</div>
              <h2>Three plans tailored to your needs</h2>
            </div>

            <div className="row justify-content-center">
              <PricingPlenish />
            </div>
          </div>
        </div> */}
        {/* <!-- /.pricing-section-nine --> */}

        {/* <!--
        =====================================================
            Feature Blog Five
        =====================================================
        --> */}
        {/* <div className="feature-blog-five lg-container mt-160 md-pt-120">
                <div className="container">
                    <div
                        className="title-style-eleven text-center mb-40 md-mb-20"
                        data-aos="fade-up"
                    >
                        <h2>Inside Story</h2>
                        <p className="mt-25 md-mt-10">
                            Automatically send people to the next stage of the journey{" "}
                        </p>
                    </div>

                    <div className="row">
                        <BlogPlenish />
                    </div>
                </div>
            </div> */}
        {/* <!-- /.feature-blog-five --> */}

        {/*=====================================================
				Useable Tools
			===================================================== */}
        <div className="container mt-40">
          <div className="row">
            <div className="col-lg-6 col-md-6 m-auto">
              <div className="px-3 sm:px-5">
                <div className="bg-wrapper flex">
                  <div className="text-xl lg:text-3xl font-semibold text-black text-center mb-4 mb-sm-0">
                    <div>
                      Did you know that 89% of wealthy families in US use
                      professional financial advisors?
                    </div>
                    <div className="pt-3 pt-lg-5">
                      At Plenish,{" "}
                      <span style={{ color: "#008766" }}>
                        we demystify the world of personal investing
                      </span>{" "}
                      by bringing similar services to you à la carte.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 m-auto">
              <img
                src="/images/assets/happy_girl_box.jpg"
                alt=""
                className="picture_hero sm:mt-0"
              />
            </div>
          </div>
        </div>

        {/* =============================================
            Fancy Feature Twenty Two
        ==============================================  */}

        <div className="fancy-feature-twentyTwo mt-50 md-mt-50">
          <div className="container">
            <div className="row">
              <div
                className="m-auto"
                data-aos="fade-up"
                data-aos-duration="1200"
              >
                <div className="title-style-nine text-center mt-20 lg:mb-10">
                  <div className="upper-title">OUR VALUE</div>
                  <h2>What Plenish brings to you</h2>
                </div>
              </div>
            </div>

            <div className="row justify-content-center">
              <FancyFeatureTewentyTwoPlenish />
            </div>
          </div>
        </div>

        {/* 	=============================================
            Fancy Feature Twenty Four
        ==============================================  */}
        <div
          className="fancy-feature-twentyFour wide_range_of_topics"
          id="service"
        >
          <div className="container">
            <div className="title-style-nine text-center">
              <h6>Wide range of topics</h6>
              <h2>
                Examples of what{" "}
                <span>
                  you can ask about{" "}
                  <img src="images/shape/194.svg" alt="shape" />
                </span>
              </h2>
            </div>
          </div>
          <div className="bg-wrapper">
            <div className="mt-30">
              <div className="container">
                <FancyFeatureTewentyFourPlenish />
              </div>
            </div>
          </div>
          {/* /.bg-wrapper */}
          <div id="faq"></div>
        </div>
        {/* End .fancy feature twenty four */}

        {/* 
     =============================================
				Faq 
		============================================== */}

        <div className="fancy-text-block-six overflow-hidden mt-50" id="faq">
          <div className="container">
            {/* <div className="row align-items-center"> */}
            <div
              // className="col-lg-6"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <div className="title-style-five text-center mb-80 md-mb-60">
                <div className="upper-title">FAQ</div>
                <h2>
                  <span>Questions & Answers</span>
                </h2>
              </div>
              {/* End title */}
              <FaqPlenish />
              {/* </div> */}
            </div>
          </div>
        </div>

        {/* /.why should you choose us */}

        {/* =====================================================
            Client Feedback Slider Six
        ===================================================== */}
        {/* <div
                    className="client-feedback-slider-six pt-100 md-pt-80 pb-40 md-pb-40"
                    id="feedback"
                >
                    <div className="inner-container">
                        <div className="container">
                            <div className="row">
                                <div
                                    className="col-lg-7 m-auto"
                                    data-aos="fade-up"
                                    data-aos-duration="1200"
                                >
                                    <div className="title-style-nine text-center">
                                        <div className="upper-title">TESTIMONIALS</div>
                                        <h2>
                                            What Our {" "}
                                            <span>
                                                Clients <img src="images/shape/201.svg" alt="shape" />
                                            </span>
                                            {" "}Think About Us.
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="clientSliderSix style-two">
                            <TestimonialSeven />
                        </div>
                    </div>
                </div> */}

        {/*     =====================================================
        Partner Section One
    ===================================================== */}
        {/* <div className="partner-section-one mt-170 md-mt-90 pb-120 md-pb-80">
                <div className="container">
                    <div className="title-style-nine text-center mb-100">
                        <h6>Our Partners</h6>
                        <h2>
                            They
                            <span>
                                Trust Us, <img src="images/shape/201.svg" alt="shape" />
                            </span>{" "}
                            & Vice Versa
                        </h2>
                    </div> */}
        {/* End title-style-nine */}
        {/* <SocialTwo />
                </div> */}
        {/* End .container */}

        {/* <img
                    src="images/shape/202.svg"
                    alt="shape"
                    className="shapes shape-one"
                />
                <img
                    src="images/shape/203.svg"
                    alt="shape"
                    className="shapes shape-two"
                />
                <img
                    src="images/shape/204.svg"
                    alt="shape"
                    className="shapes shape-three"
                />
                <img
                    src="images/shape/205.svg"
                    alt="shape"
                    className="shapes shape-four"
                />
                <img
                    src="images/shape/206.svg"
                    alt="shape"
                    className="shapes shape-five"
                />
                <img
                    src="images/shape/207.svg"
                    alt="shape"
                    className="shapes shape-six"
                />
                <img
                    src="images/shape/208.svg"
                    alt="shape"
                    className="shapes shape-seven"
                />
                <img
                    src="images/shape/209.svg"
                    alt="shape"
                    className="shapes shape-eight"
                />
            </div> */}
        {/*  /.partner-section-one */}

        {/* 	=====================================================
				Fancy Short Banner Ten
			===================================================== */}
        <div
          className="fancy-short-banner-ten max-w-7xl mx-auto "
          id="for-advisors"
        >
          {/* <div className="container"> */}
          {/* <div className="row"> */}
          <div
            className="col-xl-12 col-lg-12 m-auto"
            data-aos="fade-up"
            data-aos-duration="1200"
          >
            {/* <div className="container"> */}
            <div className="row">
              <div className="col-lg-6 col-md-6 m-auto order-lg-first">
                <img
                  src="/images/assets/financial_advisor_landscape_2.jpg"
                  className="picture_hero"
                  alt=""
                />
              </div>
              <div className="col-lg-6 col-md-6 m-auto order-lg-last">
                <div className="px-0 md:px-5 mt-20 md:mt-0">
                  <div className="bg-wrapper">
                    <div className="text-center mb-20">
                      <h2>For advisors</h2>
                    </div>
                    <div className="title-style-two">
                      <h4>
                        If you are a fee-only, registered advisor who wants to
                        be part of Plenish's network of advisors, please contact
                        us through the form below to learn more.
                      </h4>
                      <div
                        data-aos="fade-up"
                        data-aos-duration="1200"
                        data-aos-delay="150"
                      >
                        <FormForAdvisorsPlenish />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* </div> */}
              {/* </div> */}
            </div>
          </div>
        </div>

        {/*  =====================================================
            Footer Style Eight
        ===================================================== */}
        <footer className="theme-footer-eight mt-100">
          <div className="top-footer">
            <div className="container">
              <FooterEightPlenish />
            </div>
            {/* /.container */}
          </div>

          <div className="container">
            <div className="bottom-footer mt-50 md-mt-30">
              <CopyRightFourPlenish />
            </div>
          </div>
        </footer>
        {/* /.theme-footer-eight  */}
      </div>
    </div>
  );
};

export default PlenishHome;
