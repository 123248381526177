import React from "react";

const GlobalExpansionTopics = () => {
  return (
    <div className="row">
      <div
        className="col-12 col-sm-6 col-lg-4 col-md-6 d-flex mb-35"
        data-aos="fade-up"
        data-aos-duration="1200"
      >
        <div className="p-6 border-2 border-gray-300 rounded-2xl">
          <div className="text-center">
            <div className="promo_rectangle_5 w-16 rounded-xl p-2 mx-auto">
              <img
                src="images/assets/icon_plenish_9.png"
                alt="icon"
                className="h-12 w-12 mx-auto"
              />
            </div>
            <div className="text-center">
              <div className="text-base text-black font-semibold py-3">
                Financial planning and budgeting
              </div>
              {/* <div className="text-sm">
                Buy or sell a property, rental property investment, REIT
                investments, 1031 Exchange
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <div
        className="col-12 col-sm-6 col-lg-4 col-md-6 d-flex mb-35"
        data-aos="fade-up"
        data-aos-duration="1200"
      >
        <div className="p-6 border-2	border-gray-300 rounded-2xl">
          <div className="text-center">
            <div className="promo_rectangle_5 w-16 rounded-xl p-2 mx-auto">
              <img
                src="images/assets/icon_plenish_2.png"
                alt="icon"
                className="h-12 w-12 mx-auto"
              />
            </div>
            <div className="text-center">
              <div className="text-base text-black font-semibold py-3">
                Retirement planning, handling your 401(k) or IRA
              </div>
              {/* <div className="text-sm">
                Marriage, Divorce financial advice, expecting a child, college
                saving
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <div
        className="col-12 col-sm-6 col-lg-4 col-md-6 d-flex mb-35"
        data-aos="fade-up"
        data-aos-duration="1200"
      >
        <div className="p-6 border-2	border-gray-300 rounded-2xl">
          <div className="text-center">
            <div className="promo_rectangle_5 w-16 rounded-xl p-2 mx-auto">
              <img
                src="images/assets/icon_plenish_14.png"
                alt="icon"
                className="h-12 w-12 mx-auto"
              />
            </div>
            <div className="text-center">
              <div className="text-base text-black font-semibold py-3">
                Company stock compensation
              </div>
              {/* <div className="text-sm">
                Trust set up, property inheritance, tax efficient wealth
                transfer, estate planning
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <div
        className="col-12 col-sm-6 col-lg-4 col-md-6 d-flex mb-35"
        data-aos="fade-up"
        data-aos-duration="1200"
      >
        <div className="p-6 border-2	border-gray-300 rounded-2xl">
          <div className="text-center">
            <div className="promo_rectangle_5 w-16 rounded-xl p-2 mx-auto">
              <img
                src="images/assets/icon_plenish_6.png"
                alt="icon"
                className="h-12 w-12 mx-auto"
              />
            </div>
            <div className="text-center">
              <div className="text-base text-black font-semibold py-3">
                Investing strategies in career transition and beyond
              </div>
              {/* <div className="text-sm">
                Retirement saving strategies, 401(k), social security, pensions
                and IRAs
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <div
        className="col-12 col-sm-6 col-lg-4 col-md-6 d-flex mb-35"
        data-aos="fade-up"
        data-aos-duration="1200"
      >
        <div className="p-6 border-2	border-gray-300 rounded-2xl">
          <div className="text-center">
            <div className="promo_rectangle_5 w-16 rounded-xl p-2 mx-auto">
              <img
                src="images/assets/icon_plenish_7.png"
                alt="icon"
                className="h-12 w-12 mx-auto"
              />
            </div>
            <div className="text-center">
              <div className="text-base text-black font-semibold py-3">
                And many more topics from real estate investing to wealth
                transfer and managing other life milestones
              </div>
              {/* <div className="text-sm">
                Life insurance, liability insurance, fixed annuity, variable
                annuity
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GlobalExpansionTopics;
