import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useContext } from "react";
import { FormContext } from "../../../FormApp";
import * as yup from "yup";
import Stepper from "../../Stepper";

function Step1(props) {
  const { activeStepIndex, setActiveStepIndex, formData, setFormData } =
    useContext(FormContext);

  const renderError = (message) => (
    <p className="italic text-red-600 text-xs">{message}</p>
  );

  const ValidationSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    email: yup
      .string()
      .required("Email is required")
      .email("Entered value does not match email format"),
  });

  return (
    <div>
      <Stepper />
      <div className="text-black font-medium text-3xl px-4 text-center">
        Let's get started on getting you the best answer
      </div>
      <div className="text-gray-500 text-base px-4 mt-6 text-center">
        We just need a little basic info from you before we begin.
      </div>

      <Formik
        initialValues={{
          name: "",
          email: "",
          question: "",
        }}
        validationSchema={ValidationSchema}
        onSubmit={(values) => {
          const data = { ...formData, ...values };
          setFormData(data);
          setActiveStepIndex(activeStepIndex + 1);
          window.scrollTo(0, 0);
        }}
      >
        <Form className="flex flex-col justify-center items-center w-full my-4">
          <div className="w-full">
            <div className="mx-4">
              <div className="grid grid-cols-1 sm:grid-cols-12 gap-4">
                <div className="col-span-12 text-center">
                  <div className="flex flex-col items-start mb-2">
                    <label className="font-normal text-gray-500 text-sm pb-1">
                      Name
                    </label>
                    <Field
                      name="name"
                      className="rounded-md border p-3 w-full border-gray-800 text-base"
                      placeholder="Jamie Smith"
                    />
                  </div>
                  <ErrorMessage name="name" render={renderError} />
                </div>
                <div className="col-span-12 text-center">
                  <div className="flex flex-col items-start mb-2">
                    <label className="font-normal text-gray-500 text-sm pb-1">
                      Email
                    </label>
                    <Field
                      name="email"
                      className="rounded-md border p-3 w-full border-gray-800 text-base"
                      placeholder="jamie.smith@email.com"
                    />
                  </div>
                  <ErrorMessage name="email" render={renderError} />
                </div>
              </div>
              <div className="text-gray-500 text-sm px-4 mt-6">
                We need your email address to send you the answer from our
                expert financial advisor. Your email address will not be shared
                with the advisor
              </div>
              <div className="flex mt-4">
                <button
                  className="w-full text-center py-2 text-white duration-150 bg-green-700 rounded-3xl focus:shadow-outline hover:bg-green-800"
                  type="submit"
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </Form>
      </Formik>
    </div>
  );
}

export default Step1;
