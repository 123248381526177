import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const socialContent = [
  {
    icon: "fa-instagram",
    link: "https://www.instagram.com/plenishfinance",
  },
  {
    icon: "fa-twitter",
    link: "https://twitter.com/plenish",
  },
];

const FooterEight = () => {
  return (
    <div className="row">
      <div className="col-xl-4 col-lg-4 col-md-4 col-12 footer-about-widget">
        <div className="logo">
          <a href="index">
            <img src="images/logo/logo.png" alt="" className="w-50" />
          </a>
        </div>
        <ul className="font-rubik mt-10">
          <li>
            <a href="mailto:hello@plenishwealth.com" className="email">
              hello@plenishwealth.com
            </a>
          </li>
        </ul>
        <ul className="d-flex social-icon">
          {socialContent.map((val, i) => (
            <li key={i}>
              <a
                href={val.link}
                target="_blank"
                rel="noreferrer"
                className="mr-3"
              >
                <i className={`fa ${val.icon}`}></i>
              </a>
            </li>
          ))}
        </ul>
      </div>
      {/*  /.about-widget */}
      <div className="col-xl-4 col-lg-4 col-md-4 footer-list">
        <h5 className="footer-title">Links</h5>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <HashLink to="/#faq">FAQ</HashLink>
          </li>
          <li>
            <Link to="/blog">Blog</Link>
          </li>
          <li>
            <Link to="/promo">Get Started</Link>
          </li>
        </ul>
      </div>
      {/* /.footer-list */}
      <div className="col-xl-4 col-lg-4 col-md-4 footer-list">
        <h5 className="footer-title">Legal</h5>
        <ul>
          <li>
            <Link to="/terms-conditions">Terms & Conditions</Link>
          </li>
          <li>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </li>
        </ul>
      </div>
      {/* /.footer-list */}
      {/* <div className="col-xl-2 col-lg-3 col-md-4 footer-list">
        <div className="col-lg-6 order-lg-3 mb-20">
          <ul
            className="d-flex
              justify-content-center justify-content-lg-end
              social-icon"
          >
            {socialContent.map((val, i) => (
              <li key={i}>
                <a href={val.link} target="_blank" rel="noreferrer" className="ml-3">
                  <i className={`fa ${val.icon}`}></i>
                </a>
              </li>
            ))}
          </ul> */}
      {/* End .social-icon */}
      {/* </div> */}
      {/* <h5 className="footer-title">Products</h5>
        <ul>
          <li>
            <Link to="/product-customer-support">Take the tour</Link>
          </li>
          <li>
            <Link to="/login">Live chat</Link>
          </li>
          <li>
            <Link to="/solution-management">Self-service</Link>
          </li>
          <li>
            <Link to="/doc-box">Docs</Link>
          </li>
          <li>
            <Link to="/doc-box-with-banner">Info Box</Link>
          </li>
          <li>
            <Link to="/blog-details">deski Reviews</Link>
          </li>
        </ul> */}
      {/* </div> */}
      {/* /.footer-list */}
    </div>
    //   /.row
  );
};

export default FooterEight;
