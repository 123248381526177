import React from "react";
import { Helmet } from "react-helmet";
import HeaderLandingAppointmentPlenish from "../../../components/header/landing/HeaderLandingAppointmentPlenish";
import FooterEightPlenish from "../../../components/footer/FooterEightPlenish";
import CopyRightFourPlenish from "../../../components/footer/CopyRightFourPlenish";
import TestimonialTwo from "../../../components/testimonial/TestimonialTwo";
import FancyFeatureTewentyFourPlenish from "../../../components/features/FancyFeatureTewentyFourPlenish";

import { Link } from "react-router-dom";

const PlenishPromo1 = () => {
  return (
    <div>
      <div className="main-page-wrapper p0 font-gordita">
        <Helmet>
          <title>Plenish | Promo</title>
        </Helmet>
        {/* End Page SEO Content */}

        <HeaderLandingAppointmentPlenish />

        {/* End Header */}

        <main>
          <div className="bg-white mt-80 font-gordita">
            <div></div>
            <div className="relative max-w-5xl mx-auto">
              <div className="flex mt-2 mb-3 mx-4">
                <div className="text-3xl text-black mx-auto font-bold">
                  Find answers that you can trust
                </div>
              </div>

              {/* how it works */}
              <div className="flex flex-col px-3 sm:p-auto">
                <div className="flex sm:ml-0">
                  <div className="px-2 text-base">
                    Now you have access to a credentialed financial advisor.
                    Simply ask your question and receive an answer by email
                    within 24 hours. You can ask about a wide range of topics,
                    from retirement planning to real estate investment
                    strategies, to how financial products work.
                  </div>
                </div>
              </div>

              <Link to={"/get-started"} className="flex my-4 justify-center">
                <button className="text-center mx-16 px-16 py-2 text-white duration-150 bg-green-700 rounded-lg focus:shadow-outline hover:bg-green-800">
                  Get Started
                </button>
              </Link>

              <div className="flex my-2 mx-16">
                <div className="text-xs text-black mx-auto font-normal">
                  No credit card required. No marketing or sales pitch will
                  follow. Questions and answers are 100% confidential, and your
                  contact information will{" "}
                  <span className="font-semibold">never</span> be shared with
                  anyone.
                </div>
              </div>

              {/* testimonial */}
              <div className="slider-content">
                <div className="clientSliderTwo slick-nav-none mb-14 mx-2">
                  <TestimonialTwo />
                </div>
              </div>

              {/* picture */}
              <div className="sm:hidden mx-3 my-4">
                <div className="relative lg:grid lg:grid-cols-1 lg:gap-8 lg:items-center">
                  <div className="relative mx-0 lg:mt-12" aria-hidden="true">
                    <div className="object-coverflex inline-block align-text-bottom">
                      <img
                        className="inset-0 w-full mx-auto rounded-r-md"
                        src="/images/assets/promo_2_mobile.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="hidden md:block mb-128 sm:mb-0">
                <div className="max-w-lg mx-auto grid gap-6 lg:grid-cols-2 lg:max-w-none mt-8 sm:shadow-md sm:rounded-2xl bg-white">
                  <img
                    className="inset-0 w-full mx-auto rounded-r-md"
                    src="/images/assets/promo_2_desktop.jpg"
                    alt=""
                  />
                </div>
              </div>

              <div className="hidden md:block">
                <div className="sm:grid place-content-center sm:grid-cols-12 sm:px-0">
                  <div className="col-span-12 md:inline-flex sm:col-span-6 text-md sm:text-lg justify-end text-center promo_rectangle_1 text-white font-bold py-16 px-3 sm:py-32 sm:pr-32 ">
                    <div className="promo_width text-xl">
                      Did you know 89% of wealthy families in the US have a
                      professional advisor?
                    </div>
                  </div>
                  <div className="col-span-12 md:inline-flex sm:col-span-6 text-md sm:text-lg justify-start text-center  promo_rectangle_5 text-white font-bold py-16 px-3 sm:py-32 sm:pl-32">
                    <div className="promo_width text-xl">
                      At Plenish, we demistify the world of personal investing
                      by bringing similar services that are catered to you.
                    </div>
                  </div>
                </div>
              </div>

              <div className="sm:hidden">
                <div className="sm:grid place-content-center sm:grid-cols-12 sm:px-0">
                  <div className="col-span-12 md:inline-flex sm:col-span-6 text-md sm:text-lg justify-end text-center promo_rectangle_1 text-white font-bold py-16 px-3 sm:py-32 sm:pr-32 ">
                    <div className="text-xl">
                      Did you know 89% of wealthy families in the US have a
                      professional advisor?
                    </div>
                  </div>
                  <div className="col-span-12 md:inline-flex sm:col-span-6 text-md sm:text-lg justify-start text-center  promo_rectangle_5 text-white font-bold py-16 px-3 sm:py-32 sm:pl-32">
                    <div className="text-xl">
                      At Plenish, we demistify the world of personal investing
                      by bringing similar services that are catered to you.
                    </div>
                  </div>
                </div>
              </div>

              {/* 	=============================================
            Fancy Feature Twenty Four
        ==============================================  */}
              <div
                className="fancy-feature-twentyFour2 wide_range_of_topics"
                id="service"
              >
                <div className="container">
                  <div className="title-style-nine text-center">
                    <h6>Wide range of topics</h6>
                    <h2>
                      Examples of what{" "}
                      <span>
                        you can ask about{" "}
                        <img src="images/shape/194.svg" alt="shape" />
                      </span>
                    </h2>
                  </div>
                </div>
                <div className="bg-wrapper -mt-100 md-mt-80">
                  {/* <div className="container -mt-32 md:mt-0 "> */}
                  <FancyFeatureTewentyFourPlenish />
                  {/* </div> */}
                </div>
                {/* /.bg-wrapper */}
              </div>
              {/* End .fancy feature twenty four */}

              <p className="mt-20 py-2 text-2xl sm:px-0 text-black font-medium ml-4 sm:mx-0">
                What we offer
              </p>
              <div className="px-3 sm:px-0">
                <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-4 md:gap-y-4">
                  <div className="relative rounded-2xl shadow-md p-3">
                    <div className="">
                      <div className="flex items-center">
                        <div className="flex-shrink-0 promo_rectangle_4 rounded-2xl p-3">
                          <object
                            data="/images/assets/lightning.svg"
                            className="h-8 w-8"
                          >
                            {" "}
                          </object>
                        </div>
                        <div className="ml-4">
                          <p className="text-lg leading-6 font-medium text-gray-900">
                            Actionable advice
                          </p>
                          <dd className="mt-2 text-base text-gray-500">
                            You will get simplified, relevant guidance to invest
                            and plan.
                          </dd>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="relative rounded-2xl shadow-md p-3">
                    <div className="">
                      <div className="flex items-center">
                        <div className="flex-shrink-0 promo_rectangle_4 rounded-2xl p-3">
                          <object
                            data="/images/assets/lock_closed.svg"
                            className="h-8 w-8"
                          >
                            {" "}
                          </object>
                        </div>
                        <div className="ml-4">
                          <p className="text-lg leading-6 font-medium text-gray-900">
                            Safe and confidential
                          </p>
                          <dd className="mt-2 text-base text-gray-500">
                            Your information will never be shared or sold to
                            anyone.
                          </dd>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="relative rounded-2xl shadow-md p-3">
                    <div className="">
                      <div className="flex items-center">
                        <div className="flex-shrink-0  promo_rectangle_4 rounded-2xl p-3">
                          <object
                            data="/images/assets/user_group.svg"
                            className="h-8 w-8"
                          >
                            {" "}
                          </object>
                        </div>
                        <div className="ml-4">
                          <p className="text-lg leading-6 font-medium text-gray-900">
                            For investors at all levels
                          </p>
                          <dd className="mt-2 text-base text-gray-500">
                            From newbies to veterans, we are your stepping stone
                            to get you to the next level.
                          </dd>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="relative rounded-2xl shadow-md p-3">
                    <div className="">
                      <div className="flex items-center">
                        <div className="flex-shrink-0  promo_rectangle_4 rounded-2xl p-3">
                          <object
                            data="/images/assets/heart.svg"
                            className="h-8 w-8"
                          >
                            {" "}
                          </object>
                        </div>
                        <div className="ml-4">
                          <p className="text-lg leading-6 font-medium text-gray-900">
                            Free for everyone
                          </p>
                          <dd className="mt-2 text-base text-gray-500">
                            Get your first 5 answers for free. No subscriptions,
                            obligations, or hidden fees.
                          </dd>
                        </div>
                      </div>
                    </div>
                  </div>
                </dl>
              </div>

              {/* <p className="mt-20 py-2 text-2xl sm:px-0 text-black font-medium ml-4 sm:mx-0">
                How It Works
              </p> */}
            </div>

            <div className="relative max-w-5xl mx-auto mt-30">
              <div className="sm:grid place-content-center sm:grid-cols-12 px-2 sm:px-0">
                <div className="justify-center items-center flex col-span-8 pr-2 text-md sm:text-lg px-3 sm:px-0">
                  <div>
                    {/* <p className="text-2xl text-black font-medium md:-mt-36">
                      Our mission
                    </p> */}
                    <p className="mt-20">
                      We believe everyone should have financial wellness. That
                      is why we start with you as our primary focus. We created
                      Plenish to help everyone gain insights and advice from our
                      national network of handpicked advisors.
                    </p>
                    <p className="mt-20">
                      Our platform makes it easy for people at all level of
                      experience to create a financial strategy and achieve
                      their goals.
                    </p>
                  </div>
                </div>
                <div className="text-center md:inline-flex rounded-sm w-full justify-end sm:col-span-4">
                  <img
                    className="h-96 rounded-2xl mt-30 sm:mt-0 mx-auto sm:mx-0"
                    src="/images/assets/financial_advisor_2.png"
                    alt="financial advisor"
                  />
                </div>
              </div>
            </div>
          </div>
        </main>

        <footer className="theme-footer-eight mt-100">
          <div className="top-footer">
            <div className="container">
              <FooterEightPlenish />
            </div>
            {/* /.container */}
          </div>
          {/* /.top-footer */}

          <div className="container">
            <div className="bottom-footer-content">
              <CopyRightFourPlenish />
            </div>
            {/*  /.bottom-footer */}
          </div>
        </footer>
        {/* /.theme-footer-one */}
      </div>
    </div>
  );
};

export default PlenishPromo1;
