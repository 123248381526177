import React from "react";
import { Helmet } from "react-helmet";

const ThankYouAdvisor = () => {
  function returnPreviousPage() {
    window.location = document.referrer;
  }

  return (
    <div className="main-page-wrapper p0 font-gordita">
      <Helmet>
        <title>Plenish | Thank You</title>
      </Helmet>
      <div className="bg-neutral-50 px-8 sm:px-0">
        <div className="flex h-screen">
          <div className="m-auto">
            <div className="flex items-center justify-center px-8 sm:px-0">
              <img className="mx-0" src="images/logo/logo.png" alt="" />
            </div>
            <div className="mt-6 text-lg sm:text-2xl font-semibold text-green-800">
              Thank you! You will be contacted shortly!
            </div>
            <div className="flex items-center justify-center">
              <button
                onClick={returnPreviousPage}
                className="mt-16 w-full inline-flex items-center justify-center px-8 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:w-auto"
              >
                Back
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThankYouAdvisor;
