// import axios from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useContext } from "react";
import { FormContext } from "../../../InvestDivaForm";
import * as yup from "yup";
import Stepper from "../../Stepper";
import WhatCanYouaskAbout from "../../../../../views/inner-pages/blog-pages/WhatCanYouaskAbout";

function Step2() {
  const { activeStepIndex, setActiveStepIndex, formData, setFormData } =
    useContext(FormContext);
  // const [message, setMessage] = React.useState("");
  const [open, setOpen] = React.useState(false);

  function closeModal() {
    setOpen(false);
  }

  function openModal() {
    setOpen(true);
  }

  const renderError = (message) => (
    <p className="italic text-red-600">{message}</p>
  );

  const ValidationSchema = yup.object().shape({
    question: yup.string().required(),
  });

  return (
    <div>
      <Stepper />
      <WhatCanYouaskAbout
        closeModal={closeModal}
        openModal={openModal}
        open={open}
      />
      <div className="text-black font-medium text-3xl px-4 text-center">
        What can we answer for you?
      </div>
      <div className="text-gray-500 text-base px-4 mt-6 text-center">
        You can ask about a wide range of financial subjects, including
        retirement, planning, college savings and many more!
      </div>
      <button
        onClick={openModal}
        className="text-sm text-green-700 mt-2 mx-auto"
      >
        See Examples of Common Questions
      </button>

      <Formik
        initialValues={{
          question: "",
        }}
        validationSchema={ValidationSchema}
        onSubmit={(values) => {
          console.log(values);
          const data = { ...formData, ...values };
          setFormData(data);
          const { name, email, question } = formData;
          console.log(question);
          fetch(
            "https://script.google.com/macros/s/AKfycbxlgcXfoxFHlZxghAVrqNCBRaW_f7ViXQABoNUlRVqJrUQ8UiOA01qaycCzJW66j14K/exec?" +
              "Type=DivaInvest" +
              "&LastName=" +
              name +
              "&Email=" +
              email +
              "&Question=" +
              values["question"],
            { method: "POST" }
          ).then((res) => {
            console.log(res.status);
            return res.text();
          });
          setActiveStepIndex(activeStepIndex + 1);
          window.scrollTo(0, 0);
        }}
      >
        <Form className="flex flex-col justify-center items-center w-full my-4">
          <div className="w-full">
            <div className="mx-4">
              <div className="flex flex-col items-start mb-2">
                <label className="font-normal text-gray-500 text-sm pb-1">
                  Your question
                </label>
                <Field
                  component="textarea"
                  rows="5"
                  name="question"
                  className="rounded-md border p-3 w-full border-gray-800 text-base"
                  placeholder="Example: How should I catch up on my Roth IRA and 401(K) contributions?"
                />
              </div>
              <ErrorMessage name="workspaceName" render={renderError} />
              <div className="text-sm text-left">Limit: 500 characters</div>

              <div className="flex mt-4">
                <button
                  className="w-full text-center py-2 text-white duration-150 bg-green-700 rounded-3xl focus:shadow-outline hover:bg-green-800"
                  type="submit"
                  // onClick={sendData}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </Form>
      </Formik>
    </div>
  );
}

export default Step2;
