import React from "react";

const FancyFeatureTewentyFourPlenish = () => {
  return (
    <div className="row">
      <div
        className="col-12 col-sm-6 col-lg-4 col-md-6 d-flex mb-35"
        data-aos="fade-up"
        data-aos-duration="1200"
      >
        <div className="p-6 border-2 border-gray-300 rounded-2xl">
          <div className="text-center">
            <div className="promo_rectangle_5 w-16 rounded-xl p-2 mx-auto">
              <img
                src="images/assets/icon_plenish_9.png"
                alt="icon"
                className="h-12 w-12 mx-auto"
              />
            </div>
            <div className="text-center">
              <div className="text-base text-black font-semibold py-3">
                Real Estate
              </div>
              <div className="text-sm">
                Buy or sell a property, rental property investment, REIT
                investments, 1031 Exchange
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="col-12 col-sm-6 col-lg-4 col-md-6 d-flex mb-35"
        data-aos="fade-up"
        data-aos-duration="1200"
      >
        <div className="p-6 border-2	border-gray-300 rounded-2xl">
          <div className="text-center">
            <div className="promo_rectangle_5 w-16 rounded-xl p-2 mx-auto">
              <img
                src="images/assets/icon_plenish_2.png"
                alt="icon"
                className="h-12 w-12 mx-auto"
              />
            </div>
            <div className="text-center">
              <div className="text-base text-black font-semibold py-3">
                Life Events
              </div>
              <div className="text-sm">
                Marriage, Divorce financial advice, expecting a child, college
                saving
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="col-12 col-sm-6 col-lg-4 col-md-6 d-flex mb-35"
        data-aos="fade-up"
        data-aos-duration="1200"
      >
        <div className="p-6 border-2	border-gray-300 rounded-2xl">
          <div className="text-center">
            <div className="promo_rectangle_5 w-16 rounded-xl p-2 mx-auto">
              <img
                src="images/assets/icon_plenish_14.png"
                alt="icon"
                className="h-12 w-12 mx-auto"
              />
            </div>
            <div className="text-center">
              <div className="text-base text-black font-semibold py-3">
                Inheritance & Wealth Transfer
              </div>
              <div className="text-sm">
                Trust set up, property inheritance, tax efficient wealth
                transfer, estate planning
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="col-12 col-sm-6 col-lg-4 col-md-6 d-flex mb-35"
        data-aos="fade-up"
        data-aos-duration="1200"
      >
        <div className="p-6 border-2	border-gray-300 rounded-2xl">
          <div className="text-center">
            <div className="promo_rectangle_5 w-16 rounded-xl p-2 mx-auto">
              <img
                src="images/assets/icon_plenish_6.png"
                alt="icon"
                className="h-12 w-12 mx-auto"
              />
            </div>
            <div className="text-center">
              <div className="text-base text-black font-semibold py-3">
                Retirement Planning
              </div>
              <div className="text-sm">
                Retirement saving strategies, 401(k), social security, pensions
                and IRAs
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="col-12 col-sm-6 col-lg-4 col-md-6 d-flex mb-35"
        data-aos="fade-up"
        data-aos-duration="1200"
      >
        <div className="p-6 border-2	border-gray-300 rounded-2xl">
          <div className="text-center">
            <div className="promo_rectangle_5 w-16 rounded-xl p-2 mx-auto">
              <img
                src="images/assets/icon_plenish_7.png"
                alt="icon"
                className="h-12 w-12 mx-auto"
              />
            </div>
            <div className="text-center">
              <div className="text-base text-black font-semibold py-3">
                Insurance
              </div>
              <div className="text-sm">
                Life insurance, liability insurance, fixed annuity, variable
                annuity
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="col-12 col-sm-6 col-lg-4 col-md-6 d-flex mb-35"
        data-aos="fade-up"
        data-aos-duration="1200"
      >
        <div className="p-6 border-2	border-gray-300 rounded-2xl">
          <div className="text-center">
            <div className="promo_rectangle_5 w-16 rounded-xl p-2 mx-auto">
              <img
                src="images/assets/icon_plenish_8.png"
                alt="icon"
                className="h-12 w-12 mx-auto"
              />
            </div>
            <div className="text-center">
              <div className="text-base text-black font-semibold py-3">
                Debt Reduction
              </div>
              <div className="text-sm">
                Student debt management, debt consolidation, refinancing,
                payment scheduling
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="col-12 col-sm-6 col-lg-4 col-md-6 d-flex mb-35"
        data-aos="fade-up"
        data-aos-duration="1200"
      >
        <div className="p-6 border-2	border-gray-300 rounded-2xl">
          <div className="text-center">
            <div className="promo_rectangle_5 w-16 rounded-xl p-2 mx-auto">
              <img
                src="images/assets/icon_plenish_1.png"
                alt="icon"
                className="h-12 w-12 mx-auto"
              />
            </div>
            <div className="text-center">
              <div className="text-base text-black font-semibold py-3">
                Investing
              </div>
              <div className="text-sm">
                Asset allocation, portfolio construction, investment &
                divestment, tax optimization
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="col-12 col-sm-6 col-lg-4 col-md-6 d-flex mb-35"
        data-aos="fade-up"
        data-aos-duration="1200"
      >
        <div className="p-6 border-2	border-gray-300 rounded-2xl">
          <div className="text-center">
            <div className="promo_rectangle_5 w-16 rounded-xl p-2 mx-auto">
              <img
                src="images/assets/icon_plenish_11.png"
                alt="icon"
                className="h-12 w-12 mx-auto"
              />
            </div>
            <div className="text-center">
              <div className="text-base text-black font-semibold py-3">
                Employee Stock Plans
              </div>
              <div className="text-sm">
                RSUs, stock options, tax optimization, diversification
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="col-12 col-sm-6 col-lg-4 col-md-6 d-flex mb-35"
        data-aos="fade-up"
        data-aos-duration="1200"
      >
        <div className="p-6 border-2	border-gray-300 rounded-2xl">
          <div className="text-center">
            <div className="promo_rectangle_5 w-16 rounded-xl p-2 mx-auto">
              <img
                src="images/assets/icon_plenish_10.png"
                alt="icon"
                className="h-12 w-12 mx-auto"
              />
            </div>
            <div className="text-center">
              <div className="text-base text-black font-semibold py-3">
                ESG Investment
              </div>
              <div className="text-sm">
                Environment & socially responsible investing, renewable energy
                investment
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="col-12 col-sm-6 col-lg-4 col-md-6 d-flex mb-35"
        data-aos="fade-up"
        data-aos-duration="1200"
      >
        <div className="p-6 border-2	border-gray-300 rounded-2xl">
          <div className="text-center">
            <div className="promo_rectangle_5 w-16 rounded-xl p-2 mx-auto">
              <img
                src="images/assets/icon_plenish_12.png"
                alt="icon"
                className="h-12 w-12 mx-auto"
              />
            </div>
            <div className="text-center">
              <div className="text-base text-black font-semibold py-3">
                Alternative Investments
              </div>
              <div className="text-sm">
                Art investing, syndicated real estate, venture capital & angel
                investing
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="col-12 col-sm-6 col-lg-4 col-md-6 d-flex mb-35"
        data-aos="fade-up"
        data-aos-duration="1200"
      >
        <div className="p-6 border-2	border-gray-300 rounded-2xl">
          <div className="text-center">
            <div className="promo_rectangle_5 w-16 rounded-xl p-2 mx-auto">
              <img
                src="images/assets/icon_plenish_13.png"
                alt="icon"
                className="h-12 w-12 mx-auto"
              />
            </div>
            <div className="text-center">
              <div className="text-base text-black font-semibold py-3">
                All Other Questions
              </div>
              <div className="text-sm">
                Setting up a business, tax strategies, or any other personal
                finance questions
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FancyFeatureTewentyFourPlenish;
