import React from "react";

const FancyVideoPlenish = () => {
  return (
    <>
      {/* <div className="col-lg-6 ml-auto"> */}
      <div className="content -mt-10 md:mt-0">
        <div className="text-3xl font-semibold text-white">
          Expert advice{" "}
          <span className="text-yellow-400">
            can double the value of your investments
          </span>{" "}
          by the time you retire
        </div>
        <div className="video-icon tran3s mt-8 sm:mt-0 d-flex align-items-center cursor-default">
          <div className="text-left">
            <span className="d-block ps-text -mt-8 md:mt-2">
              Source: Vanguard study, 2019
            </span>
            <strong className="d-block">
              $100,000 invested while having professional financial advice can
              create up to $800,000 more wealth within 25 years
            </strong>
            <span className="d-block notstrong">
              Assuming an 8.8% return based on a 60/40 portfolio without an
              advisor and 3% of advisor alpha
            </span>
          </div>
          {/* </div> */}
        </div>
      </div>
    </>
  );
};

export default FancyVideoPlenish;
