import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

const FaqContent = [
  {
    title: "Can I ask a question for free?",
    desc: `You can ask up to 3 questions per month for free on Plenish's platform which will be answered by a professional financial advisor.`,
    expand: "a",
  },
  {
    title: "What types of questions can I ask?",
    desc: `Please refer to "Examples of what you can ask about" in our Home page`,
    expand: "b",
  },
  {
    title: "How long does it take to receive an answer after posting it?",
    desc: `Our advisors will spend time to review your questions and provide you with best answers within 24 hours after you post your question.`,
    expand: "c",
  },
  {
    title: "Will my questions get public?",
    desc: `No. Only our dedicated financial advisor will review and answer.`,
    expand: "d",
  },
  {
    title: "Who will answer my question?",
    desc: `A licensed and professional financial advisor will answer your question.`,
    expand: "e",
  },
  {
    title: "How do I receive an answer?",
    desc: `We will email you the answer on the email address that you provide at the time of submission.`,
    expand: "f",
  },
  {
    title: "What happens after I submit my question?",
    desc: `A financial advisor will review your question and provide an answer which you will receive in an email.`,
    expand: "g",
  },
  {
    title: "How do I post a question?",
    desc: `You can send us your question on the "Get Started" page.`,
    expand: "h",
  },
];

const Faq = () => {
  return (
    <div className="accordion-style-two sm:px-5 bg-gray-50 ">
      <div className="faq-wrraper">
        <Accordion allowZeroExpanded>
          {FaqContent.map((item, i) => (
            <AccordionItem className="card" key={i} uuid={item.expand}>
              <AccordionItemHeading className="card-header">
                <AccordionItemButton>
                  <h5 className="mb-0">
                    <button className="btn btn-link">{item.title}</button>{" "}
                  </h5>
                </AccordionItemButton>
              </AccordionItemHeading>
              {/* Accordion Heading */}
              <AccordionItemPanel className="card-body fadeInUp">
                <p>{item.desc}</p>
              </AccordionItemPanel>
              {/* Accordion Body Content */}
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </div>
  );
};

export default Faq;
