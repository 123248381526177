import React from "react";
import { Helmet } from "react-helmet";
import HeaderLandingAppointmentPlenish from "../../../components/header/landing/HeaderLandingAppointmentPlenish";
import FooterEightPlenish from "../../../components/footer/FooterEightPlenish";
import CopyRightFourPlenish from "../../../components/footer/CopyRightFourPlenish";

const PlenishPromo = () => {
  function checkform() {
    var f = document.forms["promo-form"].elements;
    var cansubmit = true;

    for (var i = 0; i < f.length; i++) {
      if ((f[i].id !== "promo-submit-button") & (f[i].value.length === 0)) {
        cansubmit = false;
      }
    }

    if (cansubmit) {
      document.getElementById("promo-submit-button").disabled = false;
      document.getElementById("promo-submit-button").className =
        "mt-3 inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500  max-w-lg w-full";
    } else {
      document.getElementById("promo-submit-button").disabled = "disabled";
      document.getElementById("promo-submit-button").className =
        "mt-3 inline-flex justify-center py-2 px-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-gray-400 bg-gray-200 block max-w-lg w-full";
    }
  }

  return (
    <div>
      <div className="main-page-wrapper p0 font-gordita">
        <Helmet>
          <title>Plenish | Promo</title>
        </Helmet>
        {/* End Page SEO Content */}

        <HeaderLandingAppointmentPlenish />

        {/* End Header */}

        <main className="hero-banner-thirteen lg-container">
          <div className="bg-gray-50 font-gordita p-1 -mt-10">
            <div className="relative max-w-5xl mx-auto">
              <div className="sm:hidden mx-3 -mt-5">
                <div className="relative lg:grid lg:grid-cols-1 lg:gap-8 lg:items-center">
                  <div className="relative mx-0 lg:mt-12" aria-hidden="true">
                    <div className="object-coverflex inline-block align-text-bottom">
                      <img
                        className="inset-0 w-full mx-auto rounded-r-md"
                        src="/images/assets/promo_2_mobile.jpg"
                        alt=""
                      />
                      <div className="-mt-7 flex">
                        <div className="bg-gray-50 bg-opacity-75 bg mx-auto p-1 rounded-md">
                          <div className="text-xs text-green-700 font-semibold">
                            Expertise can make a difference in your finances
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/*                 
                <img
                  className="rounded-md"
                  src="/images/assets/promo_2_mobile.jpg"
                  alt=""
                /> */}
              </div>
              <div className="max-w-lg mx-auto grid gap-6 lg:grid-cols-2 lg:max-w-none mt-8 sm:shadow-md sm:rounded-2xl bg-white">
                <div className="flex flex-col rounded-lg px-4 sm:pt-4">
                  <form
                    method="POST"
                    action="https://script.google.com/macros/s/AKfycbxlgcXfoxFHlZxghAVrqNCBRaW_f7ViXQABoNUlRVqJrUQ8UiOA01qaycCzJW66j14K/exec"
                    id="promo-form"
                  >
                    <div className="text-2xl font-medium text-gray-900">
                      Your most pressing financial questions answered by an
                      expert: <span className="text-green-700">5</span> free
                      questions, <span className="text-green-700">100%</span>{" "}
                      confidential,{" "}
                      <span className="text-green-700">zero </span>
                      marketing
                    </div>
                    <div className="mt-4 text-lg font-medium text-gray-500">
                      Questions answered same day by a licensed professional
                      financial advisor in writing
                    </div>
                    <div className="space-y-6 sm:space-y-5">
                      <div className="sm:grid sm:grid-cols-1 sm:gap-1 sm:items-start pt-4">
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                          Enter your question(s)
                        </label>
                        <div className="mt-1 sm:mt-0">
                          <textarea
                            required
                            onKeyUp={checkform}
                            id="question"
                            name="Question"
                            rows="5"
                            className="block max-w-lg w-full shadow-sm border-2 border-gray-900 py-2 px-2 focus:ring-green-900 focus:border-gray-900 sm:text-sm rounded-md"
                            placeholder="Example: Which one is a more suitable index for my portfolio? S&P 500 or Russell 2000?"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div className="space-y-6 sm:space-y-5">
                      <div className="sm:grid sm:grid-cols-1 sm:gap-1 sm:items-start pt-4">
                        <div>
                          <label
                            htmlFor="email"
                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                          >
                            Your email address (Only used to send you your
                            answer)
                          </label>
                        </div>
                        <div className="mt-1 sm:mt-0">
                          <input
                            required
                            onKeyUp={checkform}
                            id="email"
                            name="Email"
                            type="email"
                            autoComplete="email"
                            className="block max-w-lg w-full shadow-sm border-2 border-gray-900 py-2 px-2 focus:ring-green-900 focus:border-gray-900 sm:text-sm rounded-md"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="hidden">
                      <input id="version" name="Version" defaultValue="v5" />
                      <input id="version" name="Type" defaultValue="Promo" />
                    </div>
                    <div className="pt-5">
                      <div className="flex justify-end">
                        <button
                          type="submit"
                          id="promo-submit-button"
                          className="mt-3 inline-flex justify-center py-2 px-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-gray-400 bg-gray-200 block max-w-lg w-full"
                        >
                          <div id="spinner" className="px-3 hidden">
                            <svg
                              role="status"
                              className="w-5 h-5 text-neutral-50 animate-spin dark:text-gray-900 fill-green-500"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http:/www.w3.org/2000/svg"
                            >
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                              />
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                              />
                            </svg>
                          </div>
                          <div id="submit" className="py-1">
                            Submit
                          </div>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>

                <div className="hidden md:block mb-128 sm:mb-0">
                  <img
                    className="inset-0 w-full mx-auto rounded-r-md"
                    src="/images/assets/promo_2_desktop.jpg"
                    alt=""
                  />
                </div>
              </div>

              <p className="mt-20 py-2 text-2xl sm:px-0 text-black font-medium ml-4 sm:mx-0">
                What we offer
              </p>
              <div className="px-3 sm:px-0">
                <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-4 md:gap-y-4">
                  <div className="relative rounded-2xl shadow-md p-3">
                    <div className="">
                      <div className="flex items-center">
                        <div className="flex-shrink-0 promo_rectangle_4 rounded-2xl p-3">
                          <object
                            data="/images/assets/lightning.svg"
                            className="h-8 w-8"
                          >
                            {" "}
                          </object>
                        </div>
                        <div className="ml-4">
                          <p className="text-lg leading-6 font-medium text-gray-900">
                            Actionable advice
                          </p>
                          <dd className="mt-2 text-base text-gray-500">
                            You will get simplified, relevant guidance to invest
                            and plan.
                          </dd>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="relative rounded-2xl shadow-md p-3">
                    <div className="">
                      <div className="flex items-center">
                        <div className="flex-shrink-0 promo_rectangle_4 rounded-2xl p-3">
                          <object
                            data="/images/assets/lock_closed.svg"
                            className="h-8 w-8"
                          >
                            {" "}
                          </object>
                        </div>
                        <div className="ml-4">
                          <p className="text-lg leading-6 font-medium text-gray-900">
                            Safe and confidential
                          </p>
                          <dd className="mt-2 text-base text-gray-500">
                            Your information will never be shared or sold to
                            anyone.
                          </dd>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="relative rounded-2xl shadow-md p-3">
                    <div className="">
                      <div className="flex items-center">
                        <div className="flex-shrink-0  promo_rectangle_4 rounded-2xl p-3">
                          <object
                            data="/images/assets/user_group.svg"
                            className="h-8 w-8"
                          >
                            {" "}
                          </object>
                        </div>
                        <div className="ml-4">
                          <p className="text-lg leading-6 font-medium text-gray-900">
                            For investors at all levels
                          </p>
                          <dd className="mt-2 text-base text-gray-500">
                            From newbies to veterans, we are your stepping stone
                            to get you to the next level.
                          </dd>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="relative rounded-2xl shadow-md p-3">
                    <div className="">
                      <div className="flex items-center">
                        <div className="flex-shrink-0  promo_rectangle_4 rounded-2xl p-3">
                          <object
                            data="/images/assets/heart.svg"
                            className="h-8 w-8"
                          >
                            {" "}
                          </object>
                        </div>
                        <div className="ml-4">
                          <p className="text-lg leading-6 font-medium text-gray-900">
                            Free for everyone
                          </p>
                          <dd className="mt-2 text-base text-gray-500">
                            Get your first 5 answers for free. No subscriptions,
                            obligations, or hidden fees.
                          </dd>
                        </div>
                      </div>
                    </div>
                  </div>
                </dl>
              </div>

              <p className="mt-20 py-2 text-2xl sm:px-0 text-black font-medium ml-4 sm:mx-0">
                How It Works
              </p>

              <div className="flex flex-col px-3 sm:p-auto">
                <div className="flex pb-8 sm:ml-0">
                  <div className="flex flex-nowrap grid grid-cols-12 gap-5">
                    {/* <!-- Card 1 --> */}
                    <div className="flex flex-col rounded-2xl overflow-hidden shadow-md bg-white p-4 flex-shrink-0 col-span-12 sm:col-span-4">
                      <div className="text-3xl font-semibold text-gray-900 pt-2 pb-2">
                        01
                      </div>
                      <div className="rounded-2xl promo_rectangle_1 py-3">
                        <img
                          className="object-cover mx-auto"
                          src="/images/assets/chat.svg"
                          alt=""
                        />
                      </div>
                      <div className="flex-1 bg-white p-2 flex flex-col justify-between">
                        <div className="flex-1">
                          <div className="block mt-2">
                            <p className="mt-3 text-base text-gray-500 font-bold">
                              Ask your question here
                            </p>
                            <p className="mt-2 text-base text-gray-500">
                              Ask us any financial questions you have from
                              topics like retirement, mortgages, 401(k)s and
                              more.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <!-- Card 2 --> */}
                    <div className="flex flex-col rounded-2xl overflow-hidden shadow-md bg-white p-4 flex-shrink-0 col-span-12 sm:col-span-4">
                      <div className="text-3xl font-semibold text-gray-900 pt-2 pb-2">
                        02
                      </div>
                      <div className="rounded-2xl promo_rectangle_2 py-3">
                        <img
                          className="object-cover mx-auto"
                          src="/images/assets/shield_check.svg"
                          alt=""
                        />
                      </div>

                      <div className="flex-1 bg-white p-2 flex flex-col justify-between">
                        <div className="flex-1">
                          <div className="block mt-2">
                            <p className="mt-3 text-base text-gray-500 font-bold">
                              Our experts will review
                            </p>
                            <p className="mt-2 text-base text-gray-500">
                              A registered professional and fiduciary advisor
                              will review your question and send back an answer.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <!-- Card 3 --> */}
                    <div className="flex flex-col rounded-2xl overflow-hidden shadow-md bg-white p-4 flex-shrink-0 col-span-12 sm:col-span-4">
                      <div className="text-3xl font-semibold text-gray-900 pt-2 pb-2">
                        03
                      </div>
                      <div className="rounded-2xl promo_rectangle_3 py-3">
                        <img
                          className="object-cover mx-auto"
                          src="/images/assets/light_bulb.svg"
                          alt=""
                        />
                      </div>
                      <div className="flex-1 bg-white p-2 flex flex-col justify-between">
                        <div className="flex-1">
                          <div className="block mt-2">
                            <p className="mt-3 text-base text-gray-500 font-bold">
                              Get your answer
                            </p>
                            <p className="mt-2 text-base text-gray-500">
                              Your answer will be sent to the email you
                              provided. We will also share a link in case you’d
                              like to ask the advisor any other questions.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="hidden md:block">
              <div className="sm:grid place-content-center sm:grid-cols-12 sm:px-0">
                <div className="col-span-12 md:inline-flex sm:col-span-6 text-md sm:text-lg justify-end text-center promo_rectangle_1 text-white font-bold py-16 px-3 sm:py-32 sm:pr-32 ">
                  <div className="promo_width text-xl">
                    Did you know 89% of wealthy families in the US have a
                    professional advisor?
                  </div>
                </div>
                <div className="col-span-12 md:inline-flex sm:col-span-6 text-md sm:text-lg justify-start text-center  promo_rectangle_5 text-white font-bold py-16 px-3 sm:py-32 sm:pl-32">
                  <div className="promo_width text-xl">
                    At Plenish, we demistify the world of personal investing by
                    bringing similar services that are catered to you.
                  </div>
                </div>
              </div>
            </div>

            <div className="sm:hidden">
              <div className="sm:grid place-content-center sm:grid-cols-12 sm:px-0">
                <div className="col-span-12 md:inline-flex sm:col-span-6 text-md sm:text-lg justify-end text-center promo_rectangle_1 text-white font-bold py-16 px-3 sm:py-32 sm:pr-32 ">
                  <div className="text-xl">
                    Did you know 89% of wealthy families in the US have a
                    professional advisor?
                  </div>
                </div>
                <div className="col-span-12 md:inline-flex sm:col-span-6 text-md sm:text-lg justify-start text-center  promo_rectangle_5 text-white font-bold py-16 px-3 sm:py-32 sm:pl-32">
                  <div className="text-xl">
                    At Plenish, we demistify the world of personal investing by
                    bringing similar services that are catered to you.
                  </div>
                </div>
              </div>
            </div>

            <div className="relative max-w-5xl mx-auto mt-30">
              <div className="sm:grid place-content-center sm:grid-cols-12 px-2 sm:px-0">
                <div className="justify-center items-center flex col-span-8 pr-2 text-md sm:text-lg px-3 sm:px-0">
                  <div>
                    {/* <p className="text-2xl text-black font-medium md:-mt-36">
                      Our mission
                    </p> */}
                    <p className="mt-20">
                      We believe everyone should have financial wellness. That
                      is why we start with you as our primary focus. We created
                      Plenish to help everyone gain insights and advice from our
                      national network of handpicked advisors.
                    </p>
                    <p className="mt-20">
                      Our platform makes it easy for people at all level of
                      experience to create a financial strategy and achieve
                      their goals.
                    </p>
                  </div>
                </div>
                <div className="text-center md:inline-flex rounded-sm w-full justify-end sm:col-span-4">
                  <img
                    className="h-96 rounded-2xl mt-30 sm:mt-0 mx-auto sm:mx-0"
                    src="/images/assets/financial_advisor_2.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </main>

        <footer className="theme-footer-eight mt-100">
          <div className="top-footer">
            <div className="container">
              <FooterEightPlenish />
            </div>
            {/* /.container */}
          </div>
          {/* /.top-footer */}

          <div className="container">
            <div className="bottom-footer-content">
              <CopyRightFourPlenish />
            </div>
            {/*  /.bottom-footer */}
          </div>
        </footer>
        {/* /.theme-footer-one */}
      </div>
    </div>
  );
};

export default PlenishPromo;
