import React from "react";
import { Helmet } from "react-helmet";
import HeaderLandingAppointmentPlenish from "../../../components/header/landing/HeaderLandingAppointmentPlenish";
import FooterEightPlenish from "../../../components/footer/FooterEightPlenish";
import CopyRightFourPlenish from "../../../components/footer/CopyRightFourPlenish";

const BlogDetailsChecklistOfWhatToConsiderIfYouAreBuyingYourFirstHome = () => {
  return (
    <div>
      {/* <div className="banner-promo">
        Get your questions answered,{" "}
        <Link to="/promo" className="">
          try for free
        </Link>
      </div> */}
      <div className="main-page-wrapper p0 font-gordita">
        <Helmet>
          <title>
            Plenish | Blog | Checklist Of What To Consider If You Are Buying
            Your First Home
          </title>
        </Helmet>
        {/* End Page SEO Content */}

        <HeaderLandingAppointmentPlenish />
        {/* End Header */}

        {/* =============================================
            Fancy Hero Two
        ==============================================  */}
        <div className="fancy-hero-five bg-gray-50">
          <div className="bg-wrapper ">
            <div className="container">
              <div className="col-lg-10 m-auto text-center">
                <h1 className="heading">
                  Checklist Of What To Consider If You Are Buying Your First
                  Home
                </h1>
              </div>
            </div>
          </div>
          {/* /.bg-wrapper */}
        </div>
        {/* /.fancy-hero-two */}

        {/* =====================================================
            Feature Blog One
        ===================================================== */}
        <main className="bg-gray-50">
          <div className="relative flex justify-between items-center max-w-4xl mx-auto">
            <div className="relative px-4 sm:px-6 lg:px-8">
              <div className="text-lg mx-auto">
                {/* <h1 className="my-2 block text-3xl text-center leading-8 font-bold tracking-tight text-gray-900 sm:text-4xl">
            Checklist Of What To Consider If You Are Buying Your First Home
          </h1> */}
                <p className="mt-4 text-xl text-gray-500 leading-8">
                  Buying your first home can be overwhelming. You need to
                  consider so many questions and details: How much can you
                  afford? Save for a down payment? Improve your credit rating?
                  Find a good real estate agent and attend open houses. Where
                  would you like to live? Be prepared to make an offer and
                  negotiate. Hire a real estate attorney. These are just some of
                  the factors you need to consider when buying your first home,
                  not to mention the different types of loans, determining your
                  budget, PMI insurance, etc.
                </p>
                <div className="relative py-4">
                  <div className="absolute inset-x-0 bottom-0 h-1/2"></div>
                  <div className="max-w-7xl mx-auto">
                    <div className="relative shadow-xl sm:rounded-2xl sm:overflow-hidden">
                      <div className="absolute inset-0">
                        <img
                          className="h-full w-full object-cover"
                          src="images/blog/blog_3.jpg"
                          alt=""
                        />
                      </div>
                      <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-48 lg:px-8"></div>
                    </div>
                  </div>
                </div>
                <h1 className="mt-4 block text-2xl text-left leading-8 font-bold tracking-tight text-gray-900 sm:text-3xl">
                  How Much Can You Afford
                </h1>
                <p className="mt-4 text-xl text-gray-500 leading-8">
                  Before looking at homes, it's essential to know what kind of
                  mortgage you can afford. Your bank or lender will require
                  documentation about your income and expenses before approving
                  a mortgage loan. The lender will also want to prove that you
                  have enough money in the reserve to cover closing costs, which
                  can vary widely depending on where you live.
                </p>
                <p className="mt-4 text-xl text-gray-500 leading-8">
                  You don't have to buy a house that's precisely within your
                  budget. If you find something that's more than what you can
                  afford but close enough that it won't significantly raise your
                  monthly payments, then go for it! Buying a home is one of the
                  most significant investments of your life so you need to put a
                  lot of thought into it.
                </p>
                <h1
                  className="mt-4 block text-2xl text-left leading-8 font-bold tracking-tight
                            text-gray-900 sm:text-3xl"
                >
                  Save For a Down Payment
                </h1>
                <p className="mt-4 text-xl text-gray-500 leading-8">
                  If you're planning to finance your home, you'll need to
                  develop funds for a down payment of at least 10 percent of the
                  purchase price. It's best to put 20 percent down, but this may
                  not be possible for everyone. Some schemes promise you can buy
                  homes without any money down – these may apply to special
                  groups so do your research before falling for them. don't fall
                  for them.
                </p>
                <h1
                  className="mt-4 block text-2xl text-left leading-8 font-bold tracking-tight
                            text-gray-900 sm:text-3xl"
                >
                  Check And Improve Your Credit Rating
                </h1>
                <p className="mt-4 text-xl text-gray-500 leading-8">
                  Before applying for a mortgage loan, check your credit rating
                  and improve it if needed. The higher your score, the lower the
                  interest rate you'll pay on your mortgage loan. If you need
                  assistance improving your credit score, various companies
                  offer services like TransUnion and Experian, whose websites
                  provide information about their products and services.
                </p>
                <p className="mt-4 text-xl text-gray-500 leading-8">
                  If your credit score falls below 620, you may need to take
                  steps to improve it before applying for a mortgage loan. You
                  can start by paying off any outstanding debts dragging down
                  your credit rating and paying off old debt can raise your
                  score in as little as 30 days — so taking care of these small
                  debts now will help you get approved for a mortgage loan
                  later.
                </p>
                <h1
                  className="mt-4 block text-2xl text-left leading-8 font-bold tracking-tight
                            text-gray-900 sm:text-3xl"
                >
                  Get Pre-Approval for A Home Loan
                </h1>
                <p className="mt-4 text-xl text-gray-500 leading-8">
                  Get pre-approved for a home loan. This will ensure that you
                  can afford the home you want to buy. It also helps show
                  sellers that you are serious about buying their house. Find
                  out about any fees associated with the purchase. Homes usually
                  have fees, such as closing costs or escrow fees. Before making
                  an offer, ask the seller about any fees to know your final
                  price once everything is added together.
                </p>
                <p className="mt-4 text-xl text-gray-500 leading-8">
                  Make sure there are no liens on the property. If there are
                  liens on a property, they must be paid off before it can be
                  sold again. This could affect your mortgage lender's
                  willingness to approve your request for financing and could
                  increase your monthly payments if they extend beyond the
                  length of your mortgage loan (usually 30 years).
                </p>
                <h1
                  className="mt-4 block text-2xl text-left leading-8 font-bold tracking-tight
                            text-gray-900 sm:text-3xl"
                >
                  Find A Good Real Estate Agent and Attend Open Houses
                </h1>
                <p className="mt-4 text-xl text-gray-500 leading-8">
                  You will want to find an excellent real estate agent. This
                  person will help guide you through finding and buying your
                  home, so make sure they fit your needs and personality.
                </p>
                <p className="mt-4 text-xl text-gray-500 leading-8">
                  Once you have found a real estate agent that meets your
                  preferences, you can start looking at homes for sale in your
                  area. This step can be overwhelming because there are so many
                  choices, but attending open houses is a great way to get an
                  idea of what's available.
                </p>
                <p className="mt-4 text-xl text-gray-500 leading-8">
                  Open houses are a great way to learn about different
                  communities and compare prices and amenities from neighborhood
                  to neighborhood. Get a feel for what's available on the market
                  and talk with real estate agents about what they like about
                  each property that may appeal to you and what concerns them
                  about each property that may deter you from making an offer.
                </p>
                <h1
                  className="mt-4 block text-2xl text-left leading-8 font-bold tracking-tight
                            text-gray-900 sm:text-3xl"
                >
                  Where You Would Like to Live
                </h1>
                <p className="mt-4 text-xl text-gray-500 leading-8">
                  Location is everything in real estate — but it doesn't
                  necessarily mean distance from work or schools. It also
                  includes access to public transportation and nearby amenities
                  such as grocery stores and restaurants. It's essential that
                  where you live fits your lifestyle so that you don't have to
                  spend hours each day commuting or running errands.
                </p>
                <h1
                  className="mt-4 block text-2xl text-left leading-8 font-bold tracking-tight
                            text-gray-900 sm:text-3xl"
                >
                  Know What You Are Looking for In A Home
                </h1>
                <p className="mt-4 text-xl text-gray-500 leading-8">
                  Decide what type of house you want, whether an apartment or a
                  single-family home for example, if you are looking for
                  something affordable but still want to live close to your
                  workplace, an apartment near public transportation could work
                  well.
                </p>
                <h1
                  className="mt-4 block text-2xl text-left leading-8 font-bold tracking-tight
                            text-gray-900 sm:text-3xl"
                >
                  Be Prepared to Make an Offer and Negotiate
                </h1>
                <p className="mt-4 text-xl text-gray-500 leading-8">
                  Even if you fall in love with a house and make an offer, it
                  doesn't mean that you'll get it. You may have to negotiate
                  with the seller or their real estate agent to get them to
                  accept your offer. Create a game plan with your real estate
                  agent and know what you're willing to pay for the home before
                  you even begin looking at houses.
                </p>
                <h1
                  className="mt-4 block text-2xl text-left leading-8 font-bold tracking-tight
                            text-gray-900 sm:text-3xl"
                >
                  Schedule Home Inspection
                </h1>
                <p className="mt-4 text-xl text-gray-500 leading-8">
                  A home inspection will give you an idea of what repairs or
                  maintenance might be needed in the future and whether these
                  repairs are even worth it. A good inspector will also give you
                  peace of mind by letting you know if any significant issues
                  could cause problems later.
                </p>
                <h1
                  className="mt-4 block text-2xl text-left leading-8 font-bold tracking-tight
                            text-gray-900 sm:text-3xl"
                >
                  Hire A Real Estate Attorney
                </h1>
                <p className="mt-4 text-xl text-gray-500 leading-8">
                  A real estate attorney can help answer questions about the
                  process, such as how long it will take to close the house. The
                  attorney will also help review contracts, negotiate with
                  sellers, and perform other essential tasks for buying a home.
                </p>
                <h1
                  className="mt-4 block text-2xl text-left leading-8 font-bold tracking-tight
                            text-gray-900 sm:text-3xl"
                >
                  Close On Your New Home
                </h1>
                <p className="mt-4 text-xl text-gray-500 leading-8">
                  Before you close on your new home, it's time to take a deep
                  breath and make sure everything is in order. Here are the
                  last-minute things you need to do before signing on the dotted
                  line.
                </p>
                <p className="mt-4 text-xl text-gray-700 leading-8 font-bold">
                  Finalize Your Loan
                </p>
                <p className="mt-4 text-xl text-gray-500 leading-8">
                  Your lender will likely give you a checklist of documents
                  needed for the closing and ask you to gather them in advance.
                  But if not, now is the time to gather everything together:
                  bank statements, pay stubs, tax returns, mortgage information,
                  and anything else they might request.
                </p>
                <p className="mt-4 text-xl text-gray-500 leading-8">
                  You should also get two or three preapprovals from different
                  lenders to have options if something goes wrong with one of
                  them. If you're buying a home with friends or family members
                  as partners, it's also wise to have each partner write a
                  personal guarantee for the loan so that if one person defaults
                  on payments (or worse), there's recourse against the others'
                  assets as well.
                </p>
                <p className="mt-4 text-xl text-gray-500 leading-8">
                  After all that work getting ready to buy a house and applying
                  for financing, the last thing you want is for some paperwork
                  snafu to delay closing day. Your attorney will be present at
                  closing and can make sure everything is in order.
                </p>
                <p className="mt-4 text-xl text-gray-500 leading-8">
                  It's a lot of fun traipsing through open houses, imagining how
                  your first home could be. But there's still a lot to learn
                  before making that first real-estate leap. Hopefully, this
                  checklist will help you figure out what you need to do to buy
                  your first home and then get you off on the right foot.
                </p>
              </div>
            </div>
          </div>
        </main>

        <footer className="theme-footer-eight mt-100">
          <div className="top-footer">
            <div className="container">
              <FooterEightPlenish />
            </div>
            {/* /.container */}
          </div>
          {/* /.top-footer */}

          <div className="container">
            <div className="bottom-footer-content">
              <CopyRightFourPlenish />
            </div>
            {/*  /.bottom-footer */}
          </div>
        </footer>
        {/* /.theme-footer-one */}
      </div>
    </div>
  );
};

export default BlogDetailsChecklistOfWhatToConsiderIfYouAreBuyingYourFirstHome;
