import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const GlobalExpansionThankYou = () => {
  return (
    <div className="main-page-wrapper p0 font-gordita">
      <Helmet>
        <title>Plenish | Thank You</title>
      </Helmet>
      <div className="bg-neutral-50 px-8 sm:px-0">
        <div className="flex h-screen">
          <div className="m-auto">
            <div className="flex items-center justify-center px-8 sm:px-0">
              <img className="mx-0" src="images/logo/logo.png" alt="" />
            </div>
            <div className="flex items-center justify-center mx-auto">
              <div className="mt-6 text-lg sm:text-2xl font-semibold text-green-800 mx-auto">
                Thank you. Talk to you soon!
              </div>
            </div>
            <div className="flex items-center justify-center">
              <Link to="/globalexpansion">
                <button className="mt-16 w-full inline-flex items-center justify-center px-8 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:w-auto">
                  Back
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GlobalExpansionThankYou;
