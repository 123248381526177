import React from "react";
import { Helmet } from "react-helmet";
import HeaderLandingAppointmentPlenish from "../../../components/header/landing/HeaderLandingAppointmentPlenish";
import FooterEightPlenish from "../../../components/footer/FooterEightPlenish";
import CopyRightFourPlenish from "../../../components/footer/CopyRightFourPlenish";

const PrivacyPolicy = () => {
  return (
    <div>
      {/* <div className="banner-promo">
        Get your questions answered,{" "}
        <Link to="/promo" className="">
          try for free
        </Link>
      </div> */}
      <div className="main-page-wrapper p0 font-gordita">
        <Helmet>
          <title>Plenish | Privacy Policy</title>
        </Helmet>
        {/* End Page SEO Content */}

        <HeaderLandingAppointmentPlenish />
        {/* End Header */}

        {/* =============================================
            Fancy Hero Two
        ==============================================  */}
        <div className="fancy-hero-five bg-gray-50">
          <div className="bg-wrapper ">
            <div className="container">
              <div className="col-lg-10 m-auto text-center">
                <h1 className="heading">Privacy Policy</h1>
              </div>
            </div>
          </div>
          {/* /.bg-wrapper */}
        </div>
        {/* /.fancy-hero-two */}

        {/* =====================================================
            Feature Blog One
        ===================================================== */}
        <main>
          <div className="bg-gray-50">
            <div className="relative justify-between items-center max-w-7xl mx-auto">
              <div className="relative px-4 sm:px-6 lg:px-8">
                <div className="text-lg mx-auto">
                  <p className="pt-4 text-xl text-gray-500 leading-8">
                    Last updated: May 12, 2022
                  </p>
                  <p className="mt-4 text-xl text-gray-500 leading-8">
                    This Privacy Policy describes Our policies and procedures on
                    the collection, use and disclosure of Your information when
                    You use the Service and tells You about Your privacy rights
                    and how the law protects You.
                  </p>
                  <p className="mt-4 text-xl text-gray-500 leading-8">
                    We use Your Personal data to provide and improve the
                    Service. By using the Service, You agree to the collection
                    and use of information in accordance with this Privacy
                    Policy.
                  </p>
                  <h1 className="mt-4 block text-2xl text-left leading-8 font-bold tracking-tight text-gray-900 sm:text-3xl">
                    Interpretation and Definitions
                  </h1>
                  <p className="mt-4 text-xl text-gray-500 leading-8 font-bold">
                    Interpretation
                  </p>
                  <p className="mt-4 text-xl text-gray-500 leading-8">
                    The words of which the initial letter is capitalized have
                    meanings defined under the following conditions. The
                    following definitions shall have the same meaning regardless
                    of whether they appear in singular or in plural.
                  </p>
                  <p className="mt-4 text-xl text-gray-500 leading-8 font-bold">
                    Definitions
                  </p>
                  <p className="mt-4 text-xl text-gray-500 leading-8">
                    For the purposes of this Privacy Policy:
                  </p>
                  <ul className="list-disc ml-8">
                    <li className="mt-4 text-xl text-gray-500 leading-8">
                      <span className="font-bold">Account</span> means a unique
                      account created for You to access our Service or parts of
                      our Service.
                    </li>
                    <li className="mt-4 text-xl text-gray-500 leading-8">
                      <span className="font-bold">Company</span> (referred to as
                      either &quot;the Company&quot;, &quot;We&quot;,
                      &quot;Us&quot; or &quot;Our&quot; in this Agreement)
                      refers to Plenish Finance, Inc, 157 13th Street, Brooklyn,
                      NY 11215.
                    </li>
                    <li className="mt-4 text-xl text-gray-500 leading-8">
                      <span className="font-bold">Cookies</span> are small files
                      that are placed on Your computer, mobile device or any
                      other device by a website, containing the details of Your
                      browsing history on that website among its many uses.
                    </li>
                    <li className="mt-4 text-xl text-gray-500 leading-8">
                      <span className="font-bold">Country</span> refers to: New
                      York, United States
                    </li>
                    <li className="mt-4 text-xl text-gray-500 leading-8">
                      <span className="font-bold">Device</span> means any device
                      that can access the Service such as a computer, a
                      cellphone or a digital tablet.
                    </li>
                    <li className="mt-4 text-xl text-gray-500 leading-8">
                      <span className="font-bold">Personal Data</span> is any
                      information that relates to an identified or identifiable
                      individual.
                    </li>
                    <li className="mt-4 text-xl text-gray-500 leading-8">
                      <span className="font-bold">Service</span> refers to the
                      Website.
                    </li>
                    <li className="mt-4 text-xl text-gray-500 leading-8">
                      <span className="font-bold">Service Provider</span> means
                      any natural or legal person who processes the data on
                      behalf of the Company. It refers to third-party companies
                      or individuals employed by the Company to facilitate the
                      Service, to provide the Service on behalf of the Company,
                      to perform services related to the Service or to assist
                      the Company in analyzing how the Service is used.
                    </li>
                    <li className="mt-4 text-xl text-gray-500 leading-8">
                      <span className="font-bold">Usage Data</span> refers to
                      data collected automatically, either generated by the use
                      of the Service or from the Service infrastructure itself
                      (for example, the duration of a page visit).
                    </li>
                    <li className="mt-4 text-xl text-gray-500 leading-8">
                      <span className="font-bold">Website</span> refers to
                      Plenish, accessible from{" "}
                      <a
                        href="https://www.plenish.finance/"
                        rel="external nofollow noopener noreferrer"
                        target="_blank"
                      >
                        https://www.plenish.finance/
                      </a>
                    </li>
                    <li className="mt-4 text-xl text-gray-500 leading-8">
                      <span className="font-bold">You</span> means the
                      individual accessing or using the Service, or the company,
                      or other legal entity on behalf of which such individual
                      is accessing or using the Service, as applicable.
                    </li>
                  </ul>
                  <h1 className="mt-4 block text-2xl text-left leading-8 font-bold tracking-tight text-gray-900 sm:text-3xl">
                    Collecting and Using Your Personal Data
                  </h1>
                  <h2 className="mt-4 block text-xl text-left leading-8 font-bold tracking-tight text-gray-900 sm:text-2xl">
                    Types of Data Collected
                  </h2>
                  <p className="mt-4 text-xl text-gray-500 leading-8 font-bold">
                    Personal Data
                  </p>
                  <p className="mt-4 text-xl text-gray-500 leading-8">
                    While using Our Service, We may ask You to provide Us with
                    certain personally identifiable information that can be used
                    to contact or identify You. Personally identifiable
                    information may include, but is not limited to:
                  </p>
                  <ul className="list-disc ml-8">
                    <li className="mt-4 text-xl text-gray-500 leading-8">
                      <p>Email address</p>
                    </li>
                    <li className="mt-4 text-xl text-gray-500 leading-8">
                      <p>First name and last name</p>
                    </li>
                    <li className="mt-4 text-xl text-gray-500 leading-8">
                      <p>Phone number</p>
                    </li>
                    <li className="mt-4 text-xl text-gray-500 leading-8">
                      <p>Address, State, Province, ZIP/Postal code, City</p>
                    </li>
                    <li className="mt-4 text-xl text-gray-500 leading-8">
                      <p>Usage Data</p>
                    </li>
                  </ul>
                  <p className="mt-4 text-xl text-gray-500 leading-8 font-bold">
                    Usage Data
                  </p>
                  <p className="mt-4 text-xl text-gray-500 leading-8">
                    Usage Data is collected automatically when using the
                    Service.
                  </p>
                  <p className="mt-4 text-xl text-gray-500 leading-8">
                    Usage Data may include information such as Your Device's
                    Internet Protocol address (e.g. IP address), browser type,
                    browser version, the pages of our Service that You visit,
                    the time and date of Your visit, the time spent on those
                    pages, unique device identifiers and other diagnostic data.
                  </p>
                  <p className="mt-4 text-xl text-gray-500 leading-8">
                    When You access the Service by or through a mobile device,
                    We may collect certain information automatically, including,
                    but not limited to, the type of mobile device You use, Your
                    mobile device unique ID, the IP address of Your mobile
                    device, Your mobile operating system, the type of mobile
                    Internet browser You use, unique device identifiers and
                    other diagnostic data.
                  </p>
                  <p className="mt-4 text-xl text-gray-500 leading-8">
                    We may also collect information that Your browser sends
                    whenever You visit our Service or when You access the
                    Service by or through a mobile device.
                  </p>
                  <p className="mt-4 text-xl text-gray-500 leading-8 font-bold">
                    Tracking Technologies and Cookies
                  </p>
                  <p className="mt-4 text-xl text-gray-500 leading-8">
                    We use Cookies and similar tracking technologies to track
                    the activity on Our Service and store certain information.
                    Tracking technologies used are beacons, tags, and scripts to
                    collect and track information and to improve and analyze Our
                    Service. The technologies We use may include:
                  </p>
                  <ul className="list-disc ml-8">
                    <li className="mt-4 text-xl text-gray-500 leading-8">
                      <span className="font-bold">
                        Cookies or Browser Cookies.
                      </span>{" "}
                      A cookie is a small file placed on Your Device. You can
                      instruct Your browser to refuse all Cookies or to indicate
                      when a Cookie is being sent. However, if You do not accept
                      Cookies, You may not be able to use some parts of our
                      Service. Unless you have adjusted Your browser setting so
                      that it will refuse Cookies, our Service may use Cookies.
                    </li>
                    <li className="mt-4 text-xl text-gray-500 leading-8">
                      <span className="font-bold">Flash Cookies.</span> Certain
                      features of our Service may use local stored objects (or
                      Flash Cookies) to collect and store information about Your
                      preferences or Your activity on our Service. Flash Cookies
                      are not managed by the same browser settings as those used
                      for Browser Cookies. For more information on how You can
                      delete Flash Cookies, please read &quot;Where can I change
                      the settings for disabling, or deleting local shared
                      objects?&quot; available at{" "}
                      <a
                        href="https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_"
                        rel="external nofollow noopener noreferrer"
                        target="_blank"
                      >
                        https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_
                      </a>
                    </li>
                    <li className="mt-4 text-xl text-gray-500 leading-8">
                      <span className="font-bold">Web Beacons.</span> Certain
                      sections of our Service and our emails may contain small
                      electronic files known as web beacons (also referred to as
                      clear gifs, pixel tags, and single-pixel gifs) that permit
                      the Company, for example, to count users who have visited
                      those pages or opened an email and for other related
                      website statistics (for example, recording the popularity
                      of a certain section and verifying system and server
                      integrity).
                    </li>
                  </ul>
                  <p className="mt-4 text-xl text-gray-500 leading-8">
                    Cookies can be &quot;Persistent&quot; or &quot;Session&quot;
                    Cookies. Persistent Cookies remain on Your personal computer
                    or mobile device when You go offline, while Session Cookies
                    are deleted as soon as You close Your web browser. Learn
                    more about cookies:{" "}
                    <a
                      href="https://www.freeprivacypolicy.com/blog/sample-privacy-policy-template/#Use_Of_Cookies_And_Tracking"
                      target="_blank"
                      rel="external nofollow noopener noreferrer"
                    >
                      Use of Cookies by Free Privacy Policy
                    </a>
                    .
                  </p>
                  <p className="mt-4 text-xl text-gray-500 leading-8">
                    We use both Session and Persistent Cookies for the purposes
                    set out below:
                  </p>
                  <ul className="list-disc ml-8">
                    <li className="mt-4 text-xl text-gray-500 leading-8">
                      <p className="font-bold">Necessary / Essential Cookies</p>
                      <p>
                        <span className="underline">Type:</span> Session Cookies
                      </p>
                      <p>
                        <span className="underline">Administered by:</span> Us
                      </p>
                      <p>
                        <span className="underline">Purpose:</span> These
                        Cookies are essential to provide You with services
                        available through the Website and to enable You to use
                        some of its features. They help to authenticate users
                        and prevent fraudulent use of user accounts. Without
                        these Cookies, the services that You have asked for
                        cannot be provided, and We only use these Cookies to
                        provide You with those services.
                      </p>
                    </li>
                    <li className="mt-4 text-xl text-gray-500 leading-8">
                      <p className="font-bold">
                        Cookies Policy / Notice Acceptance Cookies
                      </p>
                      <p>
                        <span className="underline">Type:</span> Persistent
                        Cookies
                      </p>
                      <p>
                        <span className="underline">Administered by:</span> Us
                      </p>
                      <p>
                        <span className="underline">Purpose:</span> These
                        Cookies identify if users have accepted the use of
                        cookies on the Website.
                      </p>
                    </li>
                    <li className="mt-4 text-xl text-gray-500 leading-8">
                      <p className="font-bold">Functionality Cookies</p>
                      <p>
                        <span className="underline">Type:</span> Persistent
                        Cookies
                      </p>
                      <p>
                        <span className="underline">Administered by:</span> Us
                      </p>
                      <p>
                        <span className="underline">Purpose:</span> These
                        Cookies allow us to remember choices You make when You
                        use the Website, such as remembering your login details
                        or language preference. The purpose of these Cookies is
                        to provide You with a more personal experience and to
                        avoid You having to re-enter your preferences every time
                        You use the Website.
                      </p>
                    </li>
                    <li className="mt-4 text-xl text-gray-500 leading-8">
                      <p className="font-bold">
                        Tracking and Performance Cookies
                      </p>
                      <p>
                        <span className="underline">Type:</span> Persistent
                        Cookies
                      </p>
                      <p>
                        <span className="underline">Administered by:</span>{" "}
                        Third-Parties
                      </p>
                      <p>
                        <span className="underline">Purpose:</span> These
                        Cookies are used to track information about traffic to
                        the Website and how users use the Website. The
                        information gathered via these Cookies may directly or
                        indirectly identify you as an individual visitor. This
                        is because the information collected is typically linked
                        to a pseudonymous identifier associated with the device
                        you use to access the Website. We may also use these
                        Cookies to test new pages, features or new functionality
                        of the Website to see how our users react to them.
                      </p>
                    </li>
                  </ul>
                  <p className="mt-4 text-xl text-gray-500 leading-8">
                    For more information about the cookies we use and your
                    choices regarding cookies, please visit our Cookies Policy
                    or the Cookies section of our Privacy Policy.
                  </p>
                  <h2 className="mt-4 block text-xl text-left leading-8 font-bold tracking-tight text-gray-900 sm:text-2xl">
                    Use of Your Personal Data
                  </h2>
                  <p className="mt-4 text-xl text-gray-500 leading-8">
                    The Company may use Personal Data for the following
                    purposes:
                  </p>
                  <ul className="list-disc ml-8">
                    <li className="mt-4 text-xl text-gray-500 leading-8">
                      <span className="font-bold">
                        To provide and maintain our Service
                      </span>
                      , including to monitor the usage of our Service.
                    </li>
                    <li className="mt-4 text-xl text-gray-500 leading-8">
                      <span className="font-bold">To manage Your Account:</span>{" "}
                      to manage Your registration as a user of the Service. The
                      Personal Data You provide can give You access to different
                      functionalities of the Service that are available to You
                      as a registered user.
                    </li>
                    <li className="mt-4 text-xl text-gray-500 leading-8">
                      <span className="font-bold">
                        For the performance of a contract:
                      </span>{" "}
                      the development, compliance and undertaking of the
                      purchase contract for the products, items or services You
                      have purchased or of any other contract with Us through
                      the Service.
                    </li>
                    <li className="mt-4 text-xl text-gray-500 leading-8">
                      <span className="font-bold">To contact You:</span> To
                      contact You by email, telephone calls, SMS, or other
                      equivalent forms of electronic communication, such as a
                      mobile application's push notifications regarding updates
                      or informative communications related to the
                      functionalities, products or contracted services,
                      including the security updates, when necessary or
                      reasonable for their implementation.
                    </li>
                    <li className="mt-4 text-xl text-gray-500 leading-8">
                      <span className="font-bold">To provide You</span> with
                      news, special offers and general information about other
                      goods, services and events which we offer that are similar
                      to those that you have already purchased or enquired about
                      unless You have opted not to receive such information.
                    </li>
                    <li className="mt-4 text-xl text-gray-500 leading-8">
                      <span className="font-bold">
                        To manage Your requests:
                      </span>{" "}
                      To attend and manage Your requests to Us.
                    </li>
                    <li className="mt-4 text-xl text-gray-500 leading-8">
                      <span className="font-bold">For business transfers:</span>{" "}
                      We may use Your information to evaluate or conduct a
                      merger, divestiture, restructuring, reorganization,
                      dissolution, or other sale or transfer of some or all of
                      Our assets, whether as a going concern or as part of
                      bankruptcy, liquidation, or similar proceeding, in which
                      Personal Data held by Us about our Service users is among
                      the assets transferred.
                    </li>
                    <li className="mt-4 text-xl text-gray-500 leading-8">
                      <span className="font-bold">For other purposes</span>: We
                      may use Your information for other purposes, such as data
                      analysis, identifying usage trends, determining the
                      effectiveness of our promotional campaigns and to evaluate
                      and improve our Service, products, services, marketing and
                      your experience.
                    </li>
                  </ul>
                  <p className="mt-4 text-xl text-gray-500 leading-8">
                    We may share Your personal information in the following
                    situations:
                  </p>
                  <ul className="list-disc ml-8">
                    <li className="mt-4 text-xl text-gray-500 leading-8">
                      <span className="font-bold">With Service Providers:</span>{" "}
                      We may share Your personal information with Service
                      Providers to monitor and analyze the use of our Service,
                      to contact You.
                    </li>
                    <li className="mt-4 text-xl text-gray-500 leading-8">
                      <span className="font-bold">For business transfers:</span>{" "}
                      We may share or transfer Your personal information in
                      connection with, or during negotiations of, any merger,
                      sale of Company assets, financing, or acquisition of all
                      or a portion of Our business to another company.
                    </li>
                    <li className="mt-4 text-xl text-gray-500 leading-8">
                      <span className="font-bold">With Affiliates:</span> We may
                      share Your information with Our affiliates, in which case
                      we will require those affiliates to honor this Privacy
                      Policy. Affiliates include Our parent company and any
                      other subsidiaries, joint venture partners or other
                      companies that We control or that are under common control
                      with Us.
                    </li>
                    <li className="mt-4 text-xl text-gray-500 leading-8">
                      <span className="font-bold">With business partners:</span>{" "}
                      We may share Your information with Our business partners
                      to offer You certain products, services or promotions.
                    </li>
                    <li className="mt-4 text-xl text-gray-500 leading-8">
                      <span className="font-bold">With other users:</span> when
                      You share personal information or otherwise interact in
                      the public areas with other users, such information may be
                      viewed by all users and may be publicly distributed
                      outside.
                    </li>
                    <li className="mt-4 text-xl text-gray-500 leading-8">
                      <span className="font-bold">With Your consent:</span> We
                      may disclose Your personal information for any other
                      purpose with Your consent.
                    </li>
                  </ul>
                  <h2 className="mt-4 block text-xl text-left leading-8 font-bold tracking-tight text-gray-900 sm:text-2xl">
                    Retention of Your Personal Data
                  </h2>
                  <p className="mt-4 text-xl text-gray-500 leading-8">
                    The Company will retain Your Personal Data only for as long
                    as is necessary for the purposes set out in this Privacy
                    Policy. We will retain and use Your Personal Data to the
                    extent necessary to comply with our legal obligations (for
                    example, if we are required to retain your data to comply
                    with applicable laws), resolve disputes, and enforce our
                    legal agreements and policies.
                  </p>
                  <p className="mt-4 text-xl text-gray-500 leading-8">
                    The Company will also retain Usage Data for internal
                    analysis purposes. Usage Data is generally retained for a
                    shorter period of time, except when this data is used to
                    strengthen the security or to improve the functionality of
                    Our Service, or We are legally obligated to retain this data
                    for longer time periods.
                  </p>
                  <h2 className="mt-4 block text-xl text-left leading-8 font-bold tracking-tight text-gray-900 sm:text-2xl">
                    Transfer of Your Personal Data
                  </h2>
                  <p className="mt-4 text-xl text-gray-500 leading-8">
                    Your information, including Personal Data, is processed at
                    the Company's operating offices and in any other places
                    where the parties involved in the processing are located. It
                    means that this information may be transferred to — and
                    maintained on — computers located outside of Your state,
                    province, country or other governmental jurisdiction where
                    the data protection laws may differ than those from Your
                    jurisdiction.
                  </p>
                  <p className="mt-4 text-xl text-gray-500 leading-8">
                    Your consent to this Privacy Policy followed by Your
                    submission of such information represents Your agreement to
                    that transfer.
                  </p>
                  <p className="mt-4 text-xl text-gray-500 leading-8">
                    The Company will take all steps reasonably necessary to
                    ensure that Your data is treated securely and in accordance
                    with this Privacy Policy and no transfer of Your Personal
                    Data will take place to an organization or a country unless
                    there are adequate controls in place including the security
                    of Your data and other personal information.
                  </p>
                  <h2 className="mt-4 block text-xl text-left leading-8 font-bold tracking-tight text-gray-900 sm:text-2xl">
                    Disclosure of Your Personal Data
                  </h2>
                  <p className="mt-4 text-xl text-gray-500 leading-8 font-bold">
                    Business Transactions
                  </p>
                  <p className="mt-4 text-xl text-gray-500 leading-8">
                    If the Company is involved in a merger, acquisition or asset
                    sale, Your Personal Data may be transferred. We will provide
                    notice before Your Personal Data is transferred and becomes
                    subject to a different Privacy Policy.
                  </p>
                  <p className="mt-4 text-xl text-gray-500 leading-8 font-bold">
                    Law enforcement
                  </p>
                  <p className="mt-4 text-xl text-gray-500 leading-8">
                    Under certain circumstances, the Company may be required to
                    disclose Your Personal Data if required to do so by law or
                    in response to valid requests by public authorities (e.g. a
                    court or a government agency).
                  </p>
                  <p className="mt-4 text-xl text-gray-500 leading-8 font-bold">
                    Other legal requirements
                  </p>
                  <p className="mt-4 text-xl text-gray-500 leading-8">
                    The Company may disclose Your Personal Data in the good
                    faith belief that such action is necessary to:
                  </p>
                  <ul className="list-disc ml-8">
                    <li className="mt-4 text-xl text-gray-500 leading-8">
                      Comply with a legal obligation
                    </li>
                    <li className="mt-4 text-xl text-gray-500 leading-8">
                      Protect and defend the rights or property of the Company
                    </li>
                    <li className="mt-4 text-xl text-gray-500 leading-8">
                      Prevent or investigate possible wrongdoing in connection
                      with the Service
                    </li>
                    <li className="mt-4 text-xl text-gray-500 leading-8">
                      Protect the personal safety of Users of the Service or the
                      public
                    </li>
                    <li className="mt-4 text-xl text-gray-500 leading-8">
                      Protect against legal liability
                    </li>
                  </ul>
                  <h2 className="mt-4 block text-xl text-left leading-8 font-bold tracking-tight text-gray-900 sm:text-2xl">
                    Security of Your Personal Data
                  </h2>
                  <p className="mt-4 text-xl text-gray-500 leading-8">
                    The security of Your Personal Data is important to Us, but
                    remember that no method of transmission over the Internet,
                    or method of electronic storage is 100% secure. While We
                    strive to use commercially acceptable means to protect Your
                    Personal Data, We cannot guarantee its absolute security.
                  </p>
                  <h1 className="mt-4 block text-2xl text-left leading-8 font-bold tracking-tight text-gray-900 sm:text-3xl">
                    Detailed Information on the Processing of Your Personal Data
                  </h1>
                  <p className="mt-4 text-xl text-gray-500 leading-8">
                    The Service Providers We use may have access to Your
                    Personal Data. These third-party vendors collect, store,
                    use, process and transfer information about Your activity on
                    Our Service in accordance with their Privacy Policies.
                  </p>
                  <h2 className="mt-4 block text-xl text-left leading-8 font-bold tracking-tight text-gray-900 sm:text-2xl">
                    Analytics
                  </h2>
                  <p className="mt-4 text-xl text-gray-500 leading-8">
                    We may use third-party Service providers to monitor and
                    analyze the use of our Service.
                  </p>
                  <ul className="list-disc ml-8">
                    <li className="mt-4 text-xl text-gray-500 leading-8">
                      <p className="mt-4 text-xl text-gray-500 leading-8">
                        Google Analytics
                      </p>
                      <p className="mt-4 text-xl text-gray-500 leading-8">
                        Google Analytics is a web analytics service offered by
                        Google that tracks and reports website traffic. Google
                        uses the data collected to track and monitor the use of
                        our Service. This data is shared with other Google
                        services. Google may use the collected data to
                        contextualize and personalize the ads of its own
                        advertising network.
                      </p>
                      <p className="mt-4 text-xl text-gray-500 leading-8">
                        You can opt-out of having made your activity on the
                        Service available to Google Analytics by installing the
                        Google Analytics opt-out browser add-on. The add-on
                        prevents the Google Analytics JavaScript (ga.js,
                        analytics.js and dc.js) from sharing information with
                        Google Analytics about visits activity.
                      </p>
                      <p className="mt-4 text-xl text-gray-500 leading-8">
                        For more information on the privacy practices of Google,
                        please visit the Google Privacy &amp; Terms web page:{" "}
                        <a
                          href="https://policies.google.com/privacy"
                          rel="external nofollow noopener noreferrer"
                          target="_blank"
                        >
                          https://policies.google.com/privacy
                        </a>
                      </p>
                    </li>
                  </ul>
                  <h1 className="mt-4 block text-2xl text-left leading-8 font-bold tracking-tight text-gray-900 sm:text-3xl">
                    Children's Privacy
                  </h1>
                  <p className="mt-4 text-xl text-gray-500 leading-8">
                    Our Service does not address anyone under the age of 13. We
                    do not knowingly collect personally identifiable information
                    from anyone under the age of 13. If You are a parent or
                    guardian and You are aware that Your child has provided Us
                    with Personal Data, please contact Us. If We become aware
                    that We have collected Personal Data from anyone under the
                    age of 13 without verification of parental consent, We take
                    steps to remove that information from Our servers.
                  </p>
                  <p className="mt-4 text-xl text-gray-500 leading-8">
                    If We need to rely on consent as a legal basis for
                    processing Your information and Your country requires
                    consent from a parent, We may require Your parent's consent
                    before We collect and use that information.
                  </p>
                  <h1 className="mt-4 block text-2xl text-left leading-8 font-bold tracking-tight text-gray-900 sm:text-3xl">
                    Links to Other Websites
                  </h1>
                  <p className="mt-4 text-xl text-gray-500 leading-8">
                    Our Service may contain links to other websites that are not
                    operated by Us. If You click on a third party link, You will
                    be directed to that third party's site. We strongly advise
                    You to review the Privacy Policy of every site You visit.
                  </p>
                  <p className="mt-4 text-xl text-gray-500 leading-8">
                    We have no control over and assume no responsibility for the
                    content, privacy policies or practices of any third party
                    sites or services.
                  </p>
                  <h1 className="mt-4 block text-2xl text-left leading-8 font-bold tracking-tight text-gray-900 sm:text-3xl">
                    Changes to this Privacy Policy
                  </h1>
                  <p className="mt-4 text-xl text-gray-500 leading-8">
                    We may update Our Privacy Policy from time to time. We will
                    notify You of any changes by posting the new Privacy Policy
                    on this page.
                  </p>
                  <p className="mt-4 text-xl text-gray-500 leading-8">
                    We will let You know via email and/or a prominent notice on
                    Our Service, prior to the change becoming effective and
                    update the &quot;Last updated&quot; date at the top of this
                    Privacy Policy.
                  </p>
                  <p className="mt-4 text-xl text-gray-500 leading-8">
                    You are advised to review this Privacy Policy periodically
                    for any changes. Changes to this Privacy Policy are
                    effective when they are posted on this page.
                  </p>
                  <h1 className="mt-4 block text-2xl text-left leading-8 font-bold tracking-tight text-gray-900 sm:text-3xl">
                    Contact Us
                  </h1>
                  <p className="mt-4 text-xl text-gray-500 leading-8">
                    If you have any questions about this Privacy Policy, You can
                    contact us by email: hello@plenishwealth.com
                  </p>
                </div>
              </div>
            </div>
          </div>
        </main>

        <footer className="theme-footer-eight mt-100">
          <div className="top-footer">
            <div className="container">
              <FooterEightPlenish />
            </div>
            {/* /.container */}
          </div>
          {/* /.top-footer */}

          <div className="container">
            <div className="bottom-footer-content">
              <CopyRightFourPlenish />
            </div>
            {/*  /.bottom-footer */}
          </div>
        </footer>
        {/* /.theme-footer-one */}
      </div>
    </div>
  );
};

export default PrivacyPolicy;
