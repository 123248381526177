import React from "react";

// Main Preview Pages
// import PreviewMain from "../views/PreviewMain";

// All HOME PAGE ROUTES
// import EventOrganizer from "../views/all-home-pages/EventOrganizer";
// import DocLanding from "../views/all-home-pages/DocLanding";
// import DocSignatureLanding from "../views/all-home-pages/DocSignatureLanding";
// import ProjectManagement from "../views/all-home-pages/ProjectManagement";
// import CustomerSupport from "../views/all-home-pages/CustomerSupport";
// import ProductLanding from "../views/all-home-pages/ProductLanding";
// import ProductLandingDark from "../views/all-home-pages/ProductLandingDark";
// import NoteTakingLanding from "../views/all-home-pages/NoteTakingLanding";
// import VideoEditorLanding from "../views/all-home-pages/VideoEditorLanding";
// import AppointmentScheduling from "../views/all-home-pages/AppointmentScheduling";
import PlenishHome from "../views/all-home-pages/PlenishHome";
// import MobileAppLanding from "../views/all-home-pages/MobileAppLanding";
// import WebsiteBuilderLanding from "../views/all-home-pages/WebsiteBuilderLanding";
// import ComingSoon from "../views/all-home-pages/ComingSoon";

// All INNER PAGES ROUTES START FROM BELLOW

// PAGES DROPDOWN ALL ROUTES

// Team inner pages
// import Team1 from "../views/inner-pages/pages/team/Team1";
// import Team2 from "../views/inner-pages/pages/team/Team2";
// import Team3 from "../views/inner-pages/pages/team/Team3";
// import Team4 from "../views/inner-pages/pages/team/Team4";
// import Team5 from "../views/inner-pages/pages/team/Team5";
// import Team6 from "../views/inner-pages/pages/team/Team6";
// import TeamDetailsV1 from "../views/inner-pages/pages/team/TeamDetailsV1";
// import TeamDetailsV2 from "../views/inner-pages/pages/team/TeamDetailsV2";
// import Faq from "../views/inner-pages/pages/Faq";
// import FaqDetails from "../views/inner-pages/pages/FaqDetails";

// Contact us inner pages
// import ContactCustomerSupport from "../views/inner-pages/pages/contact/ContactCustomerSupport";
// import ContactEventOrganizer from "../views/inner-pages/pages/contact/ContactEventOrganizer";
// import ContactProjectManagement from "../views/inner-pages/pages/contact/ContactProjectManagement";
// import ContactDocumentation from "../views/inner-pages/pages/contact/ContactDocumentation";

// About us inner pages
// import AboutCustomerSupport from "../views/inner-pages/pages/about/AboutCustomerSupport";
// import AboutEventOrganizer from "../views/inner-pages/pages/about/AboutEventOrganizer";
// import AboutProjectManagement from "../views/inner-pages/pages/about/AboutProjectManagement";
// import AboutDocumentation from "../views/inner-pages/pages/about/AboutDocumentation";

// Pricing inner pages
// import PricingCustomerSupport from "../views/inner-pages/pages/pricing/PricingCustomerSupport";
// import PricingEventOrganizer from "../views/inner-pages/pages/pricing/PricingEventOrganizer";
// import PricingProjectManagement from "../views/inner-pages/pages/pricing/PricingProjectManagement";

// FEATURES DROPDOWN ALL ROUTES
// import Login from "../views/inner-pages/features/miscellaneous/Login";
// import SignUp from "../views/inner-pages/features/miscellaneous/SignUp";
// import TermsConditions from "../views/inner-pages/features/miscellaneous/TermsConditions";
// import TermsConditionsPlenish from "../views/inner-pages/features/miscellaneous/TermsConditionsPlenish";
// import SolutionMangement from "../views/inner-pages/features/SolutionMangement";
// import ProductCustomerSupport from "../views/inner-pages/features/ProductCustomerSupport";
// import FeaturesCustomerSupport from "../views/inner-pages/features/FeaturesCustomerSupport";

// SERVICE PAGES ROUTES
// import ServiceV1 from "../views/inner-pages/service/ServiceV1";
// import ServiceV2 from "../views/inner-pages/service/ServiceV2";
// import ServiceV3 from "../views/inner-pages/service/ServiceV3";
// import ServiceV4 from "../views/inner-pages/service/ServiceV4";
// import ServiceDetails from "../views/inner-pages/service/ServiceDetails";

// DOCS DROPDOWN ALL ROUTES
// import DocFullWidth from "../views/inner-pages/docs/DocFullWidth";
// import DocFullWidthBanner from "../views/inner-pages/docs/DocFullWidthBanner";
// import DocBox from "../views/inner-pages/docs/DocBox";
// import DocBoxWithBanner from "../views/inner-pages/docs/DocBoxWithBanner";
// import Changelog from "../views/inner-pages/docs/Changelog";

// PORTFOLIO DROPDOWN ALL ROUTES
// import PortfolioV1 from "../views/inner-pages/portfolio/PortfolioV1";
// import PortfolioV2 from "../views/inner-pages/portfolio/PortfolioV2";
// import PortfolioV3 from "../views/inner-pages/portfolio/PortfolioV3";
// import PortfolioV4 from "../views/inner-pages/portfolio/PortfolioV4";
// import PortfolioV5 from "../views/inner-pages/portfolio/PortfolioV5";
// import PortfolioDetailsV1 from "../views/inner-pages/portfolio/PortfolioDetailsV1";

// BLOGS DROPDOWN ALL ROUTES
import BlogPlenish from "../views/inner-pages/blog-pages/BlogPlenish";
// import BlogV1 from "../views/inner-pages/blog-pages/BlogV1";
// import BlogV2 from "../views/inner-pages/blog-pages/BlogV2";
// import BlogV3 from "../views/inner-pages/blog-pages/BlogV3";
// import BlogV4 from "../views/inner-pages/blog-pages/BlogV4";
// import BlogV5 from "../views/inner-pages/blog-pages/BlogV5";
// import BlogV6 from "../views/inner-pages/blog-pages/BlogV6";
import BlogDetailsHowBigOfAnInvestmentPropertyCanIAfford from "../views/inner-pages/blog-pages/BlogDetailsHowBigOfAnInvestmentPropertyCanIAfford";
import BlogDetailsAllYouNeedToKnowAboutPrivateMortgageInsurance from "../views/inner-pages/blog-pages/BlogDetailsAllYouNeedToKnowAboutPrivateMortgageInsurance";
import BlogDetailsPortfolioDiversificationImportanceAndBestPractices from "../views/inner-pages/blog-pages/BlogDetailsPortfolioDiversificationImportanceAndBestPractices";
import BlogDetailsShouldIOwnRealEstateInMyPortfolio from "../views/inner-pages/blog-pages/BlogDetailsShouldIOwnRealEstateInMyPortfolio";
import BlogDetailsLearnAboutETFs from "../views/inner-pages/blog-pages/BlogDetailsLearnAboutETFs";
import BlogDetailsChecklistOfWhatToConsiderIfYouAreBuyingYourFirstHome from "../views/inner-pages/blog-pages/BlogDetailsChecklistOfWhatToConsiderIfYouAreBuyingYourFirstHome";
import BlogDetailsStockInvestmentStrategies from "../views/inner-pages/blog-pages/BlogDetailsStockInvestmentStrategies";
import BlogDetailsHomeInspectionGuideForRealEstateInvesting from "../views/inner-pages/blog-pages/BlogDetailsHomeInspectionGuideForRealEstateInvesting";
import BlogDetailsShouldICarryMyMortgageIntoMyRetirement from "../views/inner-pages/blog-pages/BlogDetailsShouldICarryMyMortgageIntoMyRetirement";
import BlogDetailsEverythingAboutDividends from "../views/inner-pages/blog-pages/BlogDetailsEverythingAboutDividends";
import BlogDetailsDiversifyingYourInvestmentPortfolioWithRealEstate from "../views/inner-pages/blog-pages/BlogDetailsDiversifyingYourInvestmentPortfolioWithRealEstate";
import BlogDetailsHowTheTaxWorksWhenYouSellStocks from "../views/inner-pages/blog-pages/BlogDetailsHowTheTaxWorksWhenYouSellStocks";
import BlogDetailsTipsOnFindingTheBestInvestmentBroker from "../views/inner-pages/blog-pages/BlogDetailsTipsOnFindingTheBestInvestmentBroker";
import BlogDetailsShouldIBuyOrRent from "../views/inner-pages/blog-pages/BlogDetailsShouldIBuyOrRent";
import BlogDetailsCondoVsHouseWhichIsBestForYou from "../views/inner-pages/blog-pages/BlogDetailsCondoVsHouseWhichIsBestForYou";
import PlenishPromoRedirect from "../views/inner-pages/blog-pages/PlenishPromoRedirect";

import TermsConditions from "../views/inner-pages/blog-pages/TermsConditions";
import PrivacyPolicy from "../views/inner-pages/blog-pages/PrivacyPolicy";

import PlenishPromo1 from "../views/inner-pages/blog-pages/PlenishPromo1";
import PlenishPromo2 from "../views/inner-pages/blog-pages/PlenishPromo2";
import PlenishPromo3 from "../views/inner-pages/blog-pages/PlenishPromo3";
import PlenishPromo4 from "../views/inner-pages/blog-pages/PlenishPromo4";
import PlenishPromo5 from "../views/inner-pages/blog-pages/PlenishPromo5";
import PlenishPromo6 from "../views/inner-pages/blog-pages/PlenishPromo6";
import PlenishPromo7 from "../views/inner-pages/blog-pages/PlenishPromo7";
import PlenishPromo8 from "../views/inner-pages/blog-pages/PlenishPromo8";

import InvestDiva from "../views/inner-pages/blog-pages/InvestDiva";

import GlobalExpansionLanding from "../views/inner-pages/blog-pages/GlobalExpansionLanding";
import GlobalExpansionAppointment from "../views/inner-pages/blog-pages/GlobalExpansionAppointment";
import GlobalExpansionThankYou from "../views/inner-pages/blog-pages/GlobalExpansionThankYou";

import ThankYouAdvisor from "../views/inner-pages/forms/ThankYouAdvisor";
import ThankYouJoin from "../views/inner-pages/forms/ThankYouJoin";
import ThankYouPromo from "../views/inner-pages/forms/ThankYouPromo";
import FormAppPlenish from "../components/form_plenish/FormApp";
import InvestDivaForm from "../components/form_invest_diva/InvestDivaForm";

// Not Found Page
// import NotFound from "../views/NotFound";

// Route Specific
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ScrollTopBehaviour from "../components/ScrollTopBehaviour";
// import FormSurveyLanding from "../views/all-home-pages/FormSurveyLanding";
// import VrLanding from "../views/all-home-pages/VrLanding";

const PlenishRoutes = () => {
  return (
    <>
      <BrowserRouter>
        <ScrollTopBehaviour />
        <Routes>
          <Route exact path="/" element={<PlenishHome />} />
          <Route exact path="/home" element={<PlenishHome />} />
          <Route
            exact
            path="/globalexpansion"
            element={<GlobalExpansionLanding />}
          />
          <Route
            exact
            path="/globalexpansion_appointment"
            element={<GlobalExpansionAppointment />}
          />
          <Route
            exact
            path="/globalexpansion_thankyou"
            element={<GlobalExpansionThankYou />}
          />
          <Route
            exact
            path="/thank_you_advisor"
            element={<ThankYouAdvisor />}
          />

          <Route
            exact
            path="/thank_you_advisor"
            element={<ThankYouAdvisor />}
          />
          <Route exact path="/thank_you_join" element={<ThankYouJoin />} />
          <Route exact path="/thank_you_promo" element={<ThankYouPromo />} />
          {/* <Route path="/event-organizer" element={<EventOrganizer/>} />
          <Route path="/doc-landing" element={<DocLanding/>} />
          <Route path="/doc-signature" element={<DocSignatureLanding/>} />
          <Route path="/project-management" element={<ProjectManagement/>} />
          <Route path="/customer-support" element={<CustomerSupport/>} />
          <Route path="/product-landing" element={<ProductLanding/>} />
          <Route path="/product-landing-dark" element={<ProductLandingDark/>} />
          <Route path="/note-taking-landing" element={<NoteTakingLanding/>} />
          <Route path="/video-editor-landing" element={<VideoEditorLanding/>} />
          <Route
            path="/appointment-scheduling"
            element={<AppointmentScheduling}
          />
          <Route path="/mobile-app-landing" element={<MobileAppLanding/>} />
          <Route path="/website-builder" element={<WebsiteBuilderLanding/>} />
          <Route path="/form-survey-landing" element={<FormSurveyLanding/>} />
          <Route path="/vr-landing" element={<VrLanding/>} />
          <Route path="/coming-soon" element={<ComingSoon/>} /> */}

          {/* Pages Dropdown Routes */}
          {/* <Route path="/team-1" element={<Team1/>} />
          <Route path="/team-2" element={<Team2/>} />
          <Route path="/team-3" element={<Team3/>} />
          <Route path="/team-4" element={<Team4/>} />
          <Route path="/team-5" element={<Team5/>} />
          <Route path="/team-6" element={<Team6/>} />
          <Route path="/team-details-v1" element={<TeamDetailsV1/>} />
          <Route path="/team-details-v2" element={<TeamDetailsV2/>} /> */}
          {/* faq inner pages */}
          {/* <Route path="/faq" element={<Faq/>} />
          <Route path="/faq-details" element={<FaqDetails/>} /> */}
          {/* contact us inner pages */}
          {/* <Route path="/contact-cs" element={<ContactCustomerSupport/>} />
          <Route path="/contact-eo" element={<ContactEventOrganizer/>} />
          <Route path="/contact-pm" element={<ContactProjectManagement/>} />
          <Route path="/contact-doc" element={<ContactDocumentation/>} /> */}
          {/* about us inner pages */}
          {/* <Route path="/about-cs" element={<AboutCustomerSupport/>} />
          <Route path="/about-eo" element={<AboutEventOrganizer/>} />
          <Route path="/about-pm" element={<AboutProjectManagement/>} />
          <Route path="/about-doc" element={<AboutDocumentation/>} /> */}
          {/* pricing inner pages */}
          {/* <Route path="/pricing-cs" element={<PricingCustomerSupport/>} />
          <Route path="/pricing-eo" element={<PricingEventOrganizer/>} />
          <Route path="/pricing-pm" element={<PricingProjectManagement/>} /> */}

          {/* Feature Dropdown Routes */}
          {/* <Route path="/terms-service" element={<TermsConditionsPlenish/>} /> */}
          {/* <Route path="/login" element={<Login/>} />
          <Route path="/SignUp" element={<SignUp/>} />
          <Route path="/terms-conditions" element={<TermsConditions/>} />
          <Route path="/solution-management" element={<SolutionMangement/>} />
          <Route
            path="/product-customer-support"
            element={<ProductCustomerSupport}
          />
          <Route
            path="/features-customer-support"
            element={<FeaturesCustomerSupport}
          /> */}

          {/* Service inner pages */}
          {/* <Route path="/service-v1" element={<ServiceV1/>} />
          <Route path="/service-v2" element={<ServiceV2/>} />
          <Route path="/service-v3" element={<ServiceV3/>} />
          <Route path="/service-v4" element={<ServiceV4/>} />
          <Route path="/service-details" element={<ServiceDetails/>} /> */}

          {/* inner pages Docs   */}
          {/* <Route path="/doc-full-width" element={<DocFullWidth/>} />
          <Route path="/doc-full-width-banner" element={<DocFullWidthBanner/>} />
          <Route path="/doc-box" element={<DocBox/>} />
          <Route path="/doc-box-with-banner" element={<DocBoxWithBanner/>} />
          <Route path="/changelog" element={<Changelog/>} /> */}

          {/* inner Portfolio pages Dropdown   */}
          {/* <Route path="/classic-style" element={<PortfolioV1/>} />
          <Route path="/grid-two-col" element={<PortfolioV2/>} />
          <Route path="/grid-three-col" element={<PortfolioV3/>} />
          <Route path="/gallery-slider" element={<PortfolioV4/>} />
          <Route path="/grid-single" element={<PortfolioV5/>} />
          <Route path="/portfolio-details-v1" element={<PortfolioDetailsV1/>} /> */}

          {/* inner Blog pages Dropdown   */}
          {/* <Route path="/blog-v1" element={<BlogV1/>} /> */}
          <Route path="/blog" element={<BlogPlenish />} />
          <Route path="/get-started" element={<FormAppPlenish />} />

          <Route path="/invest-diva-form" element={<InvestDivaForm />} />
          <Route
            path="/blog_how_big_of_an_investment_property_can_i_afford"
            element={<BlogDetailsHowBigOfAnInvestmentPropertyCanIAfford />}
          />
          <Route
            path="/blog_all_you_need_to_know_about_private_mortgage_insurance"
            element={
              <BlogDetailsAllYouNeedToKnowAboutPrivateMortgageInsurance />
            }
          />
          <Route
            path="/blog_portfolio_diversification_importance_and_best_practices"
            element={
              <BlogDetailsPortfolioDiversificationImportanceAndBestPractices />
            }
          />
          <Route
            path="/blog_should_i_own_real_estate_in_my_portfolio"
            element={<BlogDetailsShouldIOwnRealEstateInMyPortfolio />}
          />
          <Route
            path="/blog_learn_about_etfs"
            element={<BlogDetailsLearnAboutETFs />}
          />
          <Route
            path="/blog_checklist_of_what_to_consider_if_you_are_buying_your_first_home"
            element={
              <BlogDetailsChecklistOfWhatToConsiderIfYouAreBuyingYourFirstHome />
            }
          />
          <Route
            path="/blog_stock_investment_strategies"
            element={<BlogDetailsStockInvestmentStrategies />}
          />
          <Route
            path="/blog_home_inspection_guide_for_real_estate_investing"
            element={<BlogDetailsHomeInspectionGuideForRealEstateInvesting />}
          />
          <Route
            path="/blog_should_i_carry_my_mortgage_into_my_retirement"
            element={<BlogDetailsShouldICarryMyMortgageIntoMyRetirement />}
          />
          <Route
            path="/blog_everything_about_dividends"
            element={<BlogDetailsEverythingAboutDividends />}
          />
          <Route
            path="/blog_diversifying_your_investment_portfolio_with_real_state"
            element={
              <BlogDetailsDiversifyingYourInvestmentPortfolioWithRealEstate />
            }
          />
          <Route
            path="/blog_how_the_tax_works_when_you_sell_stocks"
            element={<BlogDetailsHowTheTaxWorksWhenYouSellStocks />}
          />
          <Route
            path="/blog_tips_on_finding_the_best_broker"
            element={<BlogDetailsTipsOnFindingTheBestInvestmentBroker />}
          />
          <Route
            path="/blog_should_i_buy_or_rent"
            element={<BlogDetailsShouldIBuyOrRent />}
          />
          <Route
            path="/blog_condo_vs_house_which_is_best_for_you"
            element={<BlogDetailsCondoVsHouseWhichIsBestForYou />}
          />

          <Route path="/terms-conditions" element={<TermsConditions />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />

          <Route path="/promo" element={<PlenishPromoRedirect />} />
          <Route path="/promo_1" element={<PlenishPromo1 />} />
          <Route path="/promo_2" element={<PlenishPromo2 />} />
          <Route path="/promo_3" element={<PlenishPromo3 />} />
          <Route path="/promo_4" element={<PlenishPromo4 />} />
          <Route path="/promo_5" element={<PlenishPromo5 />} />
          <Route path="/promo_6" element={<PlenishPromo6 />} />
          <Route path="/promo_7" element={<PlenishPromo7 />} />
          <Route path="/promo_8" element={<PlenishPromo8 />} />
          <Route path="/invest-diva" element={<InvestDiva />} />

          {/* <Route path="/blog/how_big_of_an_investment_property_can_i_afford" element={<BlogDetailsPlenish1/>} />
          <Route path="/blog/how_big_of_an_investment_property_can_i_afford" element={<BlogDetailsPlenish1/>} />
          <Route path="/blog/how_big_of_an_investment_property_can_i_afford" element={<BlogDetailsPlenish1/>} />
          <Route path="/blog/how_big_of_an_investment_property_can_i_afford" element={<BlogDetailsPlenish1/>} />
          <Route path="/blog/how_big_of_an_investment_property_can_i_afford" element={<BlogDetailsPlenish1/>} />
          <Route path="/blog/how_big_of_an_investment_property_can_i_afford" element={<BlogDetailsPlenish1/>} />
          <Route path="/blog/how_big_of_an_investment_property_can_i_afford" element={<BlogDetailsPlenish1/>} />
          <Route path="/blog/how_big_of_an_investment_property_can_i_afford" element={<BlogDetailsPlenish1/>} />
          <Route path="/blog/how_big_of_an_investment_property_can_i_afford" element={<BlogDetailsPlenish1/>} />
          <Route path="/blog/how_big_of_an_investment_property_can_i_afford" element={<BlogDetailsPlenish1/>} />
          <Route path="/blog/how_big_of_an_investment_property_can_i_afford" element={<BlogDetailsPlenish1/>} />
          <Route path="/blog/how_big_of_an_investment_property_can_i_afford" element={<BlogDetailsPlenish1/>} />
          <Route path="/blog/how_big_of_an_investment_property_can_i_afford" element={<BlogDetailsPlenish1/>} />
          <Route path="/blog/how_big_of_an_investment_property_can_i_afford" element={<BlogDetailsPlenish1/>} /> */}
          {/* <Route path="/blog-v2" element={<BlogV2/>} />
          <Route path="/blog-v3" element={<BlogV3/>} />
          <Route path="/blog-v4" element={<BlogV4/>} />
          <Route path="/blog-v5" element={<BlogV5/>} />
          <Route path="/blog-v6" element={<BlogV6/>} />
          <Route path="/blog-details" element={<BlogDetails/>} />
          <Route path="/404" element={<NotFound/>} /> */}

          {/* NotFound Route */}
          <Route element={<PlenishHome />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default PlenishRoutes;
