import React from "react";

const FeatureContent = [
  {
    icon: "icon_plenish_4.png",
    title: "A safe space for all your financial questions",
    subTitle: `No sales pressure, no product push, no conflict of interest. You are in control of what you want.`,
    bgColor: "#00B08D",
  },
  {
    icon: "icon_plenish_5.png",
    title: "Licensed, experienced, and handpicked experts",
    subTitle: `Advisors who are registered and have many years of experience relevant to the questions you have, selected through a rigorous process.`,
    bgColor: "#00B08D",
  },
  {
    icon: "icon_plenish_3.png",
    title: "Information and advice on a wide range of topics",
    subTitle: `From investments, to real estate, to estate planning and much more, to help you through your journey.`,
    bgColor: "#00B08D",
  },
];

const FancyFeatureTewentyTwoPlenish = () => {
  return (
    <>
      {FeatureContent.map((val, i) => (
        <div
          className="col-lg-4 col-sm-6 m-2 m-md-0 py-md-2"
          data-aos="fade-up"
          data-aos-duration="1200"
          key={i}
        >
          <div className="block-style-twentyTwo h-100">
            <div
              className="icon d-flex align-items-center justify-content-center"
              style={{ background: val.bgColor }}
            >
              <img
                src={`images/assets/${val.icon}`}
                alt="icon"
                className="p-3"
              />
            </div>
            <h4>{val.title}</h4>
            <p>{val.subTitle}</p>
          </div>
          {/* /.block-style-twentyTwo */}
        </div>
      ))}
    </>
  );
};

export default FancyFeatureTewentyTwoPlenish;
