import React from "react";
import { Link } from "react-router-dom";
// import FormJoinPlenish from "../form/FormJoinPlenish";

const HeroBannerThirteenPlenish = () => {
  return (
    // <!--
    // =============================================
    //     Theme Hero Banner
    // ==============================================
    // -->
    <div className="hero-banner-thirteen lg-container" id="home">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 -mt-10 sm:mt-5 pr-5">
            {/* <Link to="/promo" className="slogan tran3s">
              <strong>Offer</strong> is going on till tomorrow, try it for <strong>free</strong>.{" "}
              <i className="fa fa-chevron-right" aria-hidden="true"></i>
            </Link> */}
            <h1 className="hero-heading">Find answers that you can trust</h1>
            <p className="hero-sub-heading">
              Personal finances can be confusing and stressful. We are here to
              make that easy for you.
              {/* <img src="images/shape/line-shape-11.svg" alt="" className="mt-n3" /> */}
            </p>
            {/* <p className="hero-sub-sub-heading">
              Think of Plenish as a Google for all your financial questions, but only better, with personalized answers from real certified financial advisors, and no obligation to hire one.
            </p> */}
            <div className="d-flex justify-content-center align-items-center">
              {/* <img src="images/shape/line-shape-8.svg" alt="line shape" /> */}
            </div>
            <div className="flex">
              <div className="mx-auto sm:mx-0">
                <Link to="/promo">
                  <button className="bg-green-700 hover:bg-green-500 text-white font-normal text-lg sm:text-xl py-3 px-6 my-4 rounded-full">
                    Get Started
                  </button>
                </Link>
              </div>
            </div>
            {/* <FormJoinPlenish /> */}
            {/* <p className="hero-sub-heading">
              Deski delivered blazing fast performance, striking word soludtion
            </p>
            <a href="#" className="theme-btn-fourteen">
              Get Started - It's Free
            </a>
            <div className="info">No credit card required</div> */}
          </div>
          <div className="col-lg-6 w-125">
            {/* <div className="illustration-container"> */}
            <img
              src="images/assets/happy_couple_2.jpg"
              alt="screen"
              className="m-auto picture_hero"
            />
            {/* </div> */}
          </div>
        </div>

        {/* <div className="screen-holder">
           <img
            src="images/assets/ils_22.svg"
            alt="illustration"
            className="img-meta"
          />
          <img
            src="images/assets/ils_22.1.svg"
            alt="illustration"
            className="shapes shape-one"
          />
          <img
            src="images/assets/ils_22.2.svg"
            alt="illustration"
            className="shapes shape-two"
          />
        </div> */}
        {/* <!-- /.screen-holder --> */}
      </div>
    </div>
    // <!-- /.hero-banner-thirteen -->
  );
};

export default HeroBannerThirteenPlenish;
