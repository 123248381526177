import React from "react";
import { Helmet } from "react-helmet";
// import Header from "../../../components/header/Header";
import BlogStyle1Plenish from "../../../components/blog/BlogStyle1Plenish";
import FooterEightPlenish from "../../../components/footer/FooterEightPlenish";
import CopyRightFourPlenish from "../../../components/footer/CopyRightFourPlenish";
import HeaderLandingAppointmentPlenish from "../../../components/header/landing/HeaderLandingAppointmentPlenish";

const BlogV1Plenish = () => {
  return (
    <div className="bg-gray-50">
      {/* <div className="banner-promo">
        Get your questions answered,{" "}
        <Link to="/promo" className="">
          try for free
        </Link>
      </div> */}
      <div className="main-page-wrapper p0 font-gordita">
        <Helmet>
          <title>Plenish | Blog</title>
        </Helmet>
        {/* End Page SEO Content */}

        <HeaderLandingAppointmentPlenish />
        {/* End Header Landing Appoinment Scheduling */}

        {/* <div id="home"> */}
        {/* <HeroBannerNinePlenish /> */}
        {/* <HeroBannerThirteenPlenish /> */}

        {/* </div> */}
        {/* End Hero Banner Nine */}

        {/* 	=============================================
            Fancy Hero One
        ==============================================  */}
        <div className="fancy-hero-one mt-80">
          {/* <div className="container"> */}
          <div className="row">
            <div className="col-xl-9 col-lg-10 m-auto bg-gray-50">
              <h2 className="font-gilroy-bold">Blog</h2>
            </div>
            {/* </div> */}
          </div>
        </div>
        {/* /.fancy-hero-one */}

        {/* =====================================================
            Feature Blog One
        ===================================================== */}
        <div className="feature-blog-one blog-page-bg">
          <div className="container">
            <div className="row">
              <BlogStyle1Plenish />
            </div>
            {/* End .row */}
            {/* <div
            className="text-center mt-30 md-mt-10"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-delay="50"
          >
            <a href="#" className="theme-btn-one">
              Show More
            </a>
          </div> */}
          </div>
        </div>
        {/* /.feature-blog-one */}

        <footer className="theme-footer-eight mt-100">
          <div className="top-footer">
            <div className="container">
              <FooterEightPlenish />
            </div>
            {/* /.container */}
          </div>
          {/* /.top-footer */}

          <div className="container">
            <div className="bottom-footer-content">
              <CopyRightFourPlenish />
            </div>
            {/*  /.bottom-footer */}
          </div>
        </footer>
        {/* /.theme-footer-one */}
      </div>
    </div>
  );
};

export default BlogV1Plenish;
