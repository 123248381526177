import React from "react";
import { Helmet } from "react-helmet";
import HeaderLandingAppointmentPlenish from "../../../components/header/landing/HeaderLandingAppointmentPlenish";
import FooterEightPlenish from "../../../components/footer/FooterEightPlenish";
import CopyRightFourPlenish from "../../../components/footer/CopyRightFourPlenish";
import { InlineWidget } from "react-calendly";

const GlobalExpansionAppointment = () => {
  return (
    <div>
      <div className="main-page-wrapper p0 font-gordita">
        <Helmet>
          <title>Plenish | Global Expansion</title>
        </Helmet>
        {/* End Page SEO Content */}

        <HeaderLandingAppointmentPlenish />

        {/* End Header */}

        <main className="hero-banner-thirteen lg-container">
          <div className="bg-gray-50 font-gordita p-1 -mt-10">
            <div className="relative max-w-5xl mx-auto pb-32">
              <InlineWidget
                styles={{ minWidth: 320, height: 900 }}
                url="https://calendly.com/plenish-globalexpansion/30min?hide_gdpr_banner=1&primary_color=047857"
              />
            </div>
          </div>
        </main>

        <footer className="theme-footer-eight mt-100">
          <div className="top-footer">
            <div className="container">
              <FooterEightPlenish />
            </div>
            {/* /.container */}
          </div>
          {/* /.top-footer */}

          <div className="container">
            <div className="bottom-footer-content">
              <CopyRightFourPlenish />
            </div>
            {/*  /.bottom-footer */}
          </div>
        </footer>
        {/* /.theme-footer-one */}
      </div>
    </div>
  );
};

export default GlobalExpansionAppointment;
