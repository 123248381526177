import React from "react";
import { Helmet } from "react-helmet";
import HeaderLandingAppointmentPlenish from "../../../components/header/landing/HeaderLandingAppointmentPlenish";
import FooterEightPlenish from "../../../components/footer/FooterEightPlenish";
import CopyRightFourPlenish from "../../../components/footer/CopyRightFourPlenish";

const BlogDetailsEverythingAboutDividends = () => {
  return (
    <div>
      {/* <div className="banner-promo">
        Get your questions answered,{" "}
        <Link to="/promo" className="">
          try for free
        </Link>
      </div> */}
      <div className="main-page-wrapper p0 font-gordita">
        <Helmet>
          <title>Plenish | Blog | Everything About Dividends</title>
        </Helmet>
        {/* End Page SEO Content */}

        <HeaderLandingAppointmentPlenish />
        {/* End Header */}

        {/* =============================================
            Fancy Hero Two
        ==============================================  */}
        <div className="fancy-hero-five bg-gray-50">
          <div className="bg-wrapper ">
            <div className="container">
              <div className="col-lg-10 m-auto text-center">
                <h1 className="heading">Everything About Dividends</h1>
              </div>
            </div>
          </div>
          {/* /.bg-wrapper */}
        </div>
        {/* /.fancy-hero-two */}

        {/* =====================================================
            Feature Blog One
        ===================================================== */}
        <main className="bg-gray-50">
          <div className="relative flex justify-between items-center max-w-4xl mx-auto">
            <div className="relative px-4 sm:px-6 lg:px-8">
              <div className="text-lg mx-auto">
                {/* <h1 className="my-2 block text-3xl text-center leading-8 font-bold tracking-tight text-gray-900 sm:text-4xl">
            Everything About Dividends
          </h1> */}
                <p className="mt-4 text-xl text-gray-500 leading-8">
                  Dividends are a form of distributing value back to the
                  shareholders of a company. It may be paid in cash or in the
                  form of additional shares of the company (i.e., stock
                  dividends). Stock dividends are often subject to
                  misconceptions. Some investors confuse stock dividends with a
                  stock split, typically after the share price has risen quite
                  significantly. The two events are quite different, even though
                  they are related in various ways. Understanding how they
                  differ will help you understand why stocks pay out money in
                  the first place and how to use this process to your advantage.
                </p>
                <div className="relative py-4">
                  <div className="absolute inset-x-0 bottom-0 h-1/2"></div>
                  <div className="max-w-7xl mx-auto">
                    <div className="relative shadow-xl sm:rounded-2xl sm:overflow-hidden">
                      <div className="absolute inset-0">
                        <img
                          className="h-full w-full object-cover"
                          src="images/blog/blog_11.jpg"
                          alt=""
                        />
                      </div>
                      <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-48 lg:px-8"></div>
                    </div>
                  </div>
                </div>
                <h1 className="mt-4 block text-2xl text-left leading-8 font-bold tracking-tight text-gray-900 sm:text-3xl">
                  What Is a Dividend?
                </h1>
                <p className="mt-4 text-xl text-gray-500 leading-8">
                  A dividend is a company's decision to distribute a certain
                  number of shares or cash to its shareholders as a part of its
                  annual dividend strategy. It is often paid when companies are
                  doing well and want to reward their shareholders.
                </p>
                <p className="mt-4 text-xl text-gray-500 leading-8">
                  Stock dividends are often paid by giving shareholders extra
                  shares in the company. For example, if you owned 100 shares of
                  XYZ Company which decided to pay a 10% stock dividend on all
                  outstanding shares, it would give you ten additional shares
                  for each 100 you owned.
                </p>
                <p className="mt-4 text-xl text-gray-500 leading-8">
                  Number of shares of the company increases as a result of a
                  stock dividend (dividend in the form of additional shares).
                  For example, if the company has 10 million outstanding shares
                  and issues 1 million new shares as a stock dividend, it will
                  have 11 million total outstanding shares (10 + 1).
                </p>
                <h1 className="mt-4 block text-2xl text-left leading-8 font-bold tracking-tight text-gray-900 sm:text-3xl">
                  Dividend Key Dates
                </h1>
                <p className="mt-4 text-xl text-gray-500 leading-8">
                  The cost of private mortgage insurance can be added to your
                  monthly payments. PMI can stop when loan principal drops below
                  a certain target.
                </p>
                <p className="mt-4 text-xl text-gray-500 leading-8">
                  The following dates are important in understanding how
                  dividends work:
                </p>
                <p className="mt-4 text-xl text-gray-500 leading-8">
                  <span className="font-bold">Ex-Dividend Date:</span> The
                  ex-dividend date is the first day when an investor cannot buy
                  a company's stock and receive the upcoming dividend payment.
                  After this date, if you buy shares, you will not be entitled
                  to the next dividend payment.
                </p>
                <p className="mt-4 text-xl text-gray-500 leading-8">
                  <span className="font-bold">Declaration Date:</span> The date
                  when the board of directors declares that a dividend is to be
                  paid to shareholders. This is usually two weeks before the
                  ex-date.
                </p>
                <p className="mt-4 text-xl text-gray-500 leading-8">
                  <span className="font-bold">Record Date:</span> The record
                  date is when all shareholders must have held their shares for
                  at least one full day to receive a dividend payment. It is the
                  day when the company checks its list of shareholders to
                  determine who receives a dividend.
                </p>
                <p className="mt-4 text-xl text-gray-500 leading-8">
                  <span className="font-bold">Payment Date:</span> The payment
                  date is when companies pay the dividend e.g., send the checks
                  or electronic deposits in case of cash dividend to
                  shareholders who qualify for them based on their holdings'
                  record dates and ex-dividend dates.
                </p>
                <p className="mt-4 text-xl text-gray-500 leading-8">
                  Why The Stock Price Drops After Dividends
                </p>
                <p className="mt-4 text-xl text-gray-500 leading-8">
                  When the company goes ex-dividend, the share price drops by
                  the dividend amount as a result of the distributed value to
                  shareholders.
                </p>
                <h1 className="mt-4 block text-2xl text-left leading-8 font-bold tracking-tight text-gray-900 sm:text-3xl">
                  Tax Impact
                </h1>
                <p className="mt-4 text-xl text-gray-500 leading-8">
                  Income from dividends is taxable but it can be taxed at
                  different rates based on whether it is classified as Qualified
                  or Non-Qualified. Qualified dividends get taxed at a lower
                  rate of long-term capital gains tax whereas non-qualified
                  dividends are taxed as ordinary income.
                </p>
                <p className="mt-4 text-xl text-gray-500 leading-8">
                  These are some of the things you must keep in mind when it
                  comes to dividends. While you can generate income through
                  dividends you need to know how to receive them properly.
                </p>
              </div>
            </div>
          </div>
        </main>

        <footer className="theme-footer-eight mt-100">
          <div className="top-footer">
            <div className="container">
              <FooterEightPlenish />
            </div>
            {/* /.container */}
          </div>
          {/* /.top-footer */}

          <div className="container">
            <div className="bottom-footer-content">
              <CopyRightFourPlenish />
            </div>
            {/*  /.bottom-footer */}
          </div>
        </footer>
        {/* /.theme-footer-one */}
      </div>
    </div>
  );
};

export default BlogDetailsEverythingAboutDividends;
