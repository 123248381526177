import React from "react";
import { Helmet } from "react-helmet";
import HeaderLandingAppointmentPlenish from "../../../components/header/landing/HeaderLandingAppointmentPlenish";
import FooterEightPlenish from "../../../components/footer/FooterEightPlenish";
import CopyRightFourPlenish from "../../../components/footer/CopyRightFourPlenish";

const BlogDetailsHowTheTaxWorksWhenYouSellStocks = () => {
  return (
    <div>
      {/* <div className="banner-promo">
        Get your questions answered,{" "}
        <Link to="/promo" className="">
          try for free
        </Link>
      </div> */}
      <div className="main-page-wrapper p0 font-gordita">
        <Helmet>
          <title>Plenish | Blog | How the Tax Works When You Sell Stocks</title>
        </Helmet>
        {/* End Page SEO Content */}

        <HeaderLandingAppointmentPlenish />
        {/* End Header */}

        {/* =============================================
            Fancy Hero Two
        ==============================================  */}
        <div className="fancy-hero-five bg-gray-50">
          <div className="bg-wrapper ">
            <div className="container">
              <div className="col-lg-10 m-auto text-center">
                <h1 className="heading">
                  How the Tax Works When You Sell Stocks
                </h1>
              </div>
            </div>
          </div>
          {/* /.bg-wrapper */}
        </div>
        {/* /.fancy-hero-two */}

        {/* =====================================================
            Feature Blog One
        ===================================================== */}
        <main className="bg-gray-50">
          <div className="relative flex justify-between items-center max-w-4xl mx-auto">
            <div className="relative px-4 sm:px-6 lg:px-8">
              <div className="text-lg mx-auto">
                {/* <h1 className="my-2 block text-3xl text-center leading-8 font-bold tracking-tight text-gray-900 sm:text-4xl">
                  How the Tax Works When You Sell Stocks
                </h1> */}
                <p className="mt-4 text-xl text-gray-500 leading-8">
                  Are you wondering how to calculate profits from selling
                  stocks? Do you want to know how to avoid paying taxes on your
                  stock? What are the tax rates for stocks, and how do they
                  figure into the amount you owe at tax time? These are just a
                  few of the many questions about taxes and trading stocks.
                  Capital gains taxes can be a huge concern when you sell stock.
                  Don't get caught off-guard by taxes and penalties. When you
                  make short-term capital gains or long-term capital gains, you
                  must pay taxes on your profits.
                </p>
                <div className="relative py-4">
                  <div className="absolute inset-x-0 bottom-0 h-1/2"></div>
                  <div className="max-w-7xl mx-auto">
                    <div className="relative shadow-xl sm:rounded-2xl sm:overflow-hidden">
                      <div className="absolute inset-0">
                        <img
                          className="h-full w-full object-cover"
                          src="images/blog/blog_13.jpg"
                          alt=""
                        />
                      </div>
                      <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-48 lg:px-8"></div>
                    </div>
                  </div>
                </div>
                <h1 className="mt-4 block text-2xl text-left leading-8 font-bold tracking-tight text-gray-900 sm:text-3xl">
                  What Are Capital Gains
                </h1>
                <p className="mt-4 text-xl text-gray-500 leading-8">
                  When you purchase a stock, it has a cost basis equal to the
                  amount you paid for it. If you sell that same stock for $1
                  more than what you paid for it, your capital gain is $1. If
                  you sell that same stock for $10 more than what you paid for
                  it, your capital gain is $10.
                </p>
                <p className="mt-4 text-xl text-gray-500 leading-8">
                  You'll have to pay taxes on any gains when you sell stocks.
                  The government levies capital gains taxes on your profits when
                  you sell certain types of investments, including stocks and
                  bonds. Depending on your income level, these taxes can be
                  extremely high.
                </p>
                <p className="mt-4 text-xl text-gray-500 leading-8">
                  The sale of assets can create short-term or long-term gains
                  and they are taxed at corresponding rates. A short-term gain
                  is any sale that occurs within one year from when you
                  purchased the asset. A long-term gain is any sale that occurs
                  after one year passes since you purchased it.
                </p>
                <h1 className="mt-4 block text-2xl text-left leading-8 font-bold tracking-tight text-gray-900 sm:text-3xl">
                  Short-Term Capital Gains Taxes
                </h1>
                <p className="mt-4 text-xl text-gray-500 leading-8">
                  Short-term capital gains on stocks are taxed like ordinary
                  income. In other words, if you buy a stock for $100 and sell
                  it before one year has passed for $110, your taxable gain is
                  $10 ($110 minus the cost basis). Depending on your tax
                  bracket, you could owe as high as 37% on these gains i.e.,
                  $3.7.
                </p>
                <h1 className="mt-4 block text-2xl text-left leading-8 font-bold tracking-tight text-gray-900 sm:text-3xl">
                  Long-Term Capital Gains Taxes
                </h1>
                <p className="mt-4 text-xl text-gray-500 leading-8">
                  If you hold your stock for one year or more before selling it,
                  the IRS considers it a long-term investment. You'll pay taxes
                  on those profits at long-term capital gains rates if you make
                  profits after holding the stock for one year or more, and
                  long-term capital gains rates are lower than short-term
                  capital gains rates.
                </p>
              </div>
            </div>
          </div>
        </main>

        <footer className="theme-footer-eight mt-100">
          <div className="top-footer">
            <div className="container">
              <FooterEightPlenish />
            </div>
            {/* /.container */}
          </div>
          {/* /.top-footer */}

          <div className="container">
            <div className="bottom-footer-content">
              <CopyRightFourPlenish />
            </div>
            {/*  /.bottom-footer */}
          </div>
        </footer>
        {/* /.theme-footer-one */}
      </div>
    </div>
  );
};

export default BlogDetailsHowTheTaxWorksWhenYouSellStocks;
