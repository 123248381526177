import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";

export default function WhatCanYouaskAbout(props) {
  return (
    <Transition appear show={props.open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={props.closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-xl font-medium leading-6 text-gray-900"
                >
                  <div className="flex justify-between">
                    <div>Examples of Common Questions</div>
                    <div>
                      <button
                        type="button"
                        className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                        onClick={props.closeModal}
                      >
                        <span className="sr-only">Close</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    Need to know what you can ask about? Here are some examples
                    for inspiration.
                  </p>
                </div>
                <div className="mt-4">
                  <p className="text-xs text-gray-700">401(K)</p>
                  <p className="text-base text-black">
                    "How should I catch up on my 401(k) contributions?"
                  </p>
                </div>
                <div className="mt-4">
                  <p className="text-xs text-gray-700">HSA</p>
                  <p className="text-base text-black">
                    "Should I maximize my HSA account?"
                  </p>
                </div>
                <div className="mt-4">
                  <p className="text-xs text-gray-700">CHILDREN</p>
                  <p className="text-base text-black">
                    "How should I invest to pay for kids' college in future?"
                  </p>
                </div>
                <div className="mt-4">
                  <p className="text-xs text-gray-700">HOUSING</p>
                  <p className="text-base text-black">
                    "Considering the interest rates, should I buy or rent now?"
                  </p>
                </div>
                <div className="mt-4">
                  <p className="text-xs text-gray-700">RETIREMENT</p>
                  <p className="text-base text-black">
                    "Should I buy annuities?"
                  </p>
                </div>

                <div className="mt-4 float-right">
                  <button
                    type="button"
                    className="inline-flex justify-center border border-transparent bg-green-700 rounded-3xl px-8 py-2 text-base font-medium text-white hover:bg-green-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                    onClick={props.closeModal}
                  >
                    Done
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
