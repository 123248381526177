import React from "react";

const FormForAdvisorsPlenish = () => {
  // const handleSubmit = (event) => {
  //   event.preventDefault();
  // };

  function checkform() {
    var f = document.forms["advisors-form"].elements;
    var cansubmit = true;

    for (var i = 0; i < f.length; i++) {
      if ((f[i].id !== "advisors-submit-button") & (f[i].value.length === 0)) {
        cansubmit = false;
      }
    }

    if (cansubmit) {
      document.getElementById("advisors-submit-button").disabled = false;
      document.getElementById("advisors-submit-button").className =
        "w-full inline-flex items-center justify-center px-4 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-emerald-700 hover:bg-emerald-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 sm:w-auto";
    } else {
      document.getElementById("advisors-submit-button").disabled = "disabled";
      document.getElementById("advisors-submit-button").className =
        "w-full inline-flex items-center justify-center px-4 py-3 border border-transparent rounded-md shadow-sm text-base font-medium bg-gray-200 text-gray-400 sm:w-auto";
    }
  }

  return (
    <form
      method="POST"
      action="https://script.google.com/macros/s/AKfycbxlgcXfoxFHlZxghAVrqNCBRaW_f7ViXQABoNUlRVqJrUQ8UiOA01qaycCzJW66j14K/exec"
      className="mt-4"
      id="advisors-form"
    >
      <div className="d-flex">
        <div className="hidden">
          <input id="version" name="Version" defaultValue="v5" />
          <input id="version" name="Type" defaultValue="Advisor" />
        </div>
        <input
          type="email"
          placeholder="Enter your email to learn more"
          onKeyUp={checkform}
          required
          id="email"
          name="Email"
          autoComplete="email"
        />
        <button
          className="d-flex justify-content-center align-items-center"
          id="advisors-submit-button"
          type="submit"
        >
          <img src="images/icon/119.svg" alt="icon" />
        </button>
      </div>
    </form>
  );
};

export default FormForAdvisorsPlenish;
