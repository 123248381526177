import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import gtag from "ga-gtag";

const ThankYouJoin = () => {
  function trackDownloadEvent() {
    gtag("event", "conversion", {
      send_to: "AW-10933766975/EPCXCLD88eIDEL-W0N0o",
    });
  }

  return (
    <div className="main-page-wrapper p0 font-gordita">
      <Helmet>
        <title>Plenish | Thank You</title>
        {trackDownloadEvent()}
      </Helmet>
      <div className="bg-neutral-50 px-8 sm:px-0">
        <div className="flex">
          <div className="m-auto">
            <div className="mt-6 text-lg sm:text-2xl font-semibold text-green-800 text-center">
              Thank you for your question. We will get back to you with an
              answer from an expert financial advisor soon!
            </div>
            <div className="flex items-center justify-center">
              <Link to="/promo">
                <button className="mt-16 w-full inline-flex items-center justify-center px-8 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:w-auto">
                  Back
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThankYouJoin;
