import React from "react";
import Cookies from "js-cookie";

import PromoPlenish5 from "../../../views/inner-pages/blog-pages/PlenishPromo5";
import PromoPlenish6 from "../../../views/inner-pages/blog-pages/PlenishPromo6";
import PromoPlenish7 from "../../../views/inner-pages/blog-pages/PlenishPromo7";

const PlenishPromoRedirect = () => {
  const [version, set_version] = React.useState("");

  function weightedRand() {
    const spec = { 0: 0.33333, 1: 0.33333, 2: 0.33333 };

    var i,
      j,
      table = [];
    for (i in spec) {
      for (j = 0; j < spec[i] * 10; j++) {
        table.push(i);
      }
    }
    return table[Math.floor(Math.random() * table.length)];
  }

  React.useEffect(() => {
    function set_cookie() {
      let cookie_value = Cookies.get("version");
      if (cookie_value === undefined) {
        cookie_value = weightedRand();
        Cookies.set("version", cookie_value);
      }
      set_version(cookie_value);
    }
    set_cookie();
  }, []);

  function renderSwitch() {
    switch (version) {
      case "0":
        return <PromoPlenish5 />;
      case "1":
        return <PromoPlenish6 />;
      case "2":
        return <PromoPlenish7 />;
      default:
        return <PromoPlenish5 />;
    }
  }

  return <>{renderSwitch()}</>;
};

export default PlenishPromoRedirect;
