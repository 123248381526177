import { createContext, useState } from "react";
import Step from "./components/Step/Step";
import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

export const FormContext = createContext();

function FormApp() {
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [formData, setFormData] = useState({});

  return (
    <div>
      <div className="main-page-wrapper p0 font-gordita">
        <Helmet>
          <title>Plenish | Get Started</title>
        </Helmet>

        {/* <HeaderLandingAppointmentPlenish /> */}

        <main className="mb-300">
          <div className="pb-100">
            <div className="pb-200">
              <FormContext.Provider
                value={{
                  activeStepIndex,
                  setActiveStepIndex,
                  formData,
                  setFormData,
                }}
              >
                <div className="bg-gray-50 font-gordita p-1">
                  <div className="max-w-7xl mx-auto pb-200">
                    <div className="hidden md:block">
                      <div className="flex">
                        <Link to="/" className="py-8 w-32 sm:w-48">
                          <img src="images/logo/logo.png" alt="brand" />
                        </Link>
                      </div>
                    </div>
                    <div className="sm:hidden">
                      <div className="flex">
                        <Link to="/" className="py-8 mx-auto w-32 sm:w-48">
                          <img src="images/logo/logo.png" alt="brand" />
                        </Link>
                      </div>
                    </div>
                    <div className="flex flex-col items-center justify-start">
                      <div className="">
                        <div className="bg-white sm:rounded-2xl py-4">
                          <div className="grid grid-cols-1 sm:grid-cols-12 gap-0">
                            <div className="col-span-3 text-center"></div>
                            <div className="col-span-6 text-center">
                              <Step />
                            </div>
                            <div className="col-span-3 text-center"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </FormContext.Provider>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default FormApp;
