import React, { useState } from "react";
import Scrollspy from "react-scrollspy";
import { Link } from "react-router-dom";
// import MegaMenuLanding from "../mega-menu/MegaMenuLanding";

const HeaderLandingAppointmentPlenish = () => {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const [navbar, setNavbar] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 90) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  function show_get_started() {
    return window.location.pathname === "/promo" ? false : true;
  }

  window.addEventListener("scroll", changeBackground);

  return (
    <>
      {/* =============================================
				Theme Main Menu
			==============================================  */}
      <div
        className={
          navbar
            ? "theme-main-menu sticky-menu theme-menu-six bg-none fixed"
            : "theme-main-menu sticky-menu theme-menu-six bg-none"
        }
      >
        <div className="d-flex align-items-center">
          <div className="logo col-sm-2">
            <Link to="/">
              <img
                src="images/logo/logo.png"
                alt="brand"
                className="logo_plenish"
              />
            </Link>
          </div>
          {/* End logo */}

          <div className="right-widget order-lg-3">
            <ul className="d-flex align-items-center">
              {/* <li>
                <Link
                  to="login"
                  className="signIn-action d-flex align-items-center"
                >
                  <img src="images/icon/120.svg" alt="icon" />
                  <span>login</span>
                </Link>
              </li> */}
              {/* <li>
                <Link to="signup" className="signup-btn pl-3 pr-3">
                  <span>Join the Waitlist</span>
                </Link>
              </li> */}
            </ul>
          </div>
          {/* End .right-widget */}

          <nav
            id="mega-menu-holder"
            className="navbar navbar-expand-lg ml-lg-auto order-lg-2"
          >
            <div className="container nav-container">
              <div className="mob-header">
                <button className="toggler-menu" onClick={handleClick}>
                  <div className={click ? "active" : ""}>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </button>
              </div>
              {/* End Header */}

              <div
                className="navbar-collapse collapse landing-menu-onepage"
                id="navbarSupportedContent"
              >
                <div className="d-lg-flex justify-content-between align-items-center">
                  <Scrollspy
                    className="navbar-nav  main-side-nav font-gordita"
                    items={["home", "for-advisors", "pricing-plenish"]}
                    // currentClassName="active"
                    offset={-90}
                  >
                    <li className="nav-item">
                      <a href="/#for-advisors" className="nav-link">
                        For advisors
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="/blog" className="nav-link">
                        Blog
                      </a>
                    </li>

                    {show_get_started() && (
                      <li className="nav-item">
                        <Link
                          to="/promo"
                          className="nav-link bg-green-700 py-3 rounded-full px-4 text-white mt-3"
                        >
                          <span>Get Started</span>
                        </Link>
                      </li>
                    )}
                  </Scrollspy>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
      {/* /.theme-main-menu */}

      {/* Mobile Menu Start */}
      <div className={click ? "mobile-menu  menu-open" : "mobile-menu"}>
        <div className="logo order-md-1 w-50">
          <Link to="/">
            <img src="images/logo/logo.png" alt="brand" />
          </Link>
          <div className="fix-icon text-dark" onClick={handleClick}>
            <img src="images/icon/close.svg" alt="icon" />
          </div>
          {/* Mobile Menu close icon */}
        </div>

        <Scrollspy
          className="navbar-nav"
          id="theme-menu-list"
          items={["home", "for-advisors", "pricing-plenish"]}
          // currentClassName="active"
          offset={-90}
        >
          <li className="nav-item">
            <a href="/" className="nav-link" onClick={handleClick}>
              Home
            </a>
          </li>
          <li className="nav-item">
            <a href="/#for-advisors" className="nav-link" onClick={handleClick}>
              For advisors
            </a>
          </li>
          <li className="nav-item">
            <a href="/blog" className="nav-link" onClick={handleClick}>
              Blog
            </a>
          </li>
          <li className="nav-item">
            <a href="/promo" className="nav-link" onClick={handleClick}>
              Get Started
            </a>
          </li>
        </Scrollspy>
      </div>
      {/* Mobile Menu End */}
    </>
  );
};

export default HeaderLandingAppointmentPlenish;
