import React from "react";
import { Helmet } from "react-helmet";
import HeaderLandingAppointmentPlenish from "../../../components/header/landing/HeaderLandingAppointmentPlenish";
import FooterEightPlenish from "../../../components/footer/FooterEightPlenish";
import CopyRightFourPlenish from "../../../components/footer/CopyRightFourPlenish";

const TermsConditions = () => {
  return (
    <div>
      {/* <div className="banner-promo">
        Get your questions answered,{" "}
        <Link to="/promo" className="">
          try for free
        </Link>
      </div> */}
      <div className="main-page-wrapper p0 font-gordita">
        <Helmet>
          <title>Plenish | Terms & Conditions</title>
        </Helmet>
        {/* End Page SEO Content */}

        <HeaderLandingAppointmentPlenish />
        {/* End Header */}

        {/* =============================================
            Fancy Hero Two
        ==============================================  */}
        <div className="fancy-hero-five bg-gray-50">
          {/* <div className="bg-wrapper "> */}
          <div className="container">
            <div className="col-lg-10 m-auto text-center">
              <h1 className="heading">Terms & Conditions</h1>
            </div>
            {/* </div> */}
          </div>
          {/* /.bg-wrapper */}
        </div>
        {/* /.fancy-hero-two */}

        {/* =====================================================
            Feature Blog One
        ===================================================== */}
        <main>
          <div className="bg-gray-50">
            <div className="relative justify-between items-center max-w-7xl mx-auto">
              <div className="relative px-4 sm:px-6 lg:px-8">
                <div className="text-lg mx-auto">
                  <p className="pt-4 text-xl text-gray-500 leading-8">
                    Last updated: May 12, 2022
                  </p>
                  <p className="mt-4 text-xl text-gray-500 leading-8">
                    Please read these terms and conditions carefully before
                    using Our Service.
                  </p>
                  <h1 className="mt-4 block text-2xl text-left leading-8 font-bold tracking-tight text-gray-900 sm:text-3xl">
                    Interpretation and Definitions
                  </h1>
                  <p className="mt-4 text-xl text-gray-500 leading-8 font-bold">
                    Interpretation
                  </p>
                  <p className="mt-4 text-xl text-gray-500 leading-8">
                    The words of which the initial letter is capitalized have
                    meanings defined under the following conditions. The
                    following definitions shall have the same meaning regardless
                    of whether they appear in singular or in plural.
                  </p>
                  <p className="mt-4 text-xl text-gray-500 leading-8 font-bold">
                    Definitions
                  </p>
                  <p className="mt-4 text-xl text-gray-500 leading-8">
                    For the purposes of these Terms and Conditions:
                  </p>
                  <ul className="list-disc ml-8">
                    <li className="mt-4 text-xl text-gray-500 leading-8">
                      <span className="font-bold">Affiliate</span> means an
                      entity that controls, is controlled by or is under common
                      control with a party, where "control" means ownership of
                      50% or more of the shares, equity interest or other
                      securities entitled to vote for election of directors or
                      other managing authority.
                    </li>
                    <li className="mt-4 text-xl text-gray-500 leading-8">
                      <span className="font-bold">Country</span> refers to: New
                      York, United States
                    </li>
                    <li className="mt-4 text-xl text-gray-500 leading-8">
                      <span className="font-bold">Company</span> (referred to as
                      either "the Company", "We", "Us" or "Our" in this
                      Agreement) refers to Plenish Finance, Inc., 157 13th St,
                      Brooklyn, NY 11215.
                    </li>
                    <li className="mt-4 text-xl text-gray-500 leading-8">
                      <span className="font-bold">Device</span> means any device
                      that can access the Service such as a computer, a
                      cellphone or a digital tablet.
                    </li>
                    <li className="mt-4 text-xl text-gray-500 leading-8">
                      <span className="font-bold">Feedback</span> means
                      feedback, innovations or suggestions sent by You regarding
                      the attributes, performance or features of our Service.
                    </li>
                    <li className="mt-4 text-xl text-gray-500 leading-8">
                      <span className="font-bold">Promotions</span> refer to
                      contests, sweepstakes or other promotions offered through
                      the Service.
                    </li>
                    <li className="mt-4 text-xl text-gray-500 leading-8">
                      <span className="font-bold">Service</span> refers to the
                      Website.
                    </li>
                    <li className="mt-4 text-xl text-gray-500 leading-8">
                      <span className="font-bold">Terms and Conditions</span>{" "}
                      (also referred as "Terms") mean these Terms and Conditions
                      that form the entire agreement between You and the Company
                      regarding the use of the Service.
                    </li>
                    <li className="mt-4 text-xl text-gray-500 leading-8">
                      <span className="font-bold">
                        Third-party Social Media Service
                      </span>{" "}
                      means any services or content (including data,
                      information, products or services) provided by a
                      third-party that may be displayed, included or made
                      available by the Service.
                    </li>
                    <li className="mt-4 text-xl text-gray-500 leading-8">
                      <span className="font-bold">Website</span> refers to
                      Plenish, accessible from https://www.plenishwealth.com/
                    </li>
                    <li className="mt-4 text-xl text-gray-500 leading-8">
                      <span className="font-bold">You</span> means the
                      individual accessing or using the Service, or the company,
                      or other legal entity on behalf of which such individual
                      is accessing or using the Service, as applicable.
                    </li>
                  </ul>

                  <h1 className="mt-4 block text-2xl text-left leading-8 font-bold tracking-tight text-gray-900 sm:text-3xl">
                    Acknowledgment
                  </h1>
                  <p className="mt-4 text-xl text-gray-500 leading-8">
                    These are the Terms and Conditions governing the use of this
                    Service and the agreement that operates between You and the
                    Company. These Terms and Conditions set out the rights and
                    obligations of all users regarding the use of the Service.
                  </p>
                  <p className="mt-4 text-xl text-gray-500 leading-8">
                    Your access to and use of the Service is conditioned on Your
                    acceptance of and compliance with these Terms and
                    Conditions. These Terms and Conditions apply to all
                    visitors, users and others who access or use the Service.
                  </p>
                  <p className="mt-4 text-xl text-gray-500 leading-8">
                    By accessing or using the Service You agree to be bound by
                    these Terms and Conditions. If You disagree with any part of
                    these Terms and Conditions then You may not access the
                    Service.
                  </p>
                  <p className="mt-4 text-xl text-gray-500 leading-8">
                    You represent that you are over the age of 18. The Company
                    does not permit those under 18 to use the Service.
                  </p>
                  <p className="mt-4 text-xl text-gray-500 leading-8">
                    Your access to and use of the Service is also conditioned on
                    Your acceptance of and compliance with the Privacy Policy of
                    the Company. Our Privacy Policy describes Our policies and
                    procedures on the collection, use and disclosure of Your
                    personal information when You use the Application or the
                    Website and tells You about Your privacy rights and how the
                    law protects You. Please read Our Privacy Policy carefully
                    before using Our Service.
                  </p>
                  <h1 className="mt-4 block text-2xl text-left leading-8 font-bold tracking-tight text-gray-900 sm:text-3xl">
                    Promotions
                  </h1>
                  <p className="mt-4 text-xl text-gray-500 leading-8">
                    Any Promotions made available through the Service may be
                    governed by rules that are separate from these Terms.
                  </p>
                  <p className="mt-4 text-xl text-gray-500 leading-8">
                    If You participate in any Promotions, please review the
                    applicable rules as well as our Privacy policy. If the rules
                    for a Promotion conflict with these Terms, the Promotion
                    rules will apply.
                  </p>
                  <h1 className="mt-4 block text-2xl text-left leading-8 font-bold tracking-tight text-gray-900 sm:text-3xl">
                    Intellectual Property
                  </h1>
                  <p className="mt-4 text-xl text-gray-500 leading-8">
                    The Service and its original content (excluding Content
                    provided by You or other users), features and functionality
                    are and will remain the exclusive property of the Company
                    and its licensors.
                  </p>
                  <p className="mt-4 text-xl text-gray-500 leading-8">
                    The Service is protected by copyright, trademark, and other
                    laws of both the Country and foreign countries.
                  </p>
                  <p className="mt-4 text-xl text-gray-500 leading-8">
                    Our trademarks and trade dress may not be used in connection
                    with any product or service without the prior written
                    consent of the Company.
                  </p>

                  <h1 className="mt-4 block text-2xl text-left leading-8 font-bold tracking-tight text-gray-900 sm:text-3xl">
                    Your Feedback to Us
                  </h1>
                  <p className="mt-4 text-xl text-gray-500 leading-8">
                    You assign all rights, title and interest in any Feedback
                    You provide the Company. If for any reason such assignment
                    is ineffective, You agree to grant the Company a
                    non-exclusive, perpetual, irrevocable, royalty free,
                    worldwide right and license to use, reproduce, disclose,
                    sub-license, distribute, modify and exploit such Feedback
                    without restriction.
                  </p>

                  <h1 className="mt-4 block text-2xl text-left leading-8 font-bold tracking-tight text-gray-900 sm:text-3xl">
                    Links to Other Websites
                  </h1>
                  <p className="mt-4 text-xl text-gray-500 leading-8">
                    Our Service may contain links to third-party web sites or
                    services that are not owned or controlled by the Company.
                  </p>
                  <p className="mt-4 text-xl text-gray-500 leading-8">
                    The Company has no control over, and assumes no
                    responsibility for, the content, privacy policies, or
                    practices of any third party web sites or services. You
                    further acknowledge and agree that the Company shall not be
                    responsible or liable, directly or indirectly, for any
                    damage or loss caused or alleged to be caused by or in
                    connection with the use of or reliance on any such content,
                    goods or services available on or through any such web sites
                    or services.
                  </p>
                  <p className="mt-4 text-xl text-gray-500 leading-8">
                    We strongly advise You to read the terms and conditions and
                    privacy policies of any third-party web sites or services
                    that You visit.
                  </p>

                  <h1 className="mt-4 block text-2xl text-left leading-8 font-bold tracking-tight text-gray-900 sm:text-3xl">
                    Termination
                  </h1>
                  <p className="mt-4 text-xl text-gray-500 leading-8">
                    We may terminate or suspend Your access immediately, without
                    prior notice or liability, for any reason whatsoever,
                    including without limitation if You breach these Terms and
                    Conditions.
                  </p>
                  <p className="mt-4 text-xl text-gray-500 leading-8">
                    Upon termination, Your right to use the Service will cease
                    immediately.
                  </p>

                  <h1 className="mt-4 block text-2xl text-left leading-8 font-bold tracking-tight text-gray-900 sm:text-3xl">
                    Limitation of Liability
                  </h1>
                  <p className="mt-4 text-xl text-gray-500 leading-8">
                    Notwithstanding any damages that You might incur, the entire
                    liability of the Company and any of its suppliers under any
                    provision of this Terms and Your exclusive remedy for all of
                    the foregoing shall be limited to the amount actually paid
                    by You through the Service or 100 USD if You haven't
                    purchased anything through the Service.
                  </p>
                  <p className="mt-4 text-xl text-gray-500 leading-8">
                    To the maximum extent permitted by applicable law, in no
                    event shall the Company or its suppliers be liable for any
                    special, incidental, indirect, or consequential damages
                    whatsoever (including, but not limited to, damages for loss
                    of profits, loss of data or other information, for business
                    interruption, for personal injury, loss of privacy arising
                    out of or in any way related to the use of or inability to
                    use the Service, third-party software and/or third-party
                    hardware used with the Service, or otherwise in connection
                    with any provision of this Terms), even if the Company or
                    any supplier has been advised of the possibility of such
                    damages and even if the remedy fails of its essential
                    purpose.
                  </p>
                  <p className="mt-4 text-xl text-gray-500 leading-8">
                    Some states do not allow the exclusion of implied warranties
                    or limitation of liability for incidental or consequential
                    damages, which means that some of the above limitations may
                    not apply. In these states, each party's liability will be
                    limited to the greatest extent permitted by law.
                  </p>

                  <h1 className="mt-4 block text-2xl text-left leading-8 font-bold tracking-tight text-gray-900 sm:text-3xl">
                    "AS IS" and "AS AVAILABLE" Disclaimer
                  </h1>
                  <p className="mt-4 text-xl text-gray-500 leading-8">
                    The Service is provided to You "AS IS" and "AS AVAILABLE"
                    and with all faults and defects without warranty of any
                    kind. To the maximum extent permitted under applicable law,
                    the Company, on its own behalf and on behalf of its
                    Affiliates and its and their respective licensors and
                    service providers, expressly disclaims all warranties,
                    whether express, implied, statutory or otherwise, with
                    respect to the Service, including all implied warranties of
                    merchantability, fitness for a particular purpose, title and
                    non-infringement, and warranties that may arise out of
                    course of dealing, course of performance, usage or trade
                    practice. Without limitation to the foregoing, the Company
                    provides no warranty or undertaking, and makes no
                    representation of any kind that the Service will meet Your
                    requirements, achieve any intended results, be compatible or
                    work with any other software, applications, systems or
                    services, operate without interruption, meet any performance
                    or reliability standards or be error free or that any errors
                    or defects can or will be corrected.
                  </p>
                  <p className="mt-4 text-xl text-gray-500 leading-8">
                    Without limiting the foregoing, neither the Company nor any
                    of the company's provider makes any representation or
                    warranty of any kind, express or implied: (i) as to the
                    operation or availability of the Service, or the
                    information, content, and materials or products included
                    thereon; (ii) that the Service will be uninterrupted or
                    error-free; (iii) as to the accuracy, reliability, or
                    currency of any information or content provided through the
                    Service; or (iv) that the Service, its servers, the content,
                    or e-mails sent from or on behalf of the Company are free of
                    viruses, scripts, trojan horses, worms, malware, timebombs
                    or other harmful components.
                  </p>
                  <p className="mt-4 text-xl text-gray-500 leading-8">
                    Some jurisdictions do not allow the exclusion of certain
                    types of warranties or limitations on applicable statutory
                    rights of a consumer, so some or all of the above exclusions
                    and limitations may not apply to You. But in such a case the
                    exclusions and limitations set forth in this section shall
                    be applied to the greatest extent enforceable under
                    applicable law.
                  </p>

                  <h1 className="mt-4 block text-2xl text-left leading-8 font-bold tracking-tight text-gray-900 sm:text-3xl">
                    Governing Law
                  </h1>
                  <p className="mt-4 text-xl text-gray-500 leading-8">
                    The laws of the Country, excluding its conflicts of law
                    rules, shall govern this Terms and Your use of the Service.
                    Your use of the Application may also be subject to other
                    local, state, national, or international laws.
                  </p>

                  <h1 className="mt-4 block text-2xl text-left leading-8 font-bold tracking-tight text-gray-900 sm:text-3xl">
                    Disputes Resolution
                  </h1>
                  <p className="mt-4 text-xl text-gray-500 leading-8">
                    If You have any concern or dispute about the Service, You
                    agree to first try to resolve the dispute informally by
                    contacting the Company.
                  </p>

                  <h1 className="mt-4 block text-2xl text-left leading-8 font-bold tracking-tight text-gray-900 sm:text-3xl">
                    For European Union (EU) Users
                  </h1>
                  <p className="mt-4 text-xl text-gray-500 leading-8">
                    If You are a European Union consumer, you will benefit from
                    any mandatory provisions of the law of the country in which
                    you are resident in.
                  </p>

                  <h1 className="mt-4 block text-2xl text-left leading-8 font-bold tracking-tight text-gray-900 sm:text-3xl">
                    United States Legal Compliance
                  </h1>
                  <p className="mt-4 text-xl text-gray-500 leading-8">
                    You represent and warrant that (i) You are not located in a
                    country that is subject to the United States government
                    embargo, or that has been designated by the United States
                    government as a "terrorist supporting" country, and (ii) You
                    are not listed on any United States government list of
                    prohibited or restricted parties.
                  </p>

                  <h1 className="mt-4 block text-2xl text-left leading-8 font-bold tracking-tight text-gray-900 sm:text-3xl">
                    Severability and Waiver
                  </h1>
                  <p className="mt-4 text-xl text-gray-500 leading-8 font-bold">
                    Severability
                  </p>
                  <p className="mt-4 text-xl text-gray-500 leading-8">
                    If any provision of these Terms is held to be unenforceable
                    or invalid, such provision will be changed and interpreted
                    to accomplish the objectives of such provision to the
                    greatest extent possible under applicable law and the
                    remaining provisions will continue in full force and effect.
                  </p>
                  <p className="mt-4 text-xl text-gray-500 leading-8 font-bold">
                    Waiver
                  </p>
                  <p className="mt-4 text-xl text-gray-500 leading-8">
                    Except as provided herein, the failure to exercise a right
                    or to require performance of an obligation under these Terms
                    shall not effect a party's ability to exercise such right or
                    require such performance at any time thereafter nor shall
                    the waiver of a breach constitute a waiver of any subsequent
                    breach.
                  </p>

                  <h1 className="mt-4 block text-2xl text-left leading-8 font-bold tracking-tight text-gray-900 sm:text-3xl">
                    Translation Interpretation
                  </h1>
                  <p className="mt-4 text-xl text-gray-500 leading-8">
                    These Terms and Conditions may have been translated if We
                    have made them available to You on our Service. You agree
                    that the original English text shall prevail in the case of
                    a dispute.
                  </p>

                  <h1 className="mt-4 block text-2xl text-left leading-8 font-bold tracking-tight text-gray-900 sm:text-3xl">
                    Changes to These Terms and Conditions
                  </h1>
                  <p className="mt-4 text-xl text-gray-500 leading-8">
                    We reserve the right, at Our sole discretion, to modify or
                    replace these Terms at any time. If a revision is material
                    We will make reasonable efforts to provide at least 30 days'
                    notice prior to any new terms taking effect. What
                    constitutes a material change will be determined at Our sole
                    discretion.
                  </p>
                  <p className="mt-4 text-xl text-gray-500 leading-8">
                    By continuing to access or use Our Service after those
                    revisions become effective, You agree to be bound by the
                    revised terms. If You do not agree to the new terms, in
                    whole or in part, please stop using the website and the
                    Service.
                  </p>

                  <h1 className="mt-4 block text-2xl text-left leading-8 font-bold tracking-tight text-gray-900 sm:text-3xl">
                    Contact Us
                  </h1>
                  <p className="mt-4 text-xl text-gray-500 leading-8">
                    If you have any questions about these Terms and Conditions,
                    you can contact us by email at hello@plenishwealth.com
                  </p>
                </div>
              </div>
            </div>
          </div>
        </main>

        <footer className="theme-footer-eight mt-100">
          <div className="top-footer">
            <div className="container">
              <FooterEightPlenish />
            </div>
            {/* /.container */}
          </div>
          {/* /.top-footer */}

          <div className="container">
            <div className="bottom-footer-content">
              <CopyRightFourPlenish />
            </div>
            {/*  /.bottom-footer */}
          </div>
        </footer>
        {/* /.theme-footer-one */}
      </div>
    </div>
  );
};

export default TermsConditions;
