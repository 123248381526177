import React from "react";
import { Helmet } from "react-helmet";
import HeaderLandingAppointmentPlenish from "../../../components/header/landing/HeaderLandingAppointmentPlenish";
import FooterEightPlenish from "../../../components/footer/FooterEightPlenish";
import CopyRightFourPlenish from "../../../components/footer/CopyRightFourPlenish";
import { Link } from "react-router-dom";
import TestimonialTwo from "../../../components/testimonial/TestimonialTwo";
import FancyFeatureTewentyFourPlenish from "../../../components/features/FancyFeatureTewentyFourPlenish";

const PlenishPromo8 = () => {
  // const refVideo = React.useRef(null);

  // React.useEffect(() => {
  //   if (!refVideo.current) {
  //     return;
  //   }

  //   //open bug since 2017 that you cannot set muted in video element https://github.com/facebook/react/issues/10389
  //   refVideo.current.defaultMuted = true;
  //   refVideo.current.muted = true;

  //   refVideo.current.srcObject = "/images/assets/iStock-1352732728.mp4";
  // }, []);

  return (
    <div>
      <div className="main-page-wrapper p0 font-gordita">
        <Helmet>
          <title>Plenish | Promo</title>
        </Helmet>
        {/* End Page SEO Content */}

        <HeaderLandingAppointmentPlenish />

        {/* End Header */}

        <main className="hero-banner-thirteen lg-container">
          <div className="bg-gray-50 font-gordita p-1 -mt-10">
            <div className="relative max-w-5xl mx-auto">
              <div class="sm:grid sm:grid-cols-2 sm:gap-y-4 bg-white rounded-2xl shadow-md mx-3">
                {/* 1 */}
                <div>
                  <div className="px-3 sm:px-5 mx-3">
                    <div className="grid grid-cols-1 sm:grid-cols-12 gap-0 py-10">
                      {/* <div className="col-span-2 text-center"></div> */}
                      <div className="col-span-12 text-center">
                        <div className="text-black title_promo font-bold">
                          Find answers that you can trust
                        </div>
                        <div className="text-gray-600 text-base mt-10">
                          Now you have access to a credentialed financial
                          advisor. Simply ask your question and receive an
                          answer by email within 24 hours. You can ask about a
                          wide range of topics, from retirement planning to real
                          estate investment strategies, to how financial
                          products work.
                        </div>
                        <div className="my-3">
                          <Link to="/get-started">
                            <button className="bg-green-700 hover:bg-green-500 text-white font-bold py-2 px-4 rounded-full">
                              Get Started
                            </button>
                          </Link>
                        </div>
                        <div className="text-sm px-2 sm:px-20">
                          No credit card required. No marketing or sales pitch
                          will follow. Questions and answers are 100%
                          confidential, and your contact information will never
                          be shared with anyone.
                        </div>
                      </div>
                      {/* <div className="col-span-2 text-center"></div> */}
                    </div>
                  </div>
                </div>
                {/* 2 */}
                <div className="relative mr-4 my-auto" aria-hidden="true">
                  <div className="flex align-middle">
                    <video
                      // ref={refVideo}
                      onContextMenu={() => false}
                      autoPlay={true}
                      loop={true}
                      muted
                      playsInline
                      className="rounded-2xl"
                      src="/images/assets/iStock-1352732728.mp4"
                      type="video/mp4"
                    />
                  </div>
                </div>
              </div>

              {/* <div className="mx-3 mt-20">
                <img
                  className="inset-0 w-full mx-auto rounded-2xl"
                  src="/images/assets/promo_2.jpg"
                  alt=""
                />
              </div> */}

              {/* <div className="slider-content"> */}
              <div className="clientSliderTwo slick-nav-none mt-20 mb-14 sm:mb-0 mx-2 sm:mx-0">
                <TestimonialTwo />
              </div>
              {/* </div> */}

              <p className="my-12 title_promo sm:px-0 text-black font-bold ml-4 sm:mx-0 text-center">
                What we offer
              </p>

              <div className="px-3 sm:px-0">
                <dl className="space-y-4 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-8">
                  <div className="relative rounded-2xl shadow-md p-3 bg-white">
                    <div className="">
                      <div className="flex items-center">
                        <div className="flex-shrink-0 promo_rectangle_1 rounded-2xl p-3">
                          <object
                            data="/images/assets/lightning.svg"
                            className="h-8 w-8"
                          >
                            {" "}
                          </object>
                        </div>
                        <div className="ml-4">
                          <p className="text-base leading-6 font-medium text-gray-900">
                            Actionable advice
                          </p>
                          <dd className="mt-2 text-sm text-gray-500">
                            You will get simplified, relevant guidance to invest
                            and plan.
                          </dd>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="relative rounded-2xl shadow-md p-3 bg-white">
                    <div className="">
                      <div className="flex items-center">
                        <div className="flex-shrink-0 promo_rectangle_2 rounded-2xl p-3">
                          <object
                            data="/images/assets/lock_closed.svg"
                            className="h-8 w-8"
                          >
                            {" "}
                          </object>
                        </div>
                        <div className="ml-4">
                          <p className="text-base leading-6 font-medium text-gray-900">
                            Safe and confidential
                          </p>
                          <dd className="mt-2 text-sm text-gray-500">
                            Your information will never be shared or sold to
                            anyone.
                          </dd>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="relative rounded-2xl shadow-md p-3 bg-white">
                    <div className="">
                      <div className="flex items-center">
                        <div className="flex-shrink-0  promo_rectangle_3 rounded-2xl p-3">
                          <object
                            data="/images/assets/user_group.svg"
                            className="h-8 w-8"
                          >
                            {" "}
                          </object>
                        </div>
                        <div className="ml-4">
                          <p className="text-base leading-6 font-medium text-gray-900">
                            For investors at all levels
                          </p>
                          <dd className="mt-2 text-sm text-gray-500">
                            From newbies to veterans, we are your stepping stone
                            to get you to the next level.
                          </dd>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="relative rounded-2xl shadow-md p-3 bg-white">
                    <div className="">
                      <div className="flex items-center">
                        <div className="flex-shrink-0  promo_rectangle_4 rounded-2xl p-3">
                          <object
                            data="/images/assets/heart.svg"
                            className="h-8 w-8"
                          >
                            {" "}
                          </object>
                        </div>
                        <div className="ml-4">
                          <p className="text-base leading-6 font-medium text-gray-900">
                            Free for everyone
                          </p>
                          <dd className="mt-2 text-sm text-gray-500">
                            Get your first 5 answers for free. No subscriptions,
                            obligations, or hidden fees.
                          </dd>
                        </div>
                      </div>
                    </div>
                  </div>
                </dl>
              </div>
            </div>

            <div className="mt-60">
              <div className="hidden md:block">
                <div className="sm:grid place-content-center sm:grid-cols-12 sm:px-0">
                  <div className="col-span-12 md:inline-flex sm:col-span-6 text-md sm:text-lg justify-end text-center promo_rectangle_6 text-white font-bold py-16 px-3 sm:py-20 sm:pr-32">
                    <div className="promo_width text-xl">
                      Did you know 89% of wealthy families in the US have a
                      professional advisor?
                    </div>
                  </div>
                  <div className="col-span-12 md:inline-flex sm:col-span-6 text-md sm:text-lg justify-start text-center  promo_rectangle_5 text-white font-bold py-16 px-3 sm:py-20 sm:pl-32">
                    <div className="promo_width text-xl">
                      At Plenish, we demistify the world of personal investing
                      by bringing similar services that are catered to you.
                    </div>
                  </div>
                </div>
              </div>

              <div className="sm:hidden">
                <div className="sm:grid place-content-center sm:grid-cols-12 sm:px-0">
                  <div className="col-span-12 md:inline-flex sm:col-span-6 text-md sm:text-lg justify-end text-center promo_rectangle_6 text-white font-bold py-16 px-3 sm:py-32 sm:pr-32 ">
                    <div className="text-xl">
                      Did you know 89% of wealthy families in the US have a
                      professional advisor?
                    </div>
                  </div>
                  <div className="col-span-12 md:inline-flex sm:col-span-6 text-md sm:text-lg justify-start text-center  promo_rectangle_5 text-white font-bold py-16 px-3 sm:py-32 sm:pl-32">
                    <div className="text-xl">
                      At Plenish, we demistify the world of personal investing
                      by bringing similar services that are catered to you.
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="relative max-w-5xl mx-auto mt-60">
              <div
                className="fancy-feature-twentyFour2 wide_range_of_topics"
                id="service"
              >
                <div className="container">
                  <div className="text-center text-black title_promo font-bold">
                    Examples of what you can ask about
                  </div>
                </div>
                <div className="bg-wrapper mt-50 px-2">
                  <FancyFeatureTewentyFourPlenish />
                </div>
              </div>
            </div>

            <div className="relative max-w-5xl mx-auto">
              <div className="sm:grid place-content-center sm:grid-cols-12 px-2 sm:px-0 mb-4">
                <div className="text-center md:inline-flex rounded-sm w-full justify-end sm:col-span-6 p-8">
                  <img
                    className="rounded-2xl mt-30 sm:mt-0 mx-auto sm:mx-0"
                    src="/images/assets/financial_advisor_2.png"
                    alt=""
                  />
                </div>
                <div className="justify-center items-center flex col-span-6 pr-2 text-md sm:text-lg px-3 sm:px-0">
                  <div>
                    {/* <p className="text-2xl text-black font-medium md:-mt-36">
                      Our mission
                    </p> */}
                    <p className="mt-20">
                      We believe everyone should have financial wellness. That
                      is why we start with you as our primary focus. We created
                      Plenish to help everyone gain insights and advice from our
                      national network of handpicked advisors.
                    </p>
                    <p className="mt-20">
                      Our platform makes it easy for people at all level of
                      experience to create a financial strategy and achieve
                      their goals.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>

        <footer className="theme-footer-eight mt-100">
          <div className="top-footer">
            <div className="container">
              <FooterEightPlenish />
            </div>
            {/* /.container */}
          </div>
          {/* /.top-footer */}

          <div className="container">
            <div className="bottom-footer-content">
              <CopyRightFourPlenish />
            </div>
            {/*  /.bottom-footer */}
          </div>
        </footer>
        {/* /.theme-footer-one */}
      </div>
    </div>
  );
};

export default PlenishPromo8;
