import React, { useContext, useEffect } from "react";
import { FormContext } from "../../InvestDivaForm";

function Stepper() {
  const { activeStepIndex } = useContext(FormContext);
  useEffect(() => {
    const stepperItems = document.querySelectorAll(".stepper-item");
    stepperItems.forEach((step, i) => {
      if (i <= activeStepIndex) {
        step.classList.add("bg-green-700", "text-white");
      } else {
        step.classList.remove("bg-green-700", "text-white");
      }
    });
  }, [activeStepIndex]);
  return (
    <div className="flex flex-row items-center justify-center px-16 sm:px-32 py-8">
      <div className="stepper-item mx-2 w-8 h-8 text-center font-medium border-2 border-green-700 rounded-full text-black">
        1
      </div>
      <div className="flex-auto border-t-2"></div>
      <div className="stepper-item mx-2 w-8 h-8 text-center font-medium border-2 border-green-700 rounded-full  text-black">
        2
      </div>
    </div>
  );
}

export default Stepper;
