import React from "react";



const CopyRightFour = () => {
  return (
    <div className="row">
      <div className="col-lg-12 order-lg-2 mb-20">
        <p className="copyright text-center">
          Copyright @{new Date().getFullYear()}{" "}
          Plenish, Inc. All rights reserved.
        </p>
      </div>
    </div>
  );
};

export default CopyRightFour;
